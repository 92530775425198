import { Alert, AlertTitle, Divider } from "@mui/material";
import { useEffect } from "react";
import Button from "../../components/Basic/Button";
import Loader from "../../components/Basic/Loader";
// import Spinner from "../../components/Basic/Spinner";
import Switch from "../../components/Basic/Switch";
import SettingsContainer from "../../components/Settings/SettingsContainer";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  deactivateAccount,
  selectDeactivateStatus,
  selectFeatureFlagUpdate,
  updateFeatureFlag,
} from "../../store/admin/adminSlice";
import {
  FeatureFlags,
  setFeatureFlag,
  useFeature,
} from "../../store/featureFlagSlice";
import { selectUser } from "../../store/user/userReducer";

interface FeatureFlagToggleProps {
  flag: FeatureFlags;
  title: string;
  description: string;
}

const FeatureFlagToggle = ({
  flag,
  title,
  description,
}: FeatureFlagToggleProps) => {
  const dispatch = useAppDispatch();
  const isEnabled = useFeature(flag);
  const isCCMEnabled = useFeature(FeatureFlags.CCM);
  const updateStatus = useAppSelector(selectFeatureFlagUpdate(flag));

  // Handle reverting on error
  useEffect(() => {
    if (updateStatus?.error && updateStatus.previousValue !== null) {
      dispatch(setFeatureFlag({ flag, enabled: updateStatus.previousValue }));
    }
  }, [updateStatus?.error, updateStatus?.previousValue, flag, dispatch]);

  const handleToggle = () => {
    //  if disabling front desk inbox, disable CCM as well
    if (flag === FeatureFlags.FRONT_DESK_INBOX && isEnabled && isCCMEnabled) {
      dispatch(updateFeatureFlag({ flag: FeatureFlags.CCM, enabled: false }));
    }
    dispatch(updateFeatureFlag({ flag, enabled: !isEnabled }));
  };

  return (
    <div className="flex items-center justify-between py-4">
      <div className="flex-1">
        <h3 className="text-base font-semibold">{title}</h3>
        <p className="text-sm text-gray-500">{description}</p>
        {updateStatus?.error && (
          <p className="text-sm text-red-500 mt-1">{updateStatus.error}</p>
        )}
      </div>
      <div className="flex items-center gap-2">
        <div className="w-5">
          {updateStatus?.loading && <Loader size={5} borderWidth={2} />}
        </div>
        <Switch
          size="medium-large"
          enabled={isEnabled}
          onChange={handleToggle}
          disabled={updateStatus?.loading}
        />
      </div>
    </div>
  );
};

const AdminSettings = () => {
  const user = useAppSelector(selectUser);

  const dispatch = useAppDispatch();
  const deactivateStatus = useAppSelector(selectDeactivateStatus);

  const handleDeactivateAccount = () => {
    dispatch(deactivateAccount());
  };

  return (
    <SettingsContainer>
      <div className="space-y-8 max-w-xl mx-auto">
        {deactivateStatus.success && (
          <Alert severity="error" className="mb-6">
            <AlertTitle>Account Deactivated</AlertTitle>
            <p>
              Your account has been deactivated. You will be logged out shortly.
            </p>
          </Alert>
        )}

        {deactivateStatus.error && (
          <Alert severity="error" className="mb-6">
            <AlertTitle>Error</AlertTitle>
            <p>{deactivateStatus.error}</p>
          </Alert>
        )}

        <div>
          <h1 className="text-2xl font-bold">{user.organization.name}</h1>
          <h2 className="text-xl font-semibold">Feature Flags</h2>
          <div className="space-y-6">
            <FeatureFlagToggle
              flag={FeatureFlags.SCRIBE_ONLY}
              title="Scribe"
              description="Enable scribe functionality for this clinic"
            />

            <FeatureFlagToggle
              flag={FeatureFlags.FRONT_DESK}
              title="Old Inbox"
              description="Enable old inbox functionality"
            />

            <FeatureFlagToggle
              flag={FeatureFlags.FRONT_DESK_INBOX}
              title="Front Desk Inbox"
              description="Enable front desk inbox functionality"
            />

            <FeatureFlagToggle
              flag={FeatureFlags.CCM}
              title="CCM"
              description="Enable CCM functionality"
            />
          </div>
        </div>

        <Divider />

        <div>
          <h2 className="text-red-600 mb-1">Danger Zone</h2>
          <div>
            <div className="space-y-4">
              <Button
                variant="red"
                disabled={deactivateStatus.loading}
                onClick={handleDeactivateAccount}
              >
                {deactivateStatus.loading
                  ? "Deactivating..."
                  : "Deactivate Account"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </SettingsContainer>
  );
};

export default AdminSettings;
