import { useAppSelector } from "../../../store";
import type { Patient } from "../../../store/patient/interfaces";
import NewScribe from "../../PatientProfile/NewScribe";
import ScribeRecording from "../ScribeRecording";
import ScribeViewDetails from "../ScribeViewDetails";
import ScribeViewDetailsSkeleton from "../ScribeViewDetailsSkeleton";
import useScribeSearchParams from "../hooks/useScribeSearchParams";
import { selectScribeById } from "../store/selectors";

const ScribeContainer = ({
  saveRecording,
  patient,
  audioContext,
  mediaRecorder,
  microphoneId,
  microphones,
  setMicrophoneId,
  startRecording,
  onBack,
  onCancel,
  handleAssignPatientId,
  togglePause,
  isReadyToRecord,
  readyToRecordErrors,
  requestMicrophonePermissions,
  onResumeRecording,
}: {
  saveRecording: () => void;
  patient: Patient | null;
  audioContext: AudioContext;
  mediaRecorder: MediaRecorder | null;
  microphoneId: string;
  microphones: { text: string; value: string }[];
  setMicrophoneId: (microphoneId: string) => void;
  startRecording: () => void;
  onBack: () => void;
  onCancel: () => void;
  handleAssignPatientId: (patientId: number) => void;
  togglePause: () => void;
  isReadyToRecord: boolean;
  readyToRecordErrors: string[];
  requestMicrophonePermissions: () => void;
  onResumeRecording: () => void;
}) => {
  const { selectedScribeId } = useScribeSearchParams();
  const selectedScribe = useAppSelector((state) =>
    selectScribeById(state, selectedScribeId),
  );

  const renderNoteView = () => (
    <>
      {selectedScribe?.note ? (
        <ScribeViewDetails
          handleAssignPatientId={handleAssignPatientId}
          onBack={onBack}
          patient={patient}
          onResumeRecording={onResumeRecording}
        />
      ) : (
        <ScribeViewDetailsSkeleton
          handleAssignPatientId={handleAssignPatientId}
          onBack={onBack}
          patient={patient}
        />
      )}
    </>
  );

  return selectedScribe?.isVisible ? (
    selectedScribe?.isRecording ||
    selectedScribe?.isPaused ||
    selectedScribe?.isGenerating ? (
      <ScribeRecording
        mediaRecorder={mediaRecorder}
        patient={patient}
        handleAssignPatientId={handleAssignPatientId}
        onBack={onBack}
        onCancel={onCancel}
        saveRecording={saveRecording}
        togglePause={togglePause}
      />
    ) : (
      renderNoteView()
    )
  ) : (
    <NewScribe
      startRecording={startRecording}
      microphoneId={microphoneId}
      microphones={microphones}
      audioContext={audioContext}
      mediaRecorder={mediaRecorder}
      setMicrophoneId={setMicrophoneId}
      onBack={onBack}
      isReadyToRecord={isReadyToRecord}
      readyToRecordErrors={readyToRecordErrors}
      requestMicrophonePermissions={requestMicrophonePermissions}
    />
  );
};

export default ScribeContainer;
