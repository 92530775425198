import { ReactComponent as ChatRoundIcon } from "@assets/icons/chat-round-2.svg";
import { ReactComponent as DocumentRoundIcon } from "@assets/icons/document-round.svg";
import { ReactComponent as DoubleHeartIcon } from "@assets/icons/double-heart.svg";
import { ReactComponent as MicrophoneScribeIcon } from "@assets/icons/microphone-scribe.svg";
import { ReactComponent as PhoneCallIcon } from "@assets/icons/phone-call-2.svg";
import { ReactComponent as SuccessRoundIcon } from "@assets/icons/success-round-2.svg";

// TODO: get formInfo from backend
const NoteTypesTiles = ({
  noteType,
  formInfo = null,
}: {
  noteType: "scribe" | "call" | "chat" | "campaign";
  formInfo?: { filled: boolean } | null;
}) => {
  // TODO: remove when info from backend will be available
  const numOfForms = 2;
  const isFormFilled = formInfo?.filled;
  const noteTypeIconClass = "w-4 h-4";

  return (
    <div className="flex items-center gap-1 overflow-hidden whitespace-nowrap">
      {noteType && (
        <span className="flex items-center gap-1 bg-gray-foreground py-[2px] px-2 rounded">
          {noteType === "scribe" && (
            <MicrophoneScribeIcon
              className={`${noteTypeIconClass} [&_path]:opacity-100`}
            />
          )}
          {noteType === "call" && (
            <PhoneCallIcon className={noteTypeIconClass} />
          )}
          {noteType === "chat" && (
            <ChatRoundIcon className={noteTypeIconClass} />
          )}
          {noteType === "campaign" && (
            <DoubleHeartIcon className={noteTypeIconClass} />
          )}
          <span className={"overflow-hidden text-ellipsis capitalize"}>
            {noteType}
          </span>
        </span>
      )}
      {formInfo && (
        <span
          className={`flex items-center gap-2 py-[2px] px-2 rounded
            ${isFormFilled ? "bg-[#E4F9F2] text-system-green-dark" : "bg-gray-foreground text-tertiary"}`}
        >
          <div className="relative">
            <DocumentRoundIcon
              className={`w-4 h-4 ${isFormFilled ? "stroke-system-green-dark" : "stroke-tertiary"}`}
            />
            {isFormFilled && (
              <SuccessRoundIcon className="absolute -right-[2px] -top-[2px]" />
            )}
          </div>
          <span className="overflow-hidden text-ellipsis">
            PHQ-9 Screening {numOfForms > 1 && `+${numOfForms - 1}...`}
          </span>
        </span>
      )}
    </div>
  );
};

export default NoteTypesTiles;
