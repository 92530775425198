import { ReactComponent as ArrowLeftIcon } from "@assets/icons/arrow-left.svg";
import { ReactComponent as NoteIcon } from "@assets/icons/note-icon.svg";
import { useState } from "react";
import { useAppSelector } from "../../../store";
import { FeatureFlags } from "../../../store/featureFlagSlice";
import { useFeature } from "../../../store/featureFlagSlice";
import type { Patient } from "../../../store/patient/interfaces";
import PillTabsBg from "../../Basic/PillTabsBg";
import ScribeHeaderTitle from "../ScribeHeaderTitle";
import ScribePatient from "../ScribePatient";
import ScribeUnassignedPatient from "../ScribeUnassignedPatient";
import { MOCK_PATIENT_FIRST_NAME } from "../consts";
import useScribeSearchParams from "../hooks/useScribeSearchParams";
import { selectScribeById } from "../store/selectors";
// import { ReactComponent as TranscriptIcon } from "@assets/icons/chat-round-call.svg";

const items = [
  // {
  //   title: "Transcript",
  //   icon: (
  //     <TranscriptIcon className="stroke-[#667085] w-5 h-5 group-[.active]:stroke-white" />
  //   ),
  // },
  {
    title: "Note",
    icon: (
      <NoteIcon className="stroke-tertiary w-5 h-5 group-[.active]:stroke-white" />
    ),
  },
];

const ScribeHeader = ({
  patient,
  onBack,
  handleAssignPatientId,
  setShowAssignPatientModal,
}: {
  patient: Patient | null | undefined;
  onBack: () => void;
  handleAssignPatientId: (patientId: number) => void;
  setShowAssignPatientModal: (show: boolean) => void;
}) => {
  const isFrontDesk = useFeature(FeatureFlags.FRONT_DESK);
  const isFrontDeskInbox = useFeature(FeatureFlags.FRONT_DESK_INBOX);
  const { selectedScribeId } = useScribeSearchParams();
  const selectedScribe = useAppSelector((state) =>
    selectScribeById(state, selectedScribeId),
  );

  const [activeTab, setActiveTab] = useState("Note");

  const showPatient =
    patient &&
    // Hack for unassigned patient
    patient.first_name !== null &&
    patient.first_name !== MOCK_PATIENT_FIRST_NAME;
  // End hack

  const canShowPills =
    items.length > 1 &&
    (selectedScribe?.noteId !== -1 || selectedScribe?.isRecording);

  return (
    <div
      className="sticky top-0 w-full flex flex-col md:py-4 md:gap-3 bg-white border-b border-gray-foreground
        md:text-2xl"
    >
      <div className="order-2 md:order-1 md:bg-white bg-gray-150">
        <ScribeHeaderTitle
          scribeOriginalTitle={
            selectedScribe?.title || selectedScribe?.originalTitle
          }
        />
      </div>
      <div
        className="flex flex-row gap-3 md:gap-0 md:justify-between items-center text-base
          py-2 md:py-0 px-5 md:px-0 order-1 md:order-2"
      >
        <button type="button" className="md:hidden" onClick={onBack}>
          <ArrowLeftIcon className="stroke-primary" />
        </button>
        <div className="grow md:grow-0">
          {showPatient ? (
            <ScribePatient
              patient={patient}
              setShowAssignPatientModal={setShowAssignPatientModal}
              handleAssignPatientId={handleAssignPatientId}
            />
          ) : (
            <ScribeUnassignedPatient
              setShowAssignPatientModal={setShowAssignPatientModal}
              className={isFrontDesk || isFrontDeskInbox ? "" : "hidden"}
            />
          )}
        </div>
        {canShowPills && (
          <PillTabsBg
            tabs={items}
            activeTab={activeTab}
            onChange={setActiveTab}
            tabClassName="py-1 text-center"
          />
        )}
      </div>
    </div>
  );
};

export default ScribeHeader;
