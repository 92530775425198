import { ReactComponent as WandIcon } from "@assets/icons/magic-stick.svg";
import { ReactComponent as SendIcon } from "@assets/icons/send-outline.svg";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import TextareaAutosize from "react-textarea-autosize";
import { v7 as uuid } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useSendMessageMutation } from "../../../store/call/api";
import type { Conversation } from "../../../store/call/interfaces";
import { setClosedResponsesAction } from "../../../store/conversation/actions";
import { suggestAnswer } from "../../../store/conversation/thunks";
import TooltipMui from "../../Basic/TooltipMui";
import CompleteButton from "./CompleteButton";
import MobileInputArea from "./MobileInputArea";
import SuggestedAnswer from "./SuggestedAnswer";

type MessagingInputArea = {
  setScrolledUp: (scrolledUp: boolean) => void;
  messages: Record<number, string>;
  setMessages: (messages: Record<number, string>) => void;
  showResponse: boolean;
  setShowResponse: (showResponse: boolean) => void;
  currentConversation: Conversation;
  selectedConversationId: string;
  className?: string;
  completeClassName?: string;
};

const MessagingInputArea = ({
  setScrolledUp,
  messages,
  setMessages,
  showResponse,
  setShowResponse,
  currentConversation,
  selectedConversationId,
  className = "",
  completeClassName,
}: MessagingInputArea) => {
  const [suggestedAnswerData, setSuggestedAnswerData] = useState("");

  const { closedResponses } = useAppSelector((state) => state.conversation);

  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isHeightSm = useMediaQuery({ minHeight: 640 });

  const [sendMessage] = useSendMessageMutation();

  // Single effect to handle conversation changes and message initialization
  useEffect(() => {
    if (currentConversation) {
      setSuggestedAnswerData(currentConversation.suggested_answer || "");

      if (currentConversation.suggested_answer) {
        setShowResponse(
          !closedResponses.includes(currentConversation.conversation_id),
        );
      } else {
        setShowResponse(false);
      }
    }
  }, [currentConversation, closedResponses, setShowResponse]);

  const onSendMessage = () => {
    sendMessage({
      message: messages[selectedConversationId],
      conversationId: Number(selectedConversationId),
      isInternal: false,
      idempotencyKey: uuid(),
    });
    setScrolledUp(false);

    const newMessages = { ...messages };
    newMessages[selectedConversationId] = "";
    setMessages(newMessages);
  };

  const onResponse = () => {
    setSuggestedAnswerData(currentConversation?.suggested_answer || "");
    if (!showResponse) {
      if (!currentConversation?.suggested_answer) {
        dispatch(suggestAnswer(selectedConversationId));
      }
      setShowResponse(true);
    } else {
      setShowResponse(false);
    }
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      !event.altKey &&
      !event.ctrlKey &&
      !event.shiftKey &&
      event.key === "Enter"
    ) {
      event.preventDefault();
      onSendMessage();
    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newMessages = { ...messages };
    newMessages[selectedConversationId] = e.target.value;
    setMessages(newMessages);
  };

  return (
    <div className={className}>
      {isDesktop && isHeightSm ? (
        <div className="px-4 pb-4 w-full max-w-5xl mx-auto">
          {showResponse && currentConversation?.show_response && (
            <SuggestedAnswer
              answer={suggestedAnswerData}
              onCancel={() => {
                setShowResponse(false);
                if (!closedResponses.includes(Number(selectedConversationId))) {
                  dispatch(
                    setClosedResponsesAction(Number(selectedConversationId)),
                  );
                }
              }}
              onUse={() => {
                const newMessages = { ...messages };
                newMessages[selectedConversationId] = suggestedAnswerData;
                setMessages(newMessages);
                setShowResponse(false);
              }}
            />
          )}

          <div className="py-3 bg-white rounded-lg border border-gray-foreground">
            <TextareaAutosize
              value={messages[selectedConversationId] || ""}
              onChange={handleTextChange}
              onKeyUp={onKeyUp}
              placeholder="Type a message"
              minRows={2}
              maxRows={14}
              className="w-full resize-none scrollbar border-r-8 border-white pl-3 pr-1 pt-1 outline-none
                focus:outline-none focus:ring-0 text-xs bg-transparent align-top mb-1 leading-[1.3]"
            />

            <div className="px-3 h-9 flex items-center justify-between">
              {currentConversation?.show_response ? (
                <button
                  type="button"
                  aria-label="Suggest answer"
                  className="flex items-center justify-center rounded-lg h-9 w-9 hover:bg-gray-background"
                  onClick={onResponse}
                >
                  <WandIcon width="20" height="20" stroke="#2970FF" />
                </button>
              ) : (
                <TooltipMui title="Conversation in progress" arrow>
                  <div
                    className="flex items-center justify-center rounded-lg h-9 w-9 hover:bg-gray-background
                      cursor-default"
                  >
                    <WandIcon width="20" height="20" />
                  </div>
                </TooltipMui>
              )}
              <button
                type="button"
                aria-label="Send message"
                className="w-9 h-9 bg-primary-blue rounded-lg flex items-center justify-center"
                onClick={onSendMessage}
              >
                <SendIcon width="18" height="18" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <MobileInputArea
          messages={messages}
          setMessages={setMessages}
          onSendMessage={onSendMessage}
          onClickWand={onResponse}
          showResponse={showResponse}
          setShowResponse={setShowResponse}
          suggestedAnswer={suggestedAnswerData}
          currentConversation={currentConversation}
          selectedConversationId={selectedConversationId}
        />
      )}
      <CompleteButton
        conversation={currentConversation}
        className={completeClassName}
      />
    </div>
  );
};

export default MessagingInputArea;
