import { useEffect } from "react";
import { useAppDispatch } from "../../../store";
import { callApi } from "../../../store/call/api";

export const usePrefetchConversations = (
  conversations: Array<{ patient: { conversation_id: number } }> | undefined,
  selectedId: number | null,
) => {
  const dispatch = useAppDispatch();
  const PREFETCH_COUNT = 3;

  useEffect(() => {
    if (!conversations?.length) {
      return;
    }

    conversations.slice(0, PREFETCH_COUNT).forEach((conv) => {
      dispatch(
        callApi.util.prefetch("getConversation", conv.patient.conversation_id, {
          force: false,
        }),
      );
    });
  }, [conversations, dispatch]);

  useEffect(() => {
    if (!conversations?.length || !selectedId) {
      return;
    }

    const currentIndex = conversations.findIndex(
      (conv) => conv.patient.conversation_id === selectedId,
    );

    if (currentIndex === -1) {
      return;
    }

    if (currentIndex > 0) {
      const prevId = conversations[currentIndex - 1].patient.conversation_id;
      dispatch(
        callApi.util.prefetch("getConversation", prevId, { force: false }),
      );
    }

    if (currentIndex < conversations.length - 1) {
      const nextId = conversations[currentIndex + 1].patient.conversation_id;
      dispatch(
        callApi.util.prefetch("getConversation", nextId, { force: false }),
      );
    }
  }, [conversations, selectedId, dispatch]);
};
