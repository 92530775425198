import { useEffect } from "react";
import { Navigate, Outlet } from "react-router";
import { Pages } from "../../helpers/constants";
import LocalStorageService from "../../services/LocalStorageService";
import { useAppDispatch, useAppSelector } from "../../store";
import { logoutAction } from "../../store/user/actions";
import { selectUser } from "../../store/user/userReducer";
import Layout from "../Layout";

const storageService = new LocalStorageService();

const ProtectedRoute = () => {
  const user = useAppSelector(selectUser);
  const token = storageService.getItem("token");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user || !token) {
      dispatch(logoutAction());
    }
  }, [dispatch, user, token]);

  if (!user || !token) {
    return <Navigate to={Pages.LOGIN} />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default ProtectedRoute;
