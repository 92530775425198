import { ReactComponent as SearchIcon } from "@assets/icons/search.svg";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import {
  type LegacyRef,
  type ReactNode,
  createContext,
  forwardRef,
  useContext,
  useState,
} from "react";
import { FixedSizeList } from "react-window";
import type { FixLater } from "../../IndependentScribe/store/scribeSlice";
import UserOption from "./UserOption";

interface AutocompleteVirtualizedProps {
  label?: string;
  placeholder?: string;
  options: any[];
  disabledIds?: number[];
  onSelect: (value: any) => void;
  className?: string;
  isDoctors?: boolean;
  required?: boolean;
  error?: FixLater;
}

const AutocompleteVirtualized = ({
  label = "",
  placeholder = "",
  options,
  disabledIds,
  onSelect,
  className = "",
  isDoctors,
  required = false,
  error,
}: AutocompleteVirtualizedProps) => {
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const getOptionLabel = (option) => {
    if (isDoctors) {
      return option.display_name;
    }

    return `${option.preferred_name ? option.preferred_name : option.first_name} ${option.last_name}`;
  };

  const getOptionDisabled = (option) => {
    if (isDoctors) {
      return disabledIds?.includes(option.doctor_id);
    }

    return disabledIds?.includes(option.patient_id);
  };

  const Row = ({ index, data, style }) => {
    const dataSet = data[index];
    return <div style={style}>{dataSet}</div>;
  };

  const OuterElementContext = createContext({});

  const OuterElementType = forwardRef(
    (
      props: React.ComponentPropsWithoutRef<"div">,
      ref: LegacyRef<HTMLDivElement>,
    ) => {
      const outerProps = useContext(OuterElementContext);
      return <div ref={ref} {...props} {...outerProps} />;
    },
  );

  const ListboxComponent = forwardRef(
    (
      { children, ...props }: { children: ReactNode[] },
      ref: LegacyRef<HTMLDivElement>,
    ) => {
      const itemCount = children.length;
      const itemSize = 50;

      const getHeight = () => {
        if (itemCount > 8) {
          return 8 * itemSize;
        }
        return itemCount * itemSize;
      };

      return (
        <div ref={ref}>
          <OuterElementContext.Provider value={props}>
            <FixedSizeList
              itemData={children}
              height={getHeight()}
              itemCount={itemCount}
              itemSize={itemSize}
              width="100%"
              outerElementType={OuterElementType}
              overscanCount={5}
            >
              {Row}
            </FixedSizeList>
          </OuterElementContext.Provider>
        </div>
      );
    },
  );

  const PaperComponent = ({ children, ...props }) => {
    return (
      <Paper
        {...props}
        sx={{
          margin: 0,
          padding: 0,
          borderRadius: "12px",
          "& > div > div > div": {
            padding: "0 !important",
          },
        }}
      >
        <div className="p-2 pr-1.5">{children}</div>
      </Paper>
    );
  };

  return (
    <div className={className}>
      {label && (
        <p className="text-sm font-semibold mb-1.5">
          {label}
          {required && <span className="text-red-600">*</span>}
        </p>
      )}
      <Autocomplete
        autoHighlight
        disableListWrap
        options={options}
        getOptionDisabled={getOptionDisabled}
        getOptionLabel={getOptionLabel}
        renderOption={(props, option) => (
          <UserOption
            isDoctors={isDoctors}
            option={option}
            {...props}
            key={isDoctors ? option.doctor_id : option.patient_id}
          />
        )}
        value={autocompleteValue}
        onChange={(_e, value) => {
          onSelect(value);
          setAutocompleteValue(null);
          setInputValue("");
        }}
        inputValue={inputValue}
        onInputChange={(_e, inputValue) => {
          setInputValue(inputValue);
        }}
        ListboxProps={{
          className:
            "scrollbar py-0 font-Mulish text-sm font-semibold text-zinc-700",
        }}
        ListboxComponent={ListboxComponent}
        PaperComponent={PaperComponent}
        renderInput={(params) => (
          <>
            <div ref={params.InputProps.ref} className="relative">
              <input
                type="text"
                placeholder={placeholder}
                {...params.inputProps}
                className={`py-2.5 pr-9 pl-3 rounded-lg border w-full outline-none text-sm text-zinc-700 bg-white
                  ${error ? "border-red-400" : "border-gray-300"}`}
              />
              <div className="absolute right-2.5 top-1/2 -translate-y-1/2">
                <SearchIcon width="18" height="18" stroke="#A0A6B2" />
              </div>
            </div>
            {error && (
              <p className="text-red-400 font-normal mt-1.5 text-sm">
                {error.message}
              </p>
            )}
          </>
        )}
      />
    </div>
  );
};

export default AutocompleteVirtualized;
