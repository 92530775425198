import automaticProfileSrc from "@assets/quadrant-ai.svg";
import { useAppSelector } from "../../../store";
import {
  CallDirection,
  type Conversation,
  type Doctor,
  MessageFrom,
} from "../../../store/call/interfaces";
import {
  type MessageInboxItem,
  MessageType,
  type NoteInboxItem,
} from "../../../store/conversation/interfaces";
import type { Message } from "../../../store/conversation/validationSchemas";
import { selectUser } from "../../../store/user/userReducer";
import type { MentionItem } from "../Mentions/interfaces";
import CallTimelineCard from "./CallCard/CallTimelineCard";
import NoteTimelineCard from "./CallCard/NoteTimelineCard";
import DateDivider from "./DateDivider";
import MessageCloud from "./MessageCloud";
import StatusUpdateMessage from "./StatusUpdateMessage";
import { getMessageDisplayProperties } from "./messageDisplayUtils";
import {
  getMessageProfilePicture,
  getMessageSenderFirstName,
} from "./messageUtils";

interface MessageListProps {
  conversation: Conversation;
  mentionItems: MentionItem[];
  patientPicture: string;
  dateRefs: React.MutableRefObject<any[]>;
}

const MessageList = ({
  conversation,
  mentionItems,
  patientPicture,
  dateRefs,
}: MessageListProps) => {
  const user = useAppSelector(selectUser);

  if (!conversation?.messages.length) {
    return null;
  }

  return (
    <>
      {conversation.messages.map((message: Message, index: number) => {
        const isDoctor = message.from === MessageFrom.Doctor;
        const isAutomatic = message.is_automatic === 1;
        const isOutgoing =
          isAutomatic ||
          (isDoctor && message.sender?.doctor_id === user?.doctor_id);
        const { combineWithPrevious, dateDivider } =
          getMessageDisplayProperties(message, index, conversation);

        const content = (
          <>
            {message.type === MessageType.Call && (
              <CallTimelineCard
                call={(message.data as MessageInboxItem).call}
                sender={
                  message.sender &&
                  (message.data as MessageInboxItem).call.direction ===
                    CallDirection.Outbound
                    ? {
                        firstName: message.sender.first_name,
                        lastName: message.sender.last_name,
                        profilePicture: message.sender.profile_picture,
                        displayName: (message.sender as Doctor).display_name,
                      }
                    : {
                        firstName: conversation.patient.first_name,
                        lastName: conversation.patient.last_name,
                        profilePicture: conversation.patient.profile_picture,
                        displayName: "",
                      }
                }
                className="mt-3"
              />
            )}

            {message.type === MessageType.StatusUpdate &&
              (message.data as MessageInboxItem).status_update && (
                <StatusUpdateMessage
                  className="my-8"
                  statusUpdateData={
                    (message.data as MessageInboxItem).status_update
                  }
                />
              )}

            {message.type === MessageType.Text && (
              <MessageCloud
                mentionItems={mentionItems}
                combineWithPrevious={combineWithPrevious}
                type={isOutgoing ? "outgoing" : "incoming"}
                firstName={getMessageSenderFirstName(message, isAutomatic)}
                lastName={isAutomatic ? "AI" : message.sender.last_name}
                displayName={
                  isAutomatic ? "" : (message.sender as Doctor).display_name
                }
                role={
                  isDoctor && !isAutomatic
                    ? (message.sender as Doctor).occupation
                    : ""
                }
                profilePicture={getMessageProfilePicture(
                  message,
                  isAutomatic,
                  isDoctor,
                  patientPicture,
                  automaticProfileSrc,
                )}
                message={(message.data as MessageInboxItem).message}
                time={message.send_at}
                isInternal={message.is_internal === 1}
              />
            )}

            {message.type === MessageType.Note && (
              <NoteTimelineCard note={(message.data as NoteInboxItem).note} />
            )}
          </>
        );

        return (
          <div key={index}>
            {dateDivider ? (
              <>
                <DateDivider
                  date={message.send_at}
                  ref={(el) => {
                    dateRefs.current[index] = el;
                  }}
                />
                {content}
              </>
            ) : (
              content
            )}
          </div>
        );
      })}
    </>
  );
};

export default MessageList;
