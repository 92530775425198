import type z from "zod";
import { providerSchema } from "../../helpers/commonValidationSchemas";
import { baseApi, validateResponse } from "../baseApi";

export const providerApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProvider: builder.query<z.infer<typeof providerSchema>, number>({
      query: (providerId) => ({
        url: `/provider/${providerId}`,
      }),
      transformResponse: (response: z.infer<typeof providerSchema>) => {
        validateResponse(providerSchema)(response);
        return response;
      },
      providesTags: (_, __, providerId: number) => [
        {
          type: "Provider",
          providerId,
        },
      ],
    }),
  }),
  overrideExisting: false,
});

export const { useGetProviderQuery } = providerApi;
