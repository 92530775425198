import { ReactComponent as PauseIcon } from "@assets/icons/pause-circle.svg";
import { ReactComponent as EditIcon } from "@assets/icons/pen.svg";
import { ReactComponent as DeleteIcon } from "@assets/icons/trash-redesign.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import ActionsButton from "../components/Basic/ActionsButton";
import AddEditPatientModal from "../components/Basic/AddEditPatientModal";
import Tag from "../components/Basic/Tag";
import { MOCK_PATIENT_FIRST_NAME } from "../components/IndependentScribe/consts";
import DeleteModal from "../components/Patients/DeleteModal";
import ImportModal from "../components/Patients/ImportModal";
import IndeterminateCheckbox from "../components/Patients/IndeterminateCheckbox";
import PatientsTable, {
  DateCell,
  PhoneCell,
} from "../components/Patients/PatientsTable";
import TableHeader from "../components/Patients/TableHeader";
import { rowsPerPage } from "../helpers/constants";
import { getAge, getPatientMrn, getPatientSex } from "../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../store";
import { setSelectedEnrollPatientsAction } from "../store/campaign/actions";
import { FeatureFlags, useFeature } from "../store/featureFlagSlice";
import { setSelectedPatientsAction } from "../store/patient/actions";
import {
  pausePatient,
  removePatients,
  searchPatients,
  unpausePatient,
} from "../store/patient/thunks";
import { selectUser } from "../store/user/userReducer";
const Patients = () => {
  const {
    patientsPage,
    searchValue,
    selectedPatients,
    currPage,
    selectedDoctorPatients,
    selectedPatientCampaigns,
    selectedPatientStatuses,
    selectedPatientInsurances,
  } = useAppSelector((state) => state.patient);
  const user = useAppSelector(selectUser);
  const { selectedEnrollPatients } = useAppSelector((state) => state.campaign);
  const isCCM = useFeature(FeatureFlags.CCM);

  const [openPatientModal, setOpenPatientModal] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [patientData, setPatientData] = useState(null);
  const [selectedPatientsState, setSelectedPatientsState] =
    useState(selectedPatients);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [cancelSelection, setCancelSelection] = useState(false);

  const currPageRef = useRef(currPage);
  const searchValueRef = useRef(searchValue);
  const patientsPageRef = useRef(patientsPage);

  const dispatch = useAppDispatch();

  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isHeightSm = useMediaQuery({ minHeight: 640 });

  useEffect(() => {
    currPageRef.current = currPage;
  }, [currPage]);

  useEffect(() => {
    searchValueRef.current = searchValue;
  }, [searchValue]);

  useEffect(() => {
    patientsPageRef.current = patientsPage;
  }, [patientsPage]);

  useEffect(() => {
    dispatch(
      searchPatients(
        searchValueRef.current,
        1,
        rowsPerPage * currPageRef.current,
        selectedDoctorPatients,
        patientsPageRef.current === null,
        () => {},
        selectedPatientCampaigns,
        selectedPatientStatuses,
        selectedPatientInsurances,
      ),
    );
  }, [
    selectedPatientCampaigns,
    selectedPatientStatuses,
    selectedPatientInsurances,
    selectedDoctorPatients,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(setSelectedPatientsAction(selectedPatientsState));
  }, [selectedPatientsState, dispatch]);

  const columns = useMemo(
    () =>
      [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps, flatRows }) => (
            <div>
              <IndeterminateCheckbox
                {...getToggleAllRowsSelectedProps()}
                onClick={(e) => {
                  const rows = flatRows.map((row) => row.original);
                  const unselectedFlatRows = flatRows.filter(
                    (flatRow) => !flatRow.isSelected,
                  );
                  const unselectedRows = unselectedFlatRows.map(
                    (row) => row.original,
                  );

                  if (e.target.checked) {
                    setSelectedPatientsState((selectedPatientsState) => [
                      ...selectedPatientsState,
                      ...unselectedRows,
                    ]);
                  } else {
                    setSelectedPatientsState((selectedPatientsState) =>
                      selectedPatientsState.filter(
                        (selectedPatient) =>
                          !rows.some(
                            (row) =>
                              selectedPatient.patient_id === row.patient_id,
                          ),
                      ),
                    );
                  }
                }}
              />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                onClick={() => {
                  if (row.isSelected) {
                    setSelectedPatientsState((selectedPatientsState) =>
                      selectedPatientsState.filter(
                        (patient) =>
                          patient.patient_id !== row.original.patient_id,
                      ),
                    );
                  } else {
                    setSelectedPatientsState((selectedPatientsState) => [
                      ...selectedPatientsState,
                      row.original,
                    ]);
                  }
                }}
              />
            </div>
          ),
        },
        {
          Header: "Preferred First Name",
          accessor: "preferred_name",
          Cell: ({ row }) => {
            return (
              <div className="truncate w-full max-w-[150px] xl:max-w-[170px] 2xl:max-w-[300px] text-left">
                {row.original.preferred_name || row.original.first_name}
              </div>
            );
          },
        },
        {
          Header: "Last Name",
          accessor: "last_name",
          Cell: ({ row }) => {
            return (
              <div className="truncate w-full max-w-[150px] xl:max-w-[170px] 2xl:max-w-[300px] text-left">
                {row.original.last_name}
              </div>
            );
          },
        },
        {
          Header: "Date of Birth",
          accessor: "birthdate",
          Cell: DateCell,
        },
        {
          Header: "Age",
          accessor: "age",
          Cell: ({ row }) => {
            return (
              <div>
                {row.original.birthdate ? getAge(row.original.birthdate) : "-"}
              </div>
            );
          },
        },
        {
          Header: "Sex",
          accessor: "sex",
          Cell: ({ row }) => {
            const patientSex = getPatientSex(row.original.sex);
            return (
              <div className="capitalize">
                {patientSex ? patientSex.charAt(0) : "-"}
              </div>
            );
          },
        },
        user.customer.has_ehr_data && {
          Header: "MRN",
          accessor: "chart_number",
          Cell: ({ row }) => {
            const mrn = useMemo(
              () =>
                getPatientMrn(
                  row.original.chart_number,
                  user.customer.has_ehr_data,
                ),
              [row.original.chart_number],
            );

            return (
              <div className="capitalize">
                <div className="truncate w-full max-w-[150px] xl:max-w-[170px] 2xl:max-w-[300px] text-left">
                  {mrn || "-"}
                </div>
              </div>
            );
          },
        },
        {
          Header: "Phone Number",
          accessor: "phone_number",
          Cell: ({ row }) => (
            <PhoneCell
              value={row.original.phone_number}
              patientId={row.original.patient_id}
            />
          ),
        },
        isCCM && {
          Header: "Care Coordination",
          accessor: "care_coordination",
          Cell: ({ row }) => (
            <Tag text={row.original.care_coordination} variant="gray" />
          ),
        },
        {
          Header: "",
          accessor: "options",
          Cell: ({ row }) => {
            const actions = [
              {
                icon: (
                  <PauseIcon
                    width="20"
                    height="20"
                    stroke="#667085"
                    className="flex-none"
                  />
                ),
                label: row.original.is_paused ? "Unpause" : "Pause",
                onClick: () =>
                  onPause(
                    row.original.patient_id,
                    row.original.is_paused === 1,
                  ),
              },
              {
                icon: (
                  <DeleteIcon
                    width="20"
                    height="20"
                    stroke="#667085"
                    className="flex-none"
                  />
                ),
                label: "Delete",
                onClick: () => onDeletePatients(row.original),
              },
            ];

            if (!user.customer.has_ehr_data) {
              actions.push({
                icon: (
                  <EditIcon
                    width="20"
                    height="20"
                    stroke="#667085"
                    className="flex-none"
                  />
                ),
                label: "Edit",
                onClick: () => onEditPatient(row.original),
              });
            }

            return <ActionsButton actions={actions} />;
          },
        },
      ].filter(Boolean),
    [user.customer.has_ehr_data, isCCM],
  );

  const onAddPatient = () => {
    setOpenPatientModal(true);
  };

  const onImportPatients = () => {
    setOpenImportModal(true);
  };

  const onDeletePatients = (patient) => {
    if (patient) {
      setSelectedPatient(patient);
    }
    setOpenDeleteModal(true);
  };

  const onEditPatient = (patient) => {
    setPatientData(patient);
    setOpenPatientModal(true);
  };

  const onPause = (patientId, paused) => {
    if (paused) {
      dispatch(
        unpausePatient(
          patientId,
          1,
          rowsPerPage * currPage,
          searchValue,
          selectedDoctorPatients,
          selectedPatientCampaigns,
          selectedPatientStatuses,
        ),
      );
    } else {
      dispatch(
        pausePatient(
          patientId,
          1,
          rowsPerPage * currPage,
          searchValue,
          selectedDoctorPatients,
          selectedPatientCampaigns,
          selectedPatientStatuses,
        ),
      );
    }
  };

  const deletePatients = () => {
    const patientIds = selectedPatient
      ? [selectedPatient.patient_id]
      : selectedPatientsState.map((patient) => patient.patient_id);
    dispatch(
      removePatients(
        patientIds,
        1,
        rowsPerPage * currPage,
        searchValue,
        selectedDoctorPatients,
        () => {
          setCancelSelection(true);
          setSelectedPatient(null);

          // TODO: test
          // if (
          //   currentConversation &&
          //   patientIds.includes(currentConversation.patient.patient_id)
          // ) {
          //   setSelectedConversationId(null);
          // }

          patientIds.forEach((patientId) => {
            removeSelectedEnrollPatients(patientId);
          });
        },
        selectedPatientCampaigns,
        selectedPatientStatuses,
      ),
    );
  };

  const removeSelectedEnrollPatients = (patientId) => {
    const updatedEnrollState = selectedEnrollPatients.filter(
      (patient) => patient.patient_id !== patientId,
    );
    dispatch(setSelectedEnrollPatientsAction(updatedEnrollState));
  };

  const patients = patientsPage?.filter(
    (patient) => patient.first_name !== MOCK_PATIENT_FIRST_NAME,
  );

  return (
    <div
      className={`w-full overflow-hidden grid ${isDesktop ? "h-screen-dynamic" : ""}`}
    >
      <div
        className={`flex overflow-hidden bg-gray-background
          ${isDesktop && isHeightSm ? "p-4 height-md:p-6" : "p-4 md:p-2"}`}
      >
        <div className="h-full w-full flex flex-col overflow-hidden">
          <TableHeader
            setCancelSelection={setCancelSelection}
            onAddPatient={onAddPatient}
            onImportPatients={onImportPatients}
            onDelete={onDeletePatients}
          />
          <PatientsTable
            columns={columns}
            data={patients}
            cancelSelection={cancelSelection}
            setCancelSelection={setCancelSelection}
            onAdd={onAddPatient}
            onDelete={onDeletePatients}
            onEdit={onEditPatient}
            onPause={onPause}
          />
        </div>
      </div>

      <AddEditPatientModal
        open={openPatientModal}
        onClose={() => {
          setOpenPatientModal(false);
          setPatientData(null);
        }}
        patientInfo={patientData}
      />

      {openImportModal && (
        <ImportModal onClose={() => setOpenImportModal(false)} />
      )}
      {openDeleteModal && (
        <DeleteModal
          onClose={() => {
            setOpenDeleteModal(false);
            setSelectedPatient(null);
          }}
          onSubmit={deletePatients}
          patients={selectedPatient ? [selectedPatient] : selectedPatientsState}
        />
      )}
    </div>
  );
};

export default Patients;
