import { ReactComponent as CheckIcon } from "@assets/icons/check-box.svg";
import type React from "react";
import { forwardRef } from "react";

const checkboxVariants = {
  primary: "border-primary-blue bg-purple-50 text-primary-blue",
  "primary-filled": "border-primary-blue bg-primary-blue text-white",
  dark: "border-blue-1000 bg-blue-1000 text-white",
};

const checkIconColor = {
  primary: "#2970FF",
  "primary-filled": "#FFFFFF",
  dark: "#FFFFFF",
};

const Checkbox = forwardRef(
  (
    {
      label,
      name,
      checked,
      onChange,
      className = "items-center",
      error,
      variant = "primary",
      number,
      register = () => {},
      required = false,
      checkboxClassName = "",
      labelClassName = "ml-3 text-sm font-medium",
      onBlur = (_) => {},
      ...props
    }: {
      label?: string;
      name: string;
      checked: boolean;
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
      className?: string;
      error?: boolean;
      variant?: "primary" | "primary-filled" | "dark";
      number?: number;
      register?: any;
      required?: boolean;
      checkboxClassName?: string;
      labelClassName?: string;
      onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
      [key: string]: any;
    },
    ref,
  ) => {
    return (
      <label className={`flex ${className}`}>
        <input
          name={name}
          type="checkbox"
          className="hidden"
          ref={ref}
          onChange={onChange}
          {...register(name, {
            required: required,
          })}
          onAbort={onBlur}
          {...props}
        />
        <div
          className={`w-5 h-5 rounded border flex items-center justify-center cursor-pointer text-xs flex-none
            ${
              error
                ? "border-red-400"
                : checked
                  ? checkboxVariants[variant]
                  : "border-gray-300"
            } ${checkboxClassName}`}
        >
          {checked &&
            (number ? (
              <span>{number}</span>
            ) : (
              <CheckIcon
                width="10"
                height="8"
                stroke={checkIconColor[variant]}
              />
            ))}
        </div>
        {label && (
          <span className={`${error ? "text-red-400" : ""} ${labelClassName}`}>
            {label}
          </span>
        )}
      </label>
    );
  },
);

export default Checkbox;
