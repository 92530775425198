import { ReactComponent as ArrowIcon } from "@assets/icons/arrow-down.svg";
import { skipToken } from "@reduxjs/toolkit/query";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import {
  Pages,
  patientPanelTabs,
  patientProfileTabs,
} from "../../../helpers/constants";
import { getFormattedPhoneNumber } from "../../../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useGetConversationQuery } from "../../../store/call/api";
import { setSelectedConversationPatientAction } from "../../../store/conversation/actions";
import {
  setSelectedPatientPanelTabAction,
  setSelectedPatientProfileTabAction,
} from "../../../store/patient/actions";
import { getPatientInfo } from "../../../store/patient/thunks";
import { setHideMobileNavbar } from "../../../store/ui/actions";
import { setOpenCallOptionsModalAction } from "../../../store/voiceRecorder/actions";
import ProfilePicture from "../../Basic/ProfilePicture";
import useConversationSearchParams from "../../Inbox/hooks/useConversationSearchParams";
import PatientShortInfo from "../../PatientProfile/PatientShortInfo";
const TopBar = () => {
  const { selectedConversationPatient } = useAppSelector(
    (state) => state.conversation,
  );
  const { selectedConversationId } = useConversationSearchParams();
  const dispatch = useAppDispatch();
  const isXl = useMediaQuery({ minWidth: 1280 });
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const navigate = useNavigate();

  const { data: currentConversation } = useGetConversationQuery(
    selectedConversationId ? Number(selectedConversationId) : skipToken,
  );
  const patient = currentConversation?.patient || selectedConversationPatient;

  const returnToConversationList = () => {
    dispatch(setHideMobileNavbar(false));
    dispatch(setSelectedConversationPatientAction(null));
  };

  return (
    <div
      className="md:h-20 py-3 md:py-4 pl-2 pr-8 md:px-4 xl:px-6 w-full flex items-start gap-2 bg-white md:rounded-lg
        border-b md:border border-gray-200 overflow-y-visible"
    >
      <button
        type="button"
        aria-label="Return to conversation list"
        className="md:hidden mt-2 w-6 h-6 flex items-center justify-center"
        onClick={returnToConversationList}
      >
        <ArrowIcon className="rotate-90" width="10" height="7" />
      </button>

      {patient && (
        <button
          type="button"
          className="grid md:grid-cols-2-right items-center gap-3 cursor-pointer w-full"
          onClick={() => {
            dispatch(
              getPatientInfo(patient.patient_id, () => {
                navigate(`${Pages.PATIENTS}/${patient.patient_id}`);
                dispatch(setOpenCallOptionsModalAction(true));
              }),
            );
            dispatch(
              setSelectedPatientProfileTabAction(patientProfileTabs.NOTES),
            );
            dispatch(
              setSelectedPatientPanelTabAction(patientPanelTabs.MESSAGE),
            );
          }}
        >
          {isDesktop && (
            <ProfilePicture
              src={patient.profile_picture}
              firstName={
                patient.preferred_name
                  ? patient.preferred_name
                  : patient.first_name
              }
              lastName={patient.last_name}
              size={isXl ? 12 : 10}
              fontSize="base"
              className="h-10 w-10 xl:h-12 xl:w-12"
            />
          )}

          <div className="flex flex-col items-start truncate">
            <p className="font-semibold text-base mt-1 md:mt-0">
              {patient.preferred_name
                ? patient.preferred_name
                : patient.first_name}{" "}
              {patient.last_name}
            </p>
            {patient.phone_number && !isDesktop ? (
              <div className="leading-none mt-1 pb-0.5 w-full truncate text-left text-xs lg:text-base text-tertiary">
                <p>{getFormattedPhoneNumber(patient.phone_number)}</p>
              </div>
            ) : (
              <PatientShortInfo
                patient={patient}
                className="mt-1 pb-0.5 truncate text-xs text-tertiary"
                showBirthdate={false}
              />
            )}
          </div>
        </button>
      )}
    </div>
  );
};

export default TopBar;
