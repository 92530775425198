import { skipToken } from "@reduxjs/toolkit/query";
import { useCallback, useEffect, useRef, useState } from "react";
import { inboxes } from "../../../helpers/constants";
import { useGetConversationQuery } from "../../../store/call/api";
import useConversationSearchParams from "../../Inbox/hooks/useConversationSearchParams";
const InboxTabsBorder = ({
  tabs,
  activeTab,
  setActiveTab,
  className,
  tabClassName,
}: {
  tabs: { name: string; conversations: number }[];
  activeTab: string;
  setActiveTab: (value: string) => void;
  className?: string;
  tabClassName?: string;
}) => {
  const { selectedConversationId } = useConversationSearchParams();
  const tabRefs = useRef<HTMLLIElement[]>([]);
  const containerRef = useRef(null);
  const [translate, setTranslate] = useState(0);
  const [tabSizes, setTabSizes] = useState<Record<number, number>>({});
  const [transition, setTransition] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);

  const { data: currentConversation } = useGetConversationQuery(
    selectedConversationId ? Number(selectedConversationId) : skipToken,
  );

  useEffect(() => {
    if (tabs.length > 0 && activeTab) {
      setActiveIndex(tabs.findIndex((tab) => tab.name === activeTab));
    } else {
      setActiveIndex(0);
    }
  }, [activeTab, tabs]);

  const updateTranslate = useCallback(() => {
    let translationOffset = 0;
    if (tabSizes) {
      translationOffset = Object.values(tabSizes)
        .slice(0, activeIndex)
        .reduce((a, b) => a + b, 0);
    }
    setTranslate(translationOffset);
  }, [tabSizes, activeIndex]);

  const updateTabSizes = useCallback(() => {
    tabRefs.current.forEach((ref, index) =>
      setTabSizes((tabSizes) => ({
        ...tabSizes,
        [index]: ref.offsetWidth,
      })),
    );
  }, []);

  const handleResize = useCallback(() => {
    updateTabSizes();
    updateTranslate();
  }, [updateTabSizes, updateTranslate]);

  useEffect(() => {
    const id = setTimeout(() => {
      setTransition("transition-all");
    }, 100);

    return () => {
      clearTimeout(id);
    };
  }, []);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, [handleResize]);

  useEffect(() => {
    let id: NodeJS.Timeout;
    if (!currentConversation) {
      id = setTimeout(() => {
        handleResize();
      }, 300);
    }

    return () => {
      clearTimeout(id);
    };
  }, [currentConversation, handleResize]);

  useEffect(() => {
    updateTabSizes();
  }, [updateTabSizes]);

  useEffect(() => {
    updateTranslate();
  }, [updateTranslate]);

  const getTab = (tab, index) => {
    return (
      <li
        key={tab.name}
        ref={(el) => {
          tabRefs.current[index] = el;
        }}
        onClick={() => setActiveTab(tab.name)}
        className={`cursor-pointer font-semibold relative transition-all pb-2.5 px-2 text-sm select-none
          flex items-center justify-center space-x-1 capitalize ${tabClassName}
          ${activeTab === tab.name ? "text-primary-blue" : "text-slate-400"} `}
      >
        <p>{tab.name}</p>
        {tab.name !== inboxes.COMPLETE && (
          <span
            className={`h-5 min-w-[20px] text-white text-xs font-medium rounded-full flex items-center justify-center px-1.5
              ${activeTab === tab.name ? "bg-primary-blue" : "bg-slate-400"}
              `}
          >
            {tab.conversations}
          </span>
        )}
      </li>
    );
  };

  return (
    <ul
      className={`flex w-fit min-w-full md:w-full relative ${className}`}
      ref={containerRef}
    >
      <div
        aria-hidden="true"
        style={{
          transform: `translateX(${translate}px)`,
          width: tabSizes
            ? `${tabSizes[tabs.findIndex((tab) => tab.name === activeTab)]}px`
            : "33px",
          height: "2px",
        }}
        className={`absolute rounded-lg z-0 bg-primary-blue -bottom-0.5 left-0 ${transition}`}
      />
      {tabs.map((tab, index) => getTab(tab, index))}
    </ul>
  );
};

export default InboxTabsBorder;
