import { type ChangeEvent, type KeyboardEvent, useRef, useState } from "react";

interface CallNoteEditingProps {
  value: string;
  setValue: (value: string) => void;
  onDelete: () => void;
  onSave: () => void;
  saveDisabled: boolean;
  saveLabel: string;
}

const CallNoteEditing = ({
  value,
  setValue,
  onDelete,
  onSave,
  saveDisabled,
  saveLabel,
}: CallNoteEditingProps) => {
  const [isFocused, setIsFocused] = useState(true);

  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const handleContainerFocus = () => {
    setIsFocused(true);
    inputRef.current?.focus();
  };

  const handleContainerBlur = (e) => {
    if (!containerRef.current?.contains(e.relatedTarget)) {
      setIsFocused(false);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSave();
    }
  };

  return (
    <div
      ref={containerRef}
      tabIndex={-1}
      className={`border rounded-lg p-3 flex flex-col text-sm
            ${isFocused ? "border-blue-500" : "border-gray-300"}`}
      onClick={handleContainerFocus}
      onBlur={handleContainerBlur}
    >
      <input
        ref={inputRef}
        type="text"
        value={value}
        onChange={handleInputChange}
        onFocus={() => setIsFocused(true)}
        onKeyDown={handleKeyDown}
        className="w-full border-none focus:outline-none text-gray-700"
        placeholder="Enter your note..."
        autoFocus
      />

      <div className="flex justify-end space-x-4 mt-4 font-semibold">
        <button
          type="button"
          tabIndex={0}
          className="px-4 h-8 border border-gray-foreground rounded-lg"
          onClick={onDelete}
        >
          Delete
        </button>
        <button
          type="button"
          tabIndex={0}
          className="px-4 h-8 bg-primary-blue text-white rounded-lg"
          onClick={onSave}
          disabled={saveDisabled}
        >
          {saveLabel}
        </button>
      </div>
    </div>
  );
};

export default CallNoteEditing;
