import { ReactComponent as RemoveIcon } from "@assets/icons/close.svg";
import { ReactComponent as EditIcon } from "@assets/icons/pen.svg";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  chronicCcmPlaceholder,
  chronicPostDischargePlaceholder,
  frequency,
  frequencyCCM,
  goalsCcmPlaceholder,
  goalsPostDischargePlaceholder,
  medicalCcmPlaceholder,
  medicalPostDischargePlaceholder,
  rowsPerPage,
  twoConditionsRegex,
} from "../../../helpers/constants";
import { getFrequencyByInterval } from "../../../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setSelectedStartDateAction } from "../../../store/campaign/actions";
import {
  cancelUpcomingMessage,
  scheduleCampaign,
} from "../../../store/campaign/thunks";
import type { Patient } from "../../../store/patient/interfaces";
import {
  enrollPatientWithAi,
  getPatientEnrollments,
  searchPatients,
} from "../../../store/patient/thunks";
import AutocompleteVirtualized from "../../Basic/Autocomplete/AutocompleteVirtualized";
import Button from "../../Basic/Button";
import DateTimeShortcutsInput from "../../Basic/DateTimeShortcutsInput";
import ProfilePicture from "../../Basic/ProfilePicture";
import Select from "../../Basic/Select";
import Switch from "../../Basic/Switch";
import TextareaAutosizeForm from "../../Basic/TextareaAutosizeForm";
import TextareaOrderedLines from "../../Basic/TextareaOrderedLines";
import type { FixLater } from "../../IndependentScribe/store/scribeSlice";

const inputBlockClasses = "lg:grid grid-cols-2-right gap-4";
const inputLabelClasses = "text-sm font-bold mb-1.5 lg:mt-0.5 lg:mb-0";
const contentClasses = "whitespace-pre-wrap text-sm";

type Enrollment = FixLater;

interface EnrollmentEditFieldsProps {
  enrollment: Enrollment;
  patient: Patient;
}

const EnrollmentEditFields = ({
  enrollment,
  patient,
}: EnrollmentEditFieldsProps) => {
  const [edit, setEdit] = useState(false);
  const [aiChat, setAiChat] = useState(!!patient?.ai_chat);
  const [patientProvider, setPatientProvider] = useState(null);
  const { doctorOptions } = useAppSelector((state) => state.user);
  const {
    searchValue,
    currPage,
    selectedPatientInfo,
    selectedDoctorPatients,
    selectedPatientCampaigns,
    selectedPatientStatuses,
  } = useAppSelector((state) => state.patient);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      chronicConditions:
        enrollment.outreach_focus || enrollment.patient.conditions || "",
      goals: enrollment.outreach_goals || enrollment.patient.goals || "",
      pertinentMedicalHistory:
        enrollment.ehr_information ||
        enrollment.patient.past_medical_history ||
        "",
      frequency: enrollment?.interval
        ? getFrequencyByInterval(enrollment.interval)
        : "",
      start: enrollment.next_checkin
        ? dayjs(`${enrollment.next_checkin.replace(/-/g, "/")} GMT+0`)
        : dayjs(),
    },
  });

  const labelWidth = edit ? "lg:w-32" : "";

  useEffect(() => {
    if (doctorOptions.length > 0 && enrollment.patient?.doctor_id) {
      setPatientProvider(
        doctorOptions.find(
          (doctor) => doctor.doctor_id === enrollment.patient.doctor_id,
        ),
      );
    }
  }, [doctorOptions, enrollment.patient?.doctor_id]);

  const onSubmit = (data) => {
    if (
      enrollment.campaign.campaign_type === "Outreach" &&
      !twoConditionsRegex.test(getValues("chronicConditions"))
    ) {
      setError("chronicConditions", {
        type: "pattern",
        message: "Please provide at least two conditions",
      });
      return;
    }

    let isNow = false;
    if (data.start) {
      data = {
        ...data,
        start: data.start.toDate().toISOString(),
      };
      const currentDateTime = dayjs();
      const selectedDateTime = dayjs(data.start);
      isNow = selectedDateTime.isSameOrBefore(currentDateTime);
    }

    // if the ai_chat was true and now it is false
    if (!!enrollment.patient.ai_chat && !aiChat) {
      // delete the campaign scheduled
      dispatch(
        cancelUpcomingMessage(
          enrollment.campaign_id,
          enrollment.campaign_patient_id,
          enrollment.patient.patient_id,
          () => {
            dispatch(
              searchPatients(
                searchValue,
                currPage,
                rowsPerPage,
                selectedDoctorPatients,
                true,
                () => {},
                selectedPatientCampaigns,
                selectedPatientStatuses,
              ),
            );
            dispatch(getPatientEnrollments(selectedPatientInfo.patient_id));
            setEdit(false);
          },
          false,
        ),
      );
    }

    // if the ai_chat was false and now it is true
    if (!enrollment.patient.ai_chat && !!aiChat) {
      // new schedule
      console.log("new schedule");
      dispatch(
        scheduleCampaign(
          enrollment.campaign_id,
          [patient.patient_id],
          isNow ? "now" : data.start,
          data.frequency,
          data.chronicConditions,
          data.goals,
          data.pertinentMedicalHistory,
          patientProvider
            ? patientProvider.doctor_id
            : enrollment.patient.doctor_id,
          true,
          false,
          () => {
            dispatch(
              searchPatients(
                searchValue,
                currPage,
                rowsPerPage,
                selectedDoctorPatients,
                true,
                () => {},
                selectedPatientCampaigns,
                selectedPatientStatuses,
              ),
            );
            dispatch(getPatientEnrollments(selectedPatientInfo.patient_id));
            setEdit(false);
          },
          false,
        ),
      );
    }

    // if the ai_chat was true and keep true
    if (!!enrollment.patient.ai_chat && !!aiChat) {
      // reschedule if start or frequency is changed
      const reschedule =
        new Date(
          `${enrollment.next_checkin.replace(/-/g, "/")} GMT+0`,
        ).toLocaleDateString() !== new Date(data.start).toLocaleDateString() ||
        enrollment.frequency !== data.frequency;
      console.log("reschedule", reschedule);

      dispatch(
        scheduleCampaign(
          enrollment.campaign_id,
          [patient.patient_id],
          isNow ? "now" : data.start,
          data.frequency,
          data.chronicConditions,
          data.goals,
          data.pertinentMedicalHistory,
          patientProvider
            ? patientProvider.doctor_id
            : enrollment.patient.doctor_id,
          true,
          reschedule,
          () => {
            dispatch(
              searchPatients(
                searchValue,
                currPage,
                rowsPerPage,
                selectedDoctorPatients,
                true,
                () => {},
                selectedPatientCampaigns,
                selectedPatientStatuses,
              ),
            );
            dispatch(getPatientEnrollments(selectedPatientInfo.patient_id));
            setEdit(false);
          },
          false,
        ),
      );
    }

    // enroll patient
    dispatch(
      enrollPatientWithAi(
        patient.patient_id,
        enrollment.campaign_id,
        data.chronicConditions,
        data.goals,
        data.pertinentMedicalHistory,
        patientProvider
          ? patientProvider.doctor_id
          : enrollment.patient.doctor_id,
        aiChat,
        true,
        () => {
          dispatch(
            searchPatients(
              searchValue,
              currPage,
              rowsPerPage,
              selectedDoctorPatients,
              true,
              () => {},
              selectedPatientCampaigns,
              selectedPatientStatuses,
            ),
          );
          dispatch(getPatientEnrollments(selectedPatientInfo.patient_id));
          setEdit(false);
        },
        true,
      ),
    );
  };

  const onCancel = (e) => {
    e.preventDefault();
    reset();
    setEdit(false);
  };

  return (
    <div
      className={`h-full flex flex-col justify-between relative
        ${edit ? "" : "bg-gray-110 border border-gray-foreground p-4 rounded-lg"}`}
    >
      {!edit && (
        <button
          type="button"
          className="absolute top-5 right-5 z-10 w-8 h-8 flex items-center justify-center rounded-lg bg-white
            border border-gray-foreground"
          onClick={() => setEdit(true)}
        >
          <EditIcon width="16" height="16" stroke="#2970FF" />
        </button>
      )}

      <form
        id="upcoming-form"
        className="space-y-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        {enrollment?.campaign?.campaign_type.includes("Outreach") && (
          <>
            <div className={edit ? inputBlockClasses : ""}>
              <p className={`${inputLabelClasses} ${labelWidth}`}>Conditions</p>
              {edit ? (
                <TextareaOrderedLines
                  name="chronicConditions"
                  register={register}
                  setValue={setValue}
                  required={
                    enrollment.campaign.campaign_type === "Outreach"
                      ? "Please provide at least two conditions"
                      : "Please provide at least one condition"
                  }
                  error={errors.chronicConditions}
                  placeholder={
                    enrollment.campaign.campaign === "Post-Discharge Care"
                      ? chronicPostDischargePlaceholder
                      : chronicCcmPlaceholder
                  }
                  initLength={getValues("chronicConditions").length}
                  maxLength={500}
                />
              ) : (
                <div className={contentClasses}>
                  {enrollment.outreach_focus || enrollment.patient?.conditions}
                </div>
              )}
            </div>

            <div className={edit ? inputBlockClasses : ""}>
              <p className={`${inputLabelClasses} ${labelWidth}`}>Goals</p>
              {edit ? (
                <TextareaAutosizeForm
                  name="goals"
                  register={register}
                  placeholder={
                    enrollment.campaign.campaign === "Post-Discharge Care"
                      ? goalsPostDischargePlaceholder
                      : goalsCcmPlaceholder
                  }
                  initLength={getValues("goals").length}
                  maxLength={500}
                />
              ) : (
                <div className={contentClasses}>
                  {enrollment.outreach_goals || enrollment.patient?.goals}
                </div>
              )}
            </div>

            <div className={edit ? inputBlockClasses : ""}>
              <p className={`${inputLabelClasses} ${labelWidth}`}>
                Pertinent Past Medical History
              </p>
              {edit ? (
                <TextareaAutosizeForm
                  name="pertinentMedicalHistory"
                  register={register}
                  placeholder={
                    enrollment.campaign.campaign === "Post-Discharge Care"
                      ? medicalPostDischargePlaceholder
                      : medicalCcmPlaceholder
                  }
                  initLength={getValues("pertinentMedicalHistory").length}
                  maxLength={500}
                />
              ) : (
                <div className={contentClasses}>
                  {enrollment.ehr_information ||
                    enrollment.patient?.past_medical_history}
                </div>
              )}
            </div>
          </>
        )}

        {edit && (
          <>
            <div className={edit ? inputBlockClasses : ""}>
              <p className={`${inputLabelClasses} ${labelWidth}`}>Provider</p>
              {patientProvider ? (
                <div className="grid grid-cols-conv-layout items-center justify-between gap-2.5 w-full min-w-0 h-[42px]">
                  <div className="flex items-center space-x-2.5 min-w-0">
                    <ProfilePicture
                      firstName={patientProvider.first_name}
                      lastName={patientProvider.last_name}
                      src={patientProvider.profile_picture}
                      flagPadding={false}
                    />
                    <div className="truncate">
                      <p className="text-sm font-semibold truncate">
                        {patientProvider.display_name}
                      </p>
                      <p className="text-xs font-medium text-tertiary truncate">
                        {patientProvider.occupation}
                      </p>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="w-8 h-8 bg-white rounded-lg flex items-center justify-center"
                    onClick={() => setPatientProvider(null)}
                  >
                    <RemoveIcon stroke="#667085" width="16" height="16" />
                  </button>
                </div>
              ) : (
                <AutocompleteVirtualized
                  options={doctorOptions}
                  onSelect={(provider) => {
                    setPatientProvider(provider);
                  }}
                  className="w-full bg-white rounded-lg"
                  placeholder="Select Provider (Clinical Lead)"
                  isDoctors
                />
              )}
            </div>

            <div className={edit ? inputBlockClasses : ""}>
              <p className={`${inputLabelClasses} ${labelWidth}`}>AI Chat</p>
              <Switch
                size="medium"
                enabled={aiChat}
                onChange={() => setAiChat(!aiChat)}
              />
            </div>
          </>
        )}

        {(aiChat || !edit) && (
          <>
            {((enrollment?.interval && !edit) || (edit && aiChat)) && (
              <div className={edit ? inputBlockClasses : ""}>
                <p className={`${inputLabelClasses} ${labelWidth}`}>
                  Frequency
                </p>
                {edit ? (
                  <Select
                    register={register}
                    name="frequency"
                    placeholder="Select frequency"
                    options={
                      enrollment.campaign.campaign_type.includes("Outreach")
                        ? frequencyCCM
                        : frequency
                    }
                  />
                ) : (
                  <div className={`${contentClasses} capitalize`}>
                    {enrollment?.interval
                      ? getFrequencyByInterval(enrollment.interval)
                      : ""}
                  </div>
                )}
              </div>
            )}

            {((enrollment.next_checkin && !edit) || (edit && aiChat)) && (
              <div className={edit ? inputBlockClasses : ""}>
                <p className={`${inputLabelClasses} ${labelWidth}`}>
                  Start Time
                </p>
                {edit ? (
                  <DateTimeShortcutsInput
                    name="start"
                    withTime
                    withShortcuts
                    validateFunc={(value) => {
                      const currentDate = dayjs();
                      const selectedDate = dayjs(value);
                      if (selectedDate.isBefore(currentDate, "minute")) {
                        return "Please choose a date in the future";
                      }
                      return true;
                    }}
                    control={control}
                    error={errors.start}
                    minDateTime={dayjs()}
                    onSelectDate={(date) => {
                      const dateString = date.toDate().toLocaleString("en-us");
                      dispatch(setSelectedStartDateAction(dateString));
                    }}
                  />
                ) : (
                  enrollment.next_checkin && (
                    <div className={contentClasses}>
                      {new Date(
                        `${enrollment.next_checkin.replace(/-/g, "/")} GMT+0`,
                      ).toLocaleDateString("en-us", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </div>
                  )
                )}
              </div>
            )}
          </>
        )}
      </form>

      {edit && (
        <div className="flex justify-end space-x-4 font-semibold pb-4 mt-4">
          <Button
            variant="gray"
            size="unset"
            className="h-10 px-4 text-sm"
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button
            form="upcoming-form"
            type="submit"
            size="unset"
            className="whitespace-nowrap h-10 px-4 text-sm"
          >
            Save Changes
          </Button>
        </div>
      )}
    </div>
  );
};

export default EnrollmentEditFields;
