import { ReactComponent as SearchIcon } from "@assets/icons/search.svg";
import Popover from "@mui/material/Popover";
import { useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import { selectInputText } from "../../../helpers/helpers";
import { useAssignPatientToProviderOrTeamMutation } from "../../../store/call/api";
import type { Conversation } from "../../../store/call/interfaces";
import { useGetTeamsQuery, useGetUsersQuery } from "../../../store/user/api";
import type { Team, User } from "../../../store/user/userReducer";
import ProfilePicture from "../../Basic/ProfilePicture";
import { getTeamIcon } from "../../Settings/Clinic/Teams/IconSelection";

const getListItemStyles = (isSelected: boolean) =>
  `flex items-center space-x-2 text-sm font-medium py-1.5 px-2 rounded-lg cursor-pointer ${
    isSelected ? "bg-gray-200 hover:bg-gray-300" : "hover:bg-gray-110"
  }`;

interface AssignButtonProps {
  conversation: Conversation;
}

const AssignButton = ({ conversation }: AssignButtonProps) => {
  const [openList, setOpenList] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchFilter] = useDebounce(searchValue, 200);

  const { data: users = [] } = useGetUsersQuery({
    search: searchFilter,
  });
  const { data: teams = [] } = useGetTeamsQuery({
    search: searchFilter,
  });

  const [assignPatientToProviderOrTeam] =
    useAssignPatientToProviderOrTeamMutation();

  const handleSearchInput = (e) => {
    setSearchValue(e.target.value);
  };

  const handleOpenList = (e: React.MouseEvent<HTMLElement>) => {
    setOpenList(true);
    setAnchorEl(e.currentTarget);
  };

  const handleCloseList = () => {
    setOpenList(false);
    setAnchorEl(null);
  };

  const handleAssign = (resource: User | Team) => {
    handleCloseList();

    const assignTo = {
      provider: null,
      team: null,
    };

    if (
      resource &&
      "doctor_team_id" in resource &&
      conversation.assigned_to_team?.doctor_team_id !== resource.doctor_team_id
    ) {
      assignTo.team = resource;
    } else if (
      resource &&
      "doctor_id" in resource &&
      conversation.assigned_to?.doctor_id !== resource.doctor_id
    ) {
      assignTo.provider = resource;
    }

    assignPatientToProviderOrTeam({
      conversation,
      assignTo,
    });
  };

  const isResourceSelected = (resource: User | Team) => {
    if ("doctor_team_id" in resource) {
      return (
        resource.doctor_team_id ===
        conversation.assigned_to_team?.doctor_team_id
      );
    }
    return resource.doctor_id === conversation.assigned_to?.doctor_id;
  };

  const sortedUsers = users
    .map((user) => ({
      ...user,
      display:
        user.display_name?.trim() ||
        `${user.first_name?.trim() || ""} ${user.last_name?.trim() || "-"}`,
    }))
    .sort((a, b) => a.display.localeCompare(b.display));

  const sortedTeams = teams
    .map((team) => ({
      ...team,
      display: team.name?.trim() || "-",
    }))
    .sort((a, b) => a.display.localeCompare(b.display));

  return (
    <div className="h-full flex items-center">
      <button
        type="button"
        className="flex items-center h-full px-3 py-1.5"
        onClick={handleOpenList}
      >
        {conversation.assigned_to || conversation.assigned_to_team ? (
          <div className="h-8 pl-1 pr-2 rounded-full border border-gray-foreground flex items-center space-x-1">
            {conversation.assigned_to ? (
              <ProfilePicture
                size={6}
                fontSize="xxs"
                firstName={conversation.assigned_to.first_name}
                lastName={conversation.assigned_to.last_name}
                flagPadding={false}
                src={conversation.assigned_to.profile_picture}
              />
            ) : (
              getTeamIcon(
                conversation.assigned_to_team.team_icon,
                "20",
                "#121212",
              )
            )}
            <div className="text-xxs font-medium text-left truncate max-w-20 lg:max-w-34">
              <p className="text-tertiary">Assigned to</p>
              <p className="truncate">
                {conversation.assigned_to
                  ? conversation.assigned_to.display_name ||
                    `${conversation.assigned_to.first_name} ${conversation.assigned_to.last_name}`
                  : conversation.assigned_to_team.name}
              </p>
            </div>
          </div>
        ) : (
          <p className="text-primary-blue text-sm font-semibold">Assign</p>
        )}
      </button>

      {openList && anchorEl && (
        <Popover
          open={openList}
          onClose={handleCloseList}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          TransitionProps={{
            onEntered: () => {
              searchInputRef.current?.focus();
            },
          }}
          slotProps={{
            paper: {
              sx: {
                borderRadius: "8px",
                width: "230px",
                boxShadow: "0px 2px 6px 0px rgba(18, 18, 18, 0.08)",
                margin: "0 0 16px 0",
              },
            },
          }}
        >
          <div className="w-full max-h-[70vh] height-sm:max-h-108 overflow-hidden grid grid-rows-note-layout py-2">
            <div className="overflow-y-auto scrollbar pl-2 pr-1 pb-2 space-y-1">
              {sortedTeams?.map((team) => (
                <div
                  key={team.doctor_team_id}
                  className={getListItemStyles(isResourceSelected(team))}
                  onClick={() => handleAssign(team)}
                >
                  {getTeamIcon(team.team_icon, "20", "#121212")}
                  <p>{team.display}</p>
                </div>
              ))}

              {sortedUsers.length > 0 && sortedTeams.length > 0 && <hr />}

              {sortedUsers?.map((user) => (
                <div
                  key={user.doctor_id}
                  className={getListItemStyles(isResourceSelected(user))}
                  onClick={() => handleAssign(user)}
                >
                  <ProfilePicture
                    size={6}
                    fontSize="xxs"
                    firstName={user.first_name}
                    lastName={user.last_name}
                    flagPadding={false}
                    src={user.profile_picture}
                  />
                  <p>{user.display}</p>
                </div>
              ))}
            </div>
            <div className="relative w-full px-2">
              <input
                type="text"
                ref={searchInputRef}
                value={searchValue}
                onClick={selectInputText}
                onChange={handleSearchInput}
                className="rounded-lg h-9 text-sm outline-none w-full pl-2 pr-7 placeholder:text-gray-400 border"
                placeholder="Search ..."
              />
              <SearchIcon
                className="absolute top-2 right-3.5"
                width="20"
                height="20"
              />
            </div>
          </div>
        </Popover>
      )}
    </div>
  );
};

export default AssignButton;
