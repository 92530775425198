import {
  type PayloadAction,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import type { RootState } from "..";
import { LOGOUT } from "../types";
import type { InboxPatient } from "./interfaces";

// Define our state type
interface NormalizedCallState {
  callsCompletedTmp: {
    pending: number[];
    completed: number[];
  };
  tableScrollPosition: number;
  selectedItemPatient: InboxPatient | null;
  isMessageInputOpen: boolean;
  isFilterByMentions: boolean;
  isFilterByAssignedToCurrentUser: boolean;
  filterByTeamId: number | null;
}

// Create initial state
const initialState: NormalizedCallState = {
  callsCompletedTmp: {
    pending: [],
    completed: [],
  },
  tableScrollPosition: 0,
  selectedItemPatient: null,
  isMessageInputOpen: true,
  isFilterByMentions: false,
  isFilterByAssignedToCurrentUser: false,
  filterByTeamId: null,
};

export const callSlice = createSlice({
  name: "call",
  initialState,
  reducers: {
    addToPendingTmp: (state, action: PayloadAction<number>) => {
      state.callsCompletedTmp.pending.push(action.payload);
    },
    addToCompletedTmp: (state, action: PayloadAction<number>) => {
      state.callsCompletedTmp.completed.push(action.payload);
    },
    removeFromPendingTmp: (state, action: PayloadAction<number>) => {
      state.callsCompletedTmp.pending = state.callsCompletedTmp.pending.filter(
        (id) => id !== action.payload,
      );
    },
    removeFromCompletedTmp: (state, action: PayloadAction<number>) => {
      state.callsCompletedTmp.completed =
        state.callsCompletedTmp.completed.filter((id) => id !== action.payload);
    },

    setTableScrollPosition(state, action: PayloadAction<number>) {
      state.tableScrollPosition = action.payload;
    },

    setSelectedItemPatient(state, action: PayloadAction<InboxPatient | null>) {
      state.selectedItemPatient = action.payload;
    },

    setIsMessageInputOpen(state, action: PayloadAction<boolean>) {
      state.isMessageInputOpen = action.payload;
    },

    setIsFilterByMentions(state, action: PayloadAction<boolean>) {
      state.isFilterByMentions = action.payload;
    },

    setIsFilterByAssignedToCurrentUser(state, action: PayloadAction<boolean>) {
      state.isFilterByAssignedToCurrentUser = action.payload;
    },

    setFilterByTeamId(state, action: PayloadAction<number | null>) {
      state.filterByTeamId = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(LOGOUT, () => ({
      ...initialState,
    }));
  },
});

export const {
  setTableScrollPosition,
  setSelectedItemPatient,
  setIsMessageInputOpen,
  setIsFilterByMentions,
  setIsFilterByAssignedToCurrentUser,
  setFilterByTeamId,
  addToPendingTmp,
  addToCompletedTmp,
  removeFromPendingTmp,
  removeFromCompletedTmp,
} = callSlice.actions;

export const getSelectedItemPatient = createSelector(
  (state: RootState) => state.call,
  (call) => call.selectedItemPatient,
);

export const getIsMessageInputOpen = createSelector(
  (state: RootState) => state.call,
  (call) => call.isMessageInputOpen,
);

export const getCallsCompletedTmp = createSelector(
  (state: RootState) => state.call,
  (call) => call.callsCompletedTmp,
);

export const getIsFilterByMentions = createSelector(
  (state: RootState) => state.call,
  (call) => call.isFilterByMentions,
);

export const getIsFilterByAssignedToCurrentUser = createSelector(
  (state: RootState) => state.call,
  (call) => call.isFilterByAssignedToCurrentUser,
);

export const getFilterByTeamId = createSelector(
  (state: RootState) => state.call,
  (call) => call.filterByTeamId,
);

export const getTableScrollPosition = createSelector(
  (state: RootState) => state.call,
  (call) => call.tableScrollPosition,
);
