import { ReactComponent as DotsIcon } from "@assets/icons/dots-grid.svg";
import { ReactComponent as CrossIcon } from "@assets/icons/x-mark.svg";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";

interface SubsectionProps {
  subsection: string;
  subsectionId: number;
  onChangeName: (name: string) => void;
  onRemove: () => void;
  index: number;
}

const Subsection = ({
  subsection,
  subsectionId,
  onChangeName,
  onRemove,
  index,
}: SubsectionProps) => {
  const [editName, setEditName] = useState(!subsection);
  const [nameValue, setNameValue] = useState(subsection || "");

  return (
    <Draggable draggableId={subsectionId.toString()} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`w-full truncate flex items-center space-x-2 justify-between bg-white p-2.5 rounded-lg border
             group cursor-pointer
            ${editName ? "border-primary-blue" : "border-gray-300 hover:border-primary-blue"}`}
        >
          <div className="w-full flex items-center space-x-2 truncate">
            <div {...provided.dragHandleProps}>
              <DotsIcon
                width="20"
                height="20"
                className="flex-none opacity-0 transition-all group-hover:opacity-100"
              />
            </div>
            {editName || !nameValue ? (
              <input
                value={nameValue}
                onChange={(e) => setNameValue(e.target.value)}
                placeholder="Enter subsection name"
                className="border-none outline-none w-full text-sm"
                autoFocus
                onFocus={() => setEditName(true)}
                onBlur={() => {
                  setEditName(false);
                  if (subsection !== nameValue.trim()) {
                    onChangeName(nameValue.trim());
                  }
                }}
              />
            ) : (
              <button
                type="button"
                className="w-full min-h-fit h-5 text-sm font-medium text-left truncate"
                onClick={() => setEditName(true)}
              >
                {nameValue}
              </button>
            )}
          </div>

          <button
            type="button"
            className="opacity-0 transition-all group-hover:opacity-100"
            onClick={onRemove}
          >
            <CrossIcon
              width="20"
              height="20"
              stroke="#A0A6B2"
              className="flex-none"
            />
          </button>
        </div>
      )}
    </Draggable>
  );
};

export default Subsection;
