import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useMemo } from "react";
import { rowsPerPage } from "../../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  callApi,
  selectNextConversation,
  useGetCallCountsQuery,
  useGetCallsQuery,
  useGetConversationQuery,
} from "../../../store/call/api";
import {
  getCallsCompletedTmp,
  getFilterByTeamId,
  getIsFilterByAssignedToCurrentUser,
  getIsFilterByMentions,
  getSelectedItemPatient,
  removeFromCompletedTmp,
  removeFromPendingTmp,
  setSelectedItemPatient,
} from "../../../store/call/callSlice";
import {
  type CallSearchParams,
  CallStatusTab,
} from "../../../store/call/interfaces";
import { setHideMobileNavbar } from "../../../store/ui/actions";
import useConversationSearchParams from "./useConversationSearchParams";
import { useFilterParams } from "./useFilterParams";
import { usePrefetchConversations } from "./usePrefetchConversations";

export const useCallManagement = () => {
  const { selectedConversationId, setSelectedConversationId } =
    useConversationSearchParams();
  const { filters, updateFilters } = useFilterParams();
  const callsCompletedTmp = useAppSelector(getCallsCompletedTmp);
  const isFilterByMentions = useAppSelector(getIsFilterByMentions);
  const isFilterByAssignedToCurrentUser = useAppSelector(
    getIsFilterByAssignedToCurrentUser,
  );
  const filterByTeamId = useAppSelector(getFilterByTeamId);
  const selectedItemPatient = useAppSelector(getSelectedItemPatient);
  const dispatch = useAppDispatch();

  const callSearchParams: CallSearchParams = useMemo(() => {
    return {
      limit: rowsPerPage,
      offset: rowsPerPage * Math.max(0, filters.page - 1),
      q: filters.search,
      direction: filters.direction === "all" ? "" : filters.direction,
      categories: filters.reasons,
      is_new_user: filters.isNewUser,
      is_urgent: filters.isUrgent,
      has_mention: isFilterByMentions ? 1 : 0,
      assigned_to_me: isFilterByAssignedToCurrentUser ? 1 : 0,
      team_id: filterByTeamId,
    };
  }, [
    filters,
    isFilterByMentions,
    isFilterByAssignedToCurrentUser,
    filterByTeamId,
  ]);

  const { isLoading, isFetching, data } = useGetCallsQuery({
    status: filters.status,
    params: callSearchParams,
  });

  const { data: reviewCalls } = useGetCallsQuery({
    status: CallStatusTab.Pending,
    params: callSearchParams,
  });

  const nextConversation = useAppSelector((state) =>
    selectNextConversation(state, selectedConversationId, {
      status: filters.status,
      params: callSearchParams,
    }),
  );

  const { data: callCounts } = useGetCallCountsQuery();

  const { data: call } = useGetConversationQuery(
    selectedItemPatient?.patient_phone_number && selectedConversationId
      ? Number(selectedConversationId)
      : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const handleCloseCallDetails = () => {
    setSelectedConversationId(null);
    dispatch(setHideMobileNavbar(false));
    dispatch(setSelectedItemPatient(null));
  };

  useEffect(() => {
    if (!data?.inbox || !reviewCalls?.inbox) {
      return;
    }

    // Clean up pending list when items appear in completed
    if (filters.status === CallStatusTab.Completed) {
      const appearedInCompleted = callsCompletedTmp.pending.filter((id) =>
        data.inbox.some((call) => call.patient.patient_id === id),
      );

      appearedInCompleted.forEach((id) => {
        dispatch(removeFromPendingTmp(id));
      });
    }

    // Clean up completed list when items appear in pending
    if (filters.status === CallStatusTab.Pending) {
      const appearedInPending = callsCompletedTmp.completed.filter((id) =>
        data.inbox.some((call) => call.patient.patient_id === id),
      );

      appearedInPending.forEach((id) => {
        dispatch(removeFromCompletedTmp(id));
      });
    }
  }, [
    data?.inbox,
    reviewCalls?.inbox,
    filters.status,
    callsCompletedTmp,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(
      callApi.endpoints.getCalls.initiate(
        {
          status: filters.status,
          params: callSearchParams,
        },
        { forceRefetch: true },
      ),
    );
  }, [filters.status, callSearchParams, dispatch]);

  usePrefetchConversations(data?.inbox, Number(selectedConversationId));

  return {
    call,
    reviewCalls,
    data,
    filters,
    isLoading,
    isFetching,
    nextConversation,
    callCounts,
    selectedConversationId,
    setSelectedConversationId,
    handleCloseCallDetails,
    updateFilters,
  };
};
