import { ReactComponent as EyeIcon } from "@assets/icons/eye-black.svg";
import { ReactComponent as EyeClosedIcon } from "@assets/icons/eye-slash.svg";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router";
import { Pages } from "../../helpers/constants";
import { setErrorAction } from "../../store/user/actions";
import { setToken } from "../../store/user/thunks";
import Button from "../Basic/Button";
import Input from "../Basic/Input";

const LoginForm = () => {
  const { errorMessage } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (errorMessage) {
      setError("email", { message: "" });
      setError("password", { message: errorMessage });
    }
  }, [errorMessage, setError]);

  const onSubmit = (data) => {
    dispatch(setErrorAction(""));
    dispatch(setToken(data.email, data.password));
  };

  return (
    <div className="w-full max-w-[22.5rem] mx-auto">
      <p className="text-2xl md:text-[32px] font-semibold mb-2">Welcome back</p>
      <p className="text-xs md:text-base font-medium text-neutral-500 mb-8">
        Please enter your details.
      </p>

      <form className="relative" onSubmit={handleSubmit(onSubmit)}>
        <Input
          register={register}
          name="email"
          label="Email"
          placeholder="Enter your email"
          type="email"
          className="mb-5"
          required="Email can’t be blank"
          withoutAsterisk
          error={errors.email}
          validationPattern={{
            value: /\S+@\S+\.\S+/,
            message: "Enter a valid email address",
          }}
        />
        <div className="relative">
          <Input
            register={register}
            name="password"
            label="Password"
            placeholder="Enter your password"
            type={showPassword ? "text" : "password"}
            className="mb-4"
            inputClassName="pr-12"
            required="Password can’t be blank"
            withoutAsterisk
            error={errors.password}
          />
          {!errors.password && (
            <button
              type="button"
              className="absolute right-[14px] top-[calc(100%-20px)] transform -translate-y-1/2"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <EyeClosedIcon className="h-5 w-5 text-gray-500" />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-500" />
              )}
            </button>
          )}
        </div>

        <Link
          to={Pages.FORGOT_PASS}
          className="text-primary-blue font-medium text-sm"
        >
          Forgot password
        </Link>

        <Button
          className="w-full font-semibold mt-6"
          type="submit"
          data-testid="login-button"
        >
          Sign in
        </Button>
      </form>
    </div>
  );
};

export default LoginForm;
