import { ReactComponent as CheckIcon } from "@assets/icons/check-circle-completed.svg";
import Skeleton from "@mui/material/Skeleton";

interface CallListItemsSkeletonProps {
  rows?: number;
  bgColor?: string;
  animation?: "wave" | "pulse";
}

const CallListItemsSkeleton = ({
  rows = 15,
  bgColor = "#EAEDF3",
  animation = "wave",
}: CallListItemsSkeletonProps) => {
  return (
    <>
      {Array.from({ length: rows }, (_, index) => (
        <div
          key={`call-${index}`}
          className="bg-white p-4 rounded-xl border-b border-gray-foreground space-y-2 mt-3"
        >
          <Skeleton
            animation={animation}
            variant="rounded"
            width={200}
            height={14}
            sx={{ bgcolor: bgColor, borderRadius: "14px" }}
          />

          <Skeleton
            animation={animation}
            variant="rounded"
            width={150}
            height={12}
            sx={{ bgcolor: bgColor, borderRadius: "12px" }}
          />

          <div className="grid grid-cols-middle items-center gap-2">
            <Skeleton
              animation={animation}
              variant="circular"
              width={32}
              height={32}
              sx={{ bgcolor: bgColor }}
            />
            <div className="flex-1 space-y-1">
              <Skeleton
                animation={animation}
                variant="rounded"
                width={120}
                height={14}
                sx={{ bgcolor: bgColor, borderRadius: "14px" }}
              />
              <Skeleton
                animation={animation}
                variant="rounded"
                width={100}
                height={12}
                sx={{ bgcolor: bgColor, borderRadius: "12px" }}
              />
            </div>

            <CheckIcon
              width="30"
              height="30"
              fill="#D0D5DD"
              className="flex-none"
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default CallListItemsSkeleton;
