import { useLayoutEffect, useMemo, useRef } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useMediaQuery } from "react-responsive";
import { rowsPerPage } from "../../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useMoveToCompletedMutation } from "../../../store/call/api";
import {
  getTableScrollPosition,
  setSelectedItemPatient,
  setTableScrollPosition,
} from "../../../store/call/callSlice";
import {
  CallStatusTab,
  type InboxListItem,
} from "../../../store/call/interfaces";
import { setHideMobileNavbar } from "../../../store/ui/actions";
import LoaderDots from "../../Basic/LoaderDots";
import CallListItemsSkeleton from "../../Skeletons/CallListItemsSkeleton";
import { useCallManagement } from "../hooks/useCallManagement";
import CallListItem from "./CallListItem";
import CallsGridTable from "./CallsGridTable";
import EmptyInboxTable from "./EmptyInboxTable";

const CallsTableData = () => {
  const tableScrollPosition = useAppSelector(getTableScrollPosition);

  const {
    data: { inbox: calls = [], total } = {},
    isLoading,
    isFetching,
    filters,
    nextConversation,
    updateFilters,
    selectedConversationId,
    setSelectedConversationId,
  } = useCallManagement();

  const [moveToCompleted] = useMoveToCompletedMutation();

  const hasMore = useMemo(() => calls?.length < total, [calls, total]);

  const tableRef = useRef<HTMLDivElement>(null);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const dispatch = useAppDispatch();

  const handleCompleteCall = (call: InboxListItem) => {
    moveToCompleted({
      patientId: call.patient.patient_id,
      conversationId: call.patient.conversation_id,
    });
    setSelectedConversationId(
      nextConversation?.patient.conversation_id.toString(),
    );
    dispatch(setSelectedItemPatient(nextConversation?.patient));
  };

  useLayoutEffect(() => {
    if (tableRef.current) {
      tableRef.current.scroll(0, tableScrollPosition);
    }
    return () => {
      if (tableRef.current) {
        dispatch(setTableScrollPosition(tableRef.current.scrollTop));
      }
    };
  }, [tableScrollPosition, dispatch]);

  const handleClickCall = (call: InboxListItem): void => {
    if (selectedConversationId === call.patient.conversation_id.toString()) {
      setSelectedConversationId(null);
      dispatch(setHideMobileNavbar(false));
      dispatch(setSelectedItemPatient(null));
      return;
    }

    setSelectedConversationId(call.patient.conversation_id.toString());
    dispatch(setSelectedItemPatient(call.patient));
  };

  const onNext = (): void => {
    if (isFetching || rowsPerPage * filters.page >= total) {
      return;
    }

    const nextPage = filters.page + 1;

    updateFilters({ page: nextPage });
  };

  return (
    <div className="overflow-auto h-full w-full scrollbar pr-1" ref={tableRef}>
      <InfiniteScroll
        pageStart={0}
        loadMore={onNext}
        hasMore={hasMore}
        useWindow={false}
        loader={
          <div key={0} className="w-full h-10 flex items-center justify-center">
            <LoaderDots />
          </div>
        }
      >
        <div className="md:min-w-fit w-full">
          {isDesktop ? (
            <CallsGridTable
              handleClickCall={handleClickCall}
              handleCompleteCall={handleCompleteCall}
            />
          ) : (
            <>
              {calls.length === 0 && isLoading ? (
                <CallListItemsSkeleton />
              ) : calls.length > 0 ? (
                calls.map((call: InboxListItem) => (
                  <CallListItem
                    key={call.patient.patient_id}
                    call={call.items[0]}
                    patient={call.patient}
                    numOfSubItems={call.items.length}
                    handleClickCall={() => handleClickCall(call)}
                    handleCompleteCall={() => handleCompleteCall(call)}
                  />
                ))
              ) : (
                <EmptyInboxTable
                  completed={filters.status === CallStatusTab.Completed}
                />
              )}
            </>
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default CallsTableData;
