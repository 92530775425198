import {
  type PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import type z from "zod";
import type { scribeSchema } from "../../../helpers/commonValidationSchemas";
import type { Pages } from "../../../helpers/constants";
import { parseDateString } from "../../../helpers/helpers";
import LocalStorageService from "../../../services/LocalStorageService";
import type { RootState } from "../../../store";
import type { Patient } from "../../../store/patient/interfaces";
import { initialTitle } from "../utils";
import type { Scribe } from "./interfaces";
import { LOGOUT, type SetInterruptedRecordingActionType } from "./types";
import { getRecordingScribeTemplate } from "./utils";
import type { providerNotesSchema } from "./validationSchemas";

const storageService = new LocalStorageService();

export type FixLater = any;

export type ScribeNote = z.infer<typeof scribeSchema>;

export const mapServerNoteToState = (note: ScribeNote): Scribe => {
  let accumulatedDuration = Math.max(
    0,
    note.duration * 1000 ||
      new Date(note.finished_at).getTime() -
        new Date(note.started_at).getTime(),
  );

  if (note.type === "campaign") {
    accumulatedDuration = 0;
  }

  return {
    ...getRecordingScribeTemplate({
      overrides: {
        audioId: `${note.audio_id}`,
        noteId: note.note_id,
        note: note.note || "",
        title: note.title || initialTitle(parseDateString(note.started_at)),
        patient: note.patient as Patient, // TODO: fix this
        createdAt: note.started_at,
        startedAt: note.started_at,
        finishedAt: note.finished_at,
        accumulatedDuration,
        freeText: note.free_text,
        isPaused: note.audio_status === "paused",
        isGenerating: note.audio_status === "generating",
        isGenerationDelayed:
          note.audio_status === "failed_too_long" ||
          (note.finished_at &&
            new Date(parseDateString(note.finished_at)).getTime() <
              new Date(Date.now() - 1000 * 60 * 3).getTime()),
        isVisible: true,
        isPersisted: true,
        type: note.type || "scribe",
        providerId: note.provider_id,
        providerName: note.provider_name,
      },
    }),
  };
};

const mapServerNotesToState = (scribes: ScribeNote[]): Scribe[] => {
  return scribes.map((note) => mapServerNoteToState(note));
};

export const scribesAdapter = createEntityAdapter({
  selectId: (scribe: Scribe) => scribe.audioId,
});

const initialState = scribesAdapter.setAll(
  scribesAdapter.getInitialState({
    interruptedRecordingAction: null,
    isLoading: false,
    pagination: {
      nextCursor: -1,
      isFetching: false,
    },
  }),
  storageService.getItem<Scribe[]>("scribes") ?? [],
);

const scribeSelectors = scribesAdapter.getSelectors();

export const globalScribeSelectors = scribesAdapter.getSelectors(
  (state: RootState) => state.scribe,
);

export const scribeSlice = createSlice({
  name: "scribe",
  initialState,
  reducers: {
    setScribeRecording(
      state,
      action: PayloadAction<{ audioId: string; patient: Patient | null }>,
    ) {
      const newScribe: Scribe = {
        ...getRecordingScribeTemplate({
          overrides: {
            audioId: action.payload.audioId,
            isVisible: true,
            isRecording: true,
            createdAt: new Date().toISOString(),
            startedAt: new Date().toISOString(),
            patient: action.payload.patient,
          },
        }),
      };

      scribesAdapter.addOne(state, newScribe);
    },

    setScribeRecordingGenerating(
      state,
      action: PayloadAction<{ audioId: string }>,
    ) {
      const selectedAudioId = action.payload.audioId;
      if (!selectedAudioId) {
        return;
      }

      const scribe = scribeSelectors.selectById(state, selectedAudioId);
      if (scribe) {
        const now = new Date();
        const accumulatedDuration = scribe.isRecording
          ? Math.max(
              0,
              now.getTime() -
                new Date(scribe.startedAt).getTime() +
                scribe.accumulatedDuration,
            )
          : scribe.accumulatedDuration;

        scribesAdapter.updateOne(state, {
          id: selectedAudioId,
          changes: {
            finishedAt: now.toISOString(),
            isRecording: false,
            isGenerating: true,
            isPaused: false,
            accumulatedDuration,
          },
        });
      }
    },

    setScribePaused(state) {
      const updates = Object.values(state.entities)
        .filter(
          (scribe) => scribe && (scribe.isRecording || scribe.isGenerating),
        )
        .map((scribe) => ({
          id: scribe.audioId,
          changes: {
            isRecording: false,
            isPaused: true,
            isGenerating: false,
            accumulatedDuration: scribe.isRecording
              ? Math.max(
                  0,
                  new Date().getTime() -
                    new Date(scribe.startedAt).getTime() +
                    scribe.accumulatedDuration,
                )
              : scribe.accumulatedDuration,
          },
        }));

      if (updates.length > 0) {
        scribesAdapter.updateMany(state, updates);
      }
    },

    setScribeTogglePause(state, action: PayloadAction<string>) {
      const selectedAudioId = action.payload;
      if (!selectedAudioId) {
        return;
      }

      const scribe = scribeSelectors.selectById(state, selectedAudioId);
      if (!scribe) {
        return;
      }

      const accumulatedDuration = scribe.isRecording
        ? Math.max(
            0,
            new Date().getTime() -
              new Date(scribe.startedAt).getTime() +
              scribe.accumulatedDuration,
          )
        : scribe.accumulatedDuration;

      const isRecording = !scribe.isRecording;
      scribesAdapter.updateOne(state, {
        id: selectedAudioId,
        changes: {
          isRecording,
          isPaused: !isRecording,
          isGenerating: false,
          startedAt: isRecording ? new Date().toISOString() : scribe.startedAt,
          accumulatedDuration,
        },
      });
    },

    setScribeResumeGenerated(state, action: PayloadAction<string>) {
      const selectedAudioId = action.payload;
      if (!selectedAudioId) {
        return;
      }

      scribesAdapter.updateOne(state, {
        id: selectedAudioId,
        changes: {
          isPaused: false,
          isRecording: true,
          startedAt: new Date().toISOString(),
          finishedAt: null,
        },
      });
    },

    setScribeDeleted(state, action: PayloadAction<{ audioId: string }>) {
      scribesAdapter.removeOne(state, action.payload.audioId);
    },

    setScribes(
      state,
      action: PayloadAction<{
        scribes: z.infer<typeof providerNotesSchema>;
        nextCursor: number;
        isPolling: boolean;
      }>,
    ) {
      const scribesFromServer = mapServerNotesToState(action.payload.scribes);
      const isPolling = action.payload.isPolling;

      scribesFromServer.forEach((serverScribe) => {
        const existingScribe = scribeSelectors.selectById(
          state,
          serverScribe.audioId,
        );

        if (!existingScribe) {
          scribesAdapter.addOne(state, serverScribe);
          return;
        }

        if (existingScribe.isEditing || existingScribe.isRecording) {
          return;
        }

        serverScribe.note = existingScribe.note;

        if (serverScribe.noteId > existingScribe.noteId) {
          serverScribe.note = "";
        }

        scribesAdapter.updateOne(state, {
          id: serverScribe.audioId,
          changes: serverScribe,
        });
      });

      if (!isPolling) {
        state.pagination.nextCursor = action.payload.nextCursor;
      }
    },

    setScribe(
      state,
      action: PayloadAction<{ audioId: string; scribe: Partial<Scribe> }>,
    ) {
      const { audioId, scribe } = action.payload;
      const currentScribeData = scribeSelectors.selectById(state, audioId);
      if (currentScribeData) {
        const updates: Partial<Scribe> = { ...scribe };

        scribesAdapter.updateOne(state, {
          id: audioId,
          changes: updates,
        });
      }
    },

    setScribeNoteContent(
      state,
      action: PayloadAction<{ audioId: string; noteContent: string }>,
    ) {
      const scribe = scribeSelectors.selectById(state, action.payload.audioId);
      if (scribe) {
        scribesAdapter.updateOne(state, {
          id: action.payload.audioId,
          changes: {
            note: action.payload.noteContent,
            isEditing: false,
          },
        });
      }
    },

    setInterruptedRecordingAction(
      state,
      action: PayloadAction<{
        type: SetInterruptedRecordingActionType;
        value?: Pages | string;
      }>,
    ) {
      state.interruptedRecordingAction = action.payload;
    },

    setScribeLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },

    setScribesPaginationFetching(state, action: PayloadAction<boolean>) {
      state.pagination.isFetching = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(LOGOUT, () => {
      return scribesAdapter.getInitialState({
        selectedAudioId: null,
        interruptedRecordingAction: null,
        isLoading: false,
        pagination: {
          nextCursor: -1,
          isFetching: false,
        },
      });
    });
  },
});

export const {
  setScribeRecording,
  setScribeRecordingGenerating,
  setScribePaused,
  setScribeTogglePause,
  setScribeResumeGenerated,
  setScribeDeleted,
  setScribes,
  setScribe,
  setScribeNoteContent,
  setInterruptedRecordingAction,
  setScribesPaginationFetching,
} = scribeSlice.actions;
