import type { Dispatch } from "@reduxjs/toolkit";
import type { Middleware } from "redux";
import { resetAbortController } from "../../../helpers/config";
import LocalStorageService from "../../../services/LocalStorageService";
import { callApi } from "../../../store/call/api";
import { userApi } from "../../../store/user/api";
import type { Scribe } from "./interfaces";
import { globalScribeSelectors } from "./scribeSlice";
import { LOGOUT, type ScribeAction } from "./types";

const storageService = new LocalStorageService();

let lastSavedState: string | null = null;

const mapRecordingToPaused = (
  entities: Record<string, Scribe>,
): Record<string, Scribe> => {
  const updatedEntities: Record<string, Scribe> = { ...entities };

  Object.keys(updatedEntities).forEach((id) => {
    const scribe = updatedEntities[id];
    if (scribe.isRecording) {
      updatedEntities[id] = {
        ...scribe,
        isRecording: false,
        isPaused: true,
      };
    }
  });

  return updatedEntities;
};

const saveToStorage = (entities: Record<string, Scribe>): void => {
  const processedEntities = mapRecordingToPaused(entities);
  const stateString = JSON.stringify(processedEntities);

  // Only save if state has actually changed
  if (stateString !== lastSavedState) {
    storageService.setItem("scribes", processedEntities);
    lastSavedState = stateString;
  }
};

export const storageMiddleware: Middleware<
  unknown,
  any,
  Dispatch<ScribeAction>
> = (store) => (next) => (action: ScribeAction | { type: typeof LOGOUT }) => {
  const { type } = action;
  const result = next(action);

  const state = store.getState();

  switch (type) {
    case LOGOUT:
      resetAbortController();
      storageService.clear();

      setTimeout(() => {
        store.dispatch(callApi.util.resetApiState());
        store.dispatch(userApi.util.resetApiState());
      }, 100);

      break;

    case "scribe/setScribeDeleted":
    case "scribe/setScribeNoteContent":
    case "scribe/setScribe":
    case "scribe/setScribePaused":
    case "scribe/setScribeRecordingGenerating":
    case "scribe/setScribeTogglePause":
    case "scribe/setScribes": {
      const scribes = globalScribeSelectors.selectEntities(state);
      saveToStorage(scribes);
      break;
    }

    default:
      break;
  }

  return result;
};
