import type { ScribeNote } from "../../components/IndependentScribe/store/scribeSlice";
import type { Call, CallStatus } from "../call/interfaces";
import type { User } from "../user/userReducer";

export enum MessageType {
  Text = "text",
  Call = "assistant_call",
  StatusUpdate = "status_update",
  Note = "note",
}

export interface StatusUpdateData {
  assistant_call_id: number;
  created_at: string;
  status_from: CallStatus;
  status_to: CallStatus;
  status_update_id: number;
  updated_at: string;
  updated_by: User;
}

export type MessageInboxItem = {
  call: Call;
  status_update: StatusUpdateData;
  message: string;
};

export type NoteInboxItem = {
  note: ScribeNote;
};
