import { useLocation, useSearchParams } from "react-router";
import { Pages } from "../../../helpers/constants";
import { useAppSelector } from "../../../store";

const getPathBefore = (pathname: string) => {
  const segments = pathname.split("/");
  if (segments.length > 2) {
    return segments.slice(0, -1).join("/");
  }

  return pathname;
};

const CONVERSATION_SEARCH_PARAM = "conversationId";

const useConversationSearchParams = () => {
  const { selectedPatientInfo } = useAppSelector((state) => state.patient);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const selectedConversationId = getPathBefore(location.pathname).includes(
    getPathBefore(Pages.PATIENTS),
  )
    ? selectedPatientInfo?.conversation_id.toString()
    : searchParams.get(CONVERSATION_SEARCH_PARAM);

  const setSelectedConversationId = (conversationId: string) => {
    if (conversationId === selectedConversationId) {
      return;
    }

    const newParams = new URLSearchParams(searchParams);

    if (conversationId === null) {
      newParams.delete(CONVERSATION_SEARCH_PARAM);
    } else {
      newParams.set(CONVERSATION_SEARCH_PARAM, conversationId);
    }

    setSearchParams(newParams);
  };

  return { selectedConversationId, setSelectedConversationId };
};

export default useConversationSearchParams;
