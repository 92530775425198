import axios, { type AxiosError } from "axios";
import {
  type FixLater,
  setScribeDeleted,
  setScribeRecordingGenerating,
} from "../../components/IndependentScribe/store/scribeSlice";
import {
  getRecordingScribe,
  selectScribeById,
} from "../../components/IndependentScribe/store/selectors";
import { handleValidation } from "../../helpers/commonValidationSchemas";
import { BASE_URL } from "../../helpers/config";
import { handleRequestError } from "../../helpers/helpers";
import type { CallScribeType } from "../call/interfaces";
import type { AppDispatch } from "../index";
import { setNotificationAction } from "../user/actions";
import { setTwilioTokenAction } from "./actions";
import {
  twilioTokenSchema,
  voiceConversationSchema,
} from "./validationSchemas";

export const sendPatientAudioPart =
  (
    patientId: number | null,
    record: Blob | null,
    onEnd = (_data: FixLater): void => {},
    onError = (_error: AxiosError): void => {},
    noteTemplateId: number | null = null,
    chunkPosition = 0,
  ) =>
  (_, getState) => {
    const state = getState();
    const scribe = getRecordingScribe(state);

    if (!scribe?.audioId) {
      return;
    }

    const formData = new FormData();
    formData.append("file", record, "audio.webm");
    formData.append("noteTemplateId", noteTemplateId?.toString() ?? null);
    formData.append("chunk_position", chunkPosition.toString());

    if (patientId) {
      formData.append("patient_id", patientId.toString());
    }

    axios
      .post(`${BASE_URL}/voice/${scribe.audioId}/send`, formData, {
        timeout: 0,
      })
      .then((response) => {
        onEnd(response.data);
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          onError(error);
        }

        handleRequestError(error);
      });
  };

export const sendFinishScribe =
  (
    patientId: number | null,
    audioId: string,
    onEnd = (_data: FixLater): void => {},
    onError = (_error: AxiosError): void => {},
  ) =>
  (dispatch, getState) => {
    const state = getState();
    const scribe = selectScribeById(state, audioId);

    if (!scribe?.audioId) {
      return;
    }

    dispatch(setScribeRecordingGenerating({ audioId: scribe.audioId }));

    axios
      .post(
        `${BASE_URL}/voice/${scribe.audioId}/finish`,
        {
          audio_status: "end",
          title: scribe.title,
          freeText: scribe.freeText,
          patient_id: patientId,
        },
        {
          timeout: 300000,
        },
      )
      .then((response) => {
        const data = response.data;
        const voiceConversation = data?.data?.voice_conversation;

        if (voiceConversation) {
          handleValidation(
            voiceConversationSchema,
            voiceConversation,
            "sendFinishScribe",
          );
        }

        onEnd(data);
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (
            (error as AxiosError)?.response?.status === 504 ||
            (error as AxiosError)?.code === "ECONNABORTED" ||
            (error as AxiosError)?.message?.includes("timeout")
          ) {
            console.warn("Request timeout or cancellation:", error?.message);
            return;
          }

          onError(error);
        }

        handleRequestError(error);
      });
  };

export const deleteScribe =
  (audioId: string, onSuccess = () => {}) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/voice/${audioId}/delete`)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to delete scribe",
          );
        } else {
          dispatch(setScribeDeleted({ audioId }));
          onSuccess();
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully deleted scribe",
              desc: "Scribe has been successfully deleted",
            }),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to delete scribe");
      });
  };

export const getTwilioToken = () => (dispatch: AppDispatch) => {
  axios
    .get(`${BASE_URL}/voice/call/get-token`)
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to get call token",
        );
      } else {
        const twilioToken = response.data.data.token;

        handleValidation(twilioTokenSchema, twilioToken, "getTwilioToken");

        dispatch(setTwilioTokenAction(twilioToken));
      }
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(error, dispatch, "Failed to get call token");
    });
};

export const sendTranscription =
  (audioId, transcription, onEnd = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/transcription/${audioId}/text`, {
        audio_transcription: transcription,
        type: "part",
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to send transcription",
          );
        }
        onEnd();
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to send transcription");
      });
  };

export const sendPauseRecording =
  (duration: number) => (_dispatch, getState) => {
    const state = getState();
    const scribe = getRecordingScribe(state);

    const MAX_DURATION_SECONDS = 60 * 60; // 1 hour in seconds

    if (!scribe?.audioId) {
      return;
    }

    const validated_duration = Math.min(
      Math.max(duration, 0),
      MAX_DURATION_SECONDS,
    );

    axios
      .post(`${BASE_URL}/voice/${scribe.audioId}/pause`, {
        duration: validated_duration,
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error);
      });
  };

export const setCallScribeType =
  (callSid: string, scribeType: CallScribeType) => (_dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/voice/call/scribe-type/${callSid}/${scribeType}`)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(response.data.error);
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error);
      });
  };

export const setIsToScribe =
  (callSid: string, isToScribe: boolean) => (_dispatch: AppDispatch) => {
    axios
      .post(
        `${BASE_URL}/voice/call/is-to-scribe/${callSid}/${Number(isToScribe)}`,
      )
      .then((response) => {
        if (response.data.error) {
          handleRequestError(response.data.error);
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error);
      });
  };
