import { ReactComponent as TemplatesIcon } from "@assets/icons/notes-templates.svg";
import { useState } from "react";
import Button from "../../../Basic/Button";
import Modal from "../../../Basic/Modal";
import TextareaAutosizeForm from "../../../Basic/TextareaAutosizeForm";

interface CreateFromNoteModalProps {
  onClose: () => void;
  onGenerate: (data: any) => void;
}

const CreateFromNoteModal = ({
  onClose = () => {},
  onGenerate = (_) => {},
}: CreateFromNoteModalProps) => {
  const [noteValue, setNoteValue] = useState("");

  return (
    <Modal className="pt-5 rounded-2xl">
      <div className="w-168 overflow-hidden">
        <div className="px-5">
          <div className="w-full flex items-center space-x-2 text-left mb-5">
            <TemplatesIcon width="20" height="20" stroke="#667085" />
            <p className="text-sm text-tertiary font-semibold">New template</p>
          </div>
          <p className="text-base font-semibold mb-3">Paste Example Note</p>
          <TextareaAutosizeForm
            name="noteValue"
            placeholder="Paste your note here"
            value={noteValue}
            onChange={(e) => setNoteValue(e.target.value)}
            minRows={8}
            className="mb-5"
            textareaClassName="text-base"
          />
        </div>
        <div className="w-full bg-gray-background py-4 px-5 flex justify-end space-x-4 text-sm font-semibold mt-5">
          <Button variant="gray" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onGenerate}>Generate</Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateFromNoteModal;
