import { useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { saveEncounterPatient } from "../../../store/conversation/thunks";
import type { Patient } from "../../../store/patient/interfaces";
import { selectUser } from "../../../store/user/userReducer";
import TemplatePreviewWithoutContent from "../../Basic/TemplatePreviewWithoutContent";
import AssignPatientModal from "../AssignPatientModal";
import Reports from "../Reports";
import ScribeHeader from "../ScribeHeader";
import ScribeRecordingFooter from "../ScribeRecordingFooter";
import useScribeSearchParams from "../hooks/useScribeSearchParams";
import { selectSelectedNoteTemplate } from "../store/noteTemplateSlice";
import { selectScribeById } from "../store/selectors";

const ScribeRecording = ({
  mediaRecorder,
  patient,
  handleAssignPatientId,
  onBack,
  onCancel,
  saveRecording,
  togglePause,
}: {
  mediaRecorder: MediaRecorder | null;
  patient: Patient | null;
  handleAssignPatientId: (patientId: number) => void;
  onBack: () => void;
  onCancel: () => void;
  saveRecording: () => void;
  togglePause: () => void;
}) => {
  const user = useAppSelector(selectUser);
  const selectedNoteTemplate = useAppSelector(selectSelectedNoteTemplate);
  const { selectedScribeId } = useScribeSearchParams();
  const selectedScribe = useAppSelector((state) =>
    selectScribeById(state, selectedScribeId),
  );

  const dispatch = useAppDispatch();

  const [showAssignPatientModal, setShowAssignPatientModal] = useState(false);

  const onAssignPatient = useCallback(
    (patientId: number) => {
      handleAssignPatientId(patientId);

      if (selectedScribe?.isPaused) {
        dispatch(saveEncounterPatient(selectedScribe?.audioId, patientId));
      }
    },
    [
      dispatch,
      handleAssignPatientId,
      selectedScribe?.audioId,
      selectedScribe?.isPaused,
    ],
  );

  const onCloseAssignPatientModal = useCallback(() => {
    setShowAssignPatientModal(false);
  }, []);

  // TODO: implement support to switch between Transcript and Note
  return (
    <>
      <ScribeHeader
        handleAssignPatientId={onAssignPatient}
        onBack={onBack}
        patient={patient}
        setShowAssignPatientModal={setShowAssignPatientModal}
      />

      <div className="w-full h-full min-h-0 flex flex-col md:justify-between px-4 md:px-0 pt-5 pb-18">
        <main className="h-full pb-6 pr-1 overflow-y-auto scrollbar">
          <div>
            {/* <FreeText
              originalFreeText={selectedScribe?.freeText}
            /> */}

            {/* demo mock reports */}
            {selectedScribe?.patient?.patient_id === 29360 &&
              user?.doctor_id === 15 && <Reports className="mt-6 max-w-2xl" />}

            {selectedNoteTemplate && (
              <TemplatePreviewWithoutContent
                className="hidden md:block mt-6"
                sections={selectedNoteTemplate?.sections}
              />
            )}
          </div>
        </main>
        <ScribeRecordingFooter
          mediaRecorder={mediaRecorder}
          cancelRecording={onCancel}
          saveRecording={saveRecording}
          togglePause={togglePause}
        />
      </div>
      {showAssignPatientModal && (
        <AssignPatientModal
          handleClose={onCloseAssignPatientModal}
          handleAssignPatientId={onAssignPatient}
        />
      )}
    </>
  );
};

export default ScribeRecording;
