import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Suspense, useCallback, useEffect } from "react";

import Bugsnag from "@bugsnag/js";
import Loader from "./components/Basic/Loader";
import { WS_URL } from "./helpers/config";
import {
  commonCampaigns,
  convPerPage,
  filtersClear,
  getFiltersInit,
  inboxes,
} from "./helpers/constants";
import { deepEqual } from "./helpers/helpers";
import AppRoutes from "./routes";
import LocalStorageService from "./services/LocalStorageService";
import { useAppDispatch, useAppSelector } from "./store";

import { updateFeatureFlag } from "./store/admin/adminSlice";
import { baseApi } from "./store/baseApi";
import { setSelectedEnrollCampaignAction } from "./store/campaign/actions";
import { getCampaigns } from "./store/campaign/thunks";
import {
  setActiveFiltersAction,
  setCampaignOptionsAction,
  setConversationsUpdatedAction,
  setInitFiltersAction,
} from "./store/conversation/actions";
import { getConversationCountByDoctor } from "./store/conversation/thunks";
import { searchConversationsWithFilters } from "./store/conversation/thunks";
import {
  FeatureFlags,
  initializeFeatureFlags,
  useFeature,
} from "./store/featureFlagSlice";
import { getNumOfPatients, searchPatients } from "./store/patient/thunks";
import { getSites, getUserSites } from "./store/site/thunks";
import { setDoctorOptionsAction } from "./store/user/actions";
import { getUser, updateStoredUser } from "./store/user/thunks";
import { selectUser } from "./store/user/userReducer";
import { setCallEventAction } from "./store/voiceRecorder/actions";
import { getTwilioToken } from "./store/voiceRecorder/thunks";

const storageService = new LocalStorageService();

const App = () => {
  const user = useAppSelector(selectUser);
  const isFrontDesk = useFeature(FeatureFlags.FRONT_DESK);
  const isFrontDeskInbox = useFeature(FeatureFlags.FRONT_DESK_INBOX);
  const isCCM = useFeature(FeatureFlags.CCM);
  const { doctorOptions } = useAppSelector((state) => state.user);
  const { campaigns } = useAppSelector((state) => state.campaign);
  const {
    campaignFilterOptions,
    initFilters,
    selectedDoctorInbox,
    conversationSearchInput,
    activeFilters,
    convCurrPage,
  } = useAppSelector((state) => state.conversation);
  const { rowsPerPage } = useAppSelector((state) => state.patient);
  const dispatch = useAppDispatch();

  const token = storageService.getItem("token");

  const onChatMessage = useCallback(
    (eventData) => {
      const id = eventData.conversation_id;
      dispatch(
        baseApi.util.invalidateTags([
          {
            type: "Conversation",
            id: Number(id),
          },
          "Call",
          "CallCounts",
        ]),
      );

      dispatch(setConversationsUpdatedAction(false));
      dispatch(
        searchConversationsWithFilters(
          conversationSearchInput,
          activeFilters,
          1,
          convPerPage * convCurrPage,
          inboxes.COMPLETE,
          selectedDoctorInbox,
        ),
      );
    },
    [
      dispatch,
      conversationSearchInput,
      activeFilters,
      convCurrPage,
      selectedDoctorInbox,
    ],
  );

  const onVoiceMessage = useCallback(
    (eventData) => {
      // console.log("websocket event voice", eventData.data);
      dispatch(setCallEventAction(eventData.data));
    },
    [dispatch],
  );

  const connect = useCallback(() => {
    const websocket = new WebSocket(`${WS_URL}?token=${token}`);

    websocket.onopen = () => {
      // console.log("Websocket is connected");
    };

    websocket.onerror = () => {
      websocket.close();
    };

    websocket.onclose = () => {
      // console.log(
      //   "Websocket is closed. Reconnect will be attempted in 1 second",
      // );
      dispatch(setConversationsUpdatedAction(false));
      setTimeout(() => {
        connect();
      }, 1000);
    };

    websocket.onmessage = (event) => {
      const eventData = JSON.parse(event.data);

      if (eventData.type === "chat") {
        onChatMessage(eventData);
      } else if (eventData.type === "voice") {
        onVoiceMessage(eventData);
      }
    };
  }, [dispatch, token, onChatMessage, onVoiceMessage]);

  useEffect(() => {
    if (storageService.getItem("token")) {
      getUser().then((user) => {
        dispatch(updateStoredUser(user));
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      connect();
    }
  }, [user, connect]);

  useEffect(() => {
    if (user?.organization?.featureFlags) {
      dispatch(initializeFeatureFlags(user.organization.featureFlags));
    }
  }, [user?.organization?.featureFlags, dispatch]);

  useEffect(() => {
    if (user) {
      Bugsnag.setUser(user.doctor_id, user.email, user.display_name);
      Bugsnag.setContext(`org: ${user.customer.display_name}`);

      dispatch(getSites());
      dispatch(getUserSites(user.doctor_id));
      if (isFrontDesk || isFrontDeskInbox) {
        dispatch(getTwilioToken());
        dispatch(getNumOfPatients());
        dispatch(
          searchPatients(
            storageService.getItem("patientSearchInput") ?? "",
            1,
            rowsPerPage,
            storageService.getItem("selectedDoctorPatients") ?? null,
            true,
            () => {},
            storageService.getItem("selectedPatientCampaigns") ?? [],
            storageService.getItem("selectedPatientStatuses") ?? [],
            storageService.getItem("selectedPatientInsurances") ?? [],
          ),
        );
        dispatch(setDoctorOptionsAction(user.doctors));
        dispatch(getConversationCountByDoctor("unassigned"));
        dispatch(getConversationCountByDoctor("clinic_all"));
        dispatch(getConversationCountByDoctor(null));
      }
      if (isCCM) {
        dispatch(getCampaigns());

        // if CCM enabled, enable front desk inbox
        if (!isFrontDeskInbox) {
          dispatch(
            updateFeatureFlag({
              flag: FeatureFlags.FRONT_DESK_INBOX,
              enabled: true,
            }),
          );
        }
      }
    }
  }, [user, rowsPerPage, isFrontDesk, isFrontDeskInbox, isCCM, dispatch]);

  useEffect(() => {
    if (doctorOptions.length > 0) {
      doctorOptions.forEach((doctor) => {
        dispatch(getConversationCountByDoctor(doctor));
      });
      dispatch(getConversationCountByDoctor("unassigned"));
      dispatch(getConversationCountByDoctor(null));
    }
  }, [doctorOptions, dispatch]);

  useEffect(() => {
    if (user && campaigns.length > 0) {
      dispatch(
        setSelectedEnrollCampaignAction(
          storageService.getItem("selectedEnrollCampaign") ?? campaigns[0],
        ),
      );

      const customerCampaigns = campaigns.map((campaign) => ({
        value: campaign.campaign_id,
        text: campaign.campaign,
      }));

      dispatch(
        setCampaignOptionsAction([...customerCampaigns, ...commonCampaigns]),
      );
    }
  }, [campaigns, dispatch, user]);

  useEffect(() => {
    if (campaignFilterOptions.length > 0) {
      dispatch(setInitFiltersAction(getFiltersInit(campaignFilterOptions)));
    }
  }, [campaignFilterOptions, dispatch]);

  useEffect(() => {
    if (initFilters && !deepEqual(initFilters, filtersClear)) {
      dispatch(
        setActiveFiltersAction(
          storageService.getItem("activeFilters") ?? initFilters,
        ),
      );
    }
  }, [initFilters, dispatch]);

  return (
    <>
      <Suspense
        fallback={
          <div className="w-screen h-screen flex items-center justify-center">
            <Loader />
          </div>
        }
      >
        <AppRoutes />
      </Suspense>
      <Analytics />
      <SpeedInsights />
    </>
  );
};

export default App;
