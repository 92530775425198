import { Call, Device } from "@twilio/voice-sdk";
import { twilioDeviceStatuses } from "../helpers/constants";
import type { AppDispatch } from "../store";
import {
  setDeviceStatusAction,
  setOpenCallModalAction,
} from "../store/voiceRecorder/actions";
import { getTwilioToken } from "../store/voiceRecorder/thunks";

class TwilioService {
  private readonly dispatch: AppDispatch;
  private twilioDevice: Device | null;
  private call: Call | null;
  private muteTimeout: NodeJS.Timeout | null;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.twilioDevice = null;
    this.call = null;
    this.muteTimeout = null;
  }

  destroyTwilioDevice(): void {
    if (this.twilioDevice) {
      // console.log("Destroying Twilio device");
      this.twilioDevice.destroy();
    }
  }

  initializeTwilioDevice(token: string): void {
    const deviceOptions: Device.Options = {
      allowIncomingWhileBusy: true,
      closeProtection: true,
      codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
    };

    try {
      // console.log("Initializing Twilio device");
      this.twilioDevice = new Device(token, deviceOptions);

      this.twilioDevice.on("ready", () => {
        // console.log("Twilio device is ready, state:", this.twilioDevice?.state);
        this.dispatch(setDeviceStatusAction(twilioDeviceStatuses.READY));
      });

      this.twilioDevice.on("error", (error: Error) => {
        console.error(
          "Twilio device error:",
          error,
          "Current state:",
          this.twilioDevice?.state,
        );
        this.dispatch(setDeviceStatusAction(twilioDeviceStatuses.OFFLINE));
        this.dispatch(getTwilioToken());
      });

      this.twilioDevice.on("disconnect", () => {
        // console.log(
        //   "Twilio device disconnected, state:",
        //   this.twilioDevice?.state,
        // );
        this.dispatch(setDeviceStatusAction(twilioDeviceStatuses.READY));
        this.dispatch(setOpenCallModalAction(false));
      });

      this.twilioDevice.on("tokenWillExpire", () => {
        // console.log("Twilio token will expire soon");
        this.dispatch(getTwilioToken());
      });

      // console.log("Registering Twilio device");
      this.twilioDevice
        .register()
        .then(() => {
          // console.log("Twilio device registered successfully");
          this.dispatch(setDeviceStatusAction(twilioDeviceStatuses.READY));
        })
        .catch((error) => {
          console.error("Failed to register Twilio device:", error);
          this.dispatch(setDeviceStatusAction(twilioDeviceStatuses.OFFLINE));
          this.dispatch(getTwilioToken());
        });
    } catch (error) {
      console.error("Error initializing Twilio device:", error);
      this.dispatch(setDeviceStatusAction(twilioDeviceStatuses.OFFLINE));
      this.dispatch(getTwilioToken());
    }
  }

  async handleCall(phoneNumber: string, patientId: number): Promise<void> {
    if (!this.twilioDevice) {
      console.error("No Twilio device available");
      this.dispatch(getTwilioToken());
      return;
    }

    // console.log("Device state before connect:", this.twilioDevice.state);

    try {
      console.log("Connecting call to:", phoneNumber);
      this.call = await this.twilioDevice.connect({
        params: {
          To: phoneNumber,
          patientId: patientId.toString(),
        },
      });
      // console.log(
      //   "Call connected successfully, device state:",
      //   this.twilioDevice.state,
      // );
      this.setupCallListeners();
    } catch (error) {
      console.error("Error connecting call:", error);
      console.log("Device state after error:", this.twilioDevice?.state);
      this.dispatch(getTwilioToken());
    }
  }

  handleDisconnect(): void {
    console.log("Disconnecting call");
    if (this.call) {
      this.call.disconnect();
    }
    this.dispatch(setOpenCallModalAction(false));
  }

  setupCallListeners(): void {
    if (this.call) {
      this.call.on("cancel", () => {
        // console.log("Call canceled");
        this.handleDisconnect();
      });

      this.call.on("disconnect", () => {
        // console.log(
        //   "Call disconnected, device state:",
        //   this.twilioDevice?.state,
        // );
        this.handleDisconnect();
      });

      this.call.on("error", (error: Error) => {
        console.error("Call error:", error);
        console.log("Device state after call error:", this.twilioDevice?.state);
        this.handleDisconnect();
      });

      this.call.on("reject", () => {
        console.log("Call rejected");
        this.handleDisconnect();
      });
    }
  }

  toggleMute(isMuted: boolean): void {
    if (this.call) {
      if (this.muteTimeout) {
        clearTimeout(this.muteTimeout);
      }
      this.muteTimeout = setTimeout(() => {
        this.call?.mute(isMuted);
      }, 300);
    }
  }
}

export default TwilioService;
