import { ReactComponent as EditIcon } from "@assets/icons/pen.svg";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../store";
import type { CallNote } from "../../../../store/call/interfaces";
import { deleteCallNote, updateCallNote } from "../../../../store/call/thunks";
import CallNoteEditing from "./CallNoteEditing";

interface CallNoteProps {
  callNote: CallNote;
  isFirst?: boolean;
  isLast?: boolean;
}

const CallNoteCard = ({ callNote, isFirst, isLast }: CallNoteProps) => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState("");
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [tempNote, setTempNote] = useState(callNote);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTempNote(callNote);
  }, [callNote]);

  const handleEditMode = () => {
    setValue(tempNote.note);
    setEditMode(!editMode);
  };

  const handleDeleteNote = () => {
    dispatch(
      deleteCallNote(
        tempNote.assistant_call_id,
        tempNote.assistant_call_note_id,
      ),
    );
  };

  const handleUpdateNote = () => {
    if (value.trim() && value.trim() !== tempNote.note) {
      setTempNote({
        ...tempNote,
        note: value.trim(),
      });
      setSaveDisabled(true);
      dispatch(
        updateCallNote(
          tempNote.assistant_call_id,
          tempNote.assistant_call_note_id,
          value.trim(),
          () => {
            setEditMode(false);
            setSaveDisabled(false);
          },
        ),
      );
    } else {
      setEditMode(false);
    }
  };

  return (
    <div className="relative pl-9 py-2.5">
      <div className="absolute left-3 top-0 bottom-0 h-full w-0 border-l border-dashed border-gray-300" />
      {isFirst && <div className="absolute w-6 h-1/2 bg-white left-0 top-0" />}
      {isLast && (
        <div className="absolute w-6 h-1/2 bg-white left-0 bottom-0" />
      )}
      <div className="absolute left-0 top-1/2 -translate-y-1/2 w-6 h-6 bg-white flex items-center justify-center">
        <div className="w-2 h-2 border-2 border-gray-foreground rounded-full box-content" />
      </div>

      {editMode ? (
        <CallNoteEditing
          value={value}
          setValue={setValue}
          onDelete={handleDeleteNote}
          onSave={handleUpdateNote}
          saveDisabled={saveDisabled}
          saveLabel="Save"
        />
      ) : (
        <div
          className="bg-[#FFF7E9] rounded-lg p-3 whitespace-pre-wrap text-sm font-medium cursor-pointer relative group"
          onClick={handleEditMode}
        >
          <p className="text-[#32413C]">{tempNote.note}</p>
          <p className="text-tertiary">
            {new Date(
              `${tempNote.created_at.replace(/-/g, "/")} GMT+0`,
            ).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}
          </p>

          <EditIcon
            width="20"
            height="20"
            stroke="#667085"
            className="absolute bottom-3 right-3 transition-all opacity-0 group-hover:opacity-100"
          />
        </div>
      )}
    </div>
  );
};

export default CallNoteCard;
