import { ReactComponent as FilterIcon } from "@assets/icons/funnel.svg";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  setSelectedPatientCampaignsAction,
  setSelectedPatientInsurancesAction,
  setSelectedPatientStatusesAction,
} from "../../store/patient/actions";
import Button from "../Basic/Button";
import ModalMobile from "../Basic/ModalMobile";
import FilterForm from "./FilterForm";

const FilterButton = ({
  width = "296px",
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "right",
  },
  popMargin = "8px 0 0 0",
  className = "",
  buttonClassName = "",
}) => {
  const {
    selectedPatientStatuses,
    selectedPatientCampaigns,
    selectedPatientInsurances,
  } = useSelector((state) => state.patient);
  const [openFilter, setOpenFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTags, setSelectedTags] = useState(selectedPatientCampaigns);
  const [selectedStatuses, setSelectedStatuses] = useState(
    selectedPatientStatuses,
  );
  const [selectedInsurances, setSelectedInsurances] = useState(
    selectedPatientInsurances,
  );

  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const onCloseOptions = () => {
    setOpenFilter(false);
    setAnchorEl(null);
    setSelectedTags(selectedPatientCampaigns);
    setSelectedStatuses(selectedPatientStatuses);
    setSelectedInsurances(selectedPatientInsurances);
  };

  const onReset = () => {
    dispatch(setSelectedPatientCampaignsAction([]));
    dispatch(setSelectedPatientStatusesAction([]));
    dispatch(setSelectedPatientInsurancesAction([]));
    setSelectedTags([]);
    setSelectedStatuses([]);
    setSelectedInsurances([]);
  };

  const onApply = () => {
    dispatch(setSelectedPatientCampaignsAction(selectedTags));
    dispatch(setSelectedPatientStatusesAction(selectedStatuses));
    dispatch(setSelectedPatientInsurancesAction(selectedInsurances));
  };

  return (
    <div className={className}>
      <Button
        variant="gray-light"
        size="unset"
        className={`space-x-2 h-10 min-w-[40px] w-fit px-2 md:px-3 ${buttonClassName}`}
        onClick={(e) => {
          setSelectedTags(selectedPatientCampaigns);
          setSelectedStatuses(selectedPatientStatuses);
          setSelectedInsurances(selectedPatientInsurances);
          setOpenFilter(true);
          setAnchorEl(e.currentTarget);
        }}
      >
        {((selectedPatientStatuses.length === 0 &&
          selectedPatientCampaigns.length === 0 &&
          selectedPatientInsurances.length === 0) ||
          !isDesktop) && (
          <FilterIcon width="20" height="20" className="flex-none" />
        )}

        <p className="hidden md:flex">
          {selectedPatientStatuses.length > 0 ||
          selectedPatientCampaigns.length > 0 ||
          selectedPatientInsurances.length > 0
            ? "Filter"
            : "All"}
        </p>

        {(selectedPatientStatuses.length > 0 ||
          selectedPatientCampaigns.length > 0 ||
          selectedPatientInsurances.length > 0) && (
          <div
            className="w-5 h-5 rounded-full bg-primary-blue text-white text-xs leading-none font-semibold text-center
              flex items-center justify-center"
          >
            {selectedPatientStatuses.length +
              selectedPatientCampaigns.length +
              selectedPatientInsurances.length}
          </div>
        )}
      </Button>

      {isDesktop && openFilter && anchorEl && (
        <Popover
          open={openFilter}
          onClose={onCloseOptions}
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          slotProps={{
            paper: {
              sx: {
                borderRadius: "8px",
                width: width,
                boxShadow: "0px 2px 6px 0px rgba(18, 18, 18, 0.08)",
                margin: popMargin,
              },
            },
          }}
        >
          <div className="w-full">
            <div className="p-5">
              <p className="mb-5 text-xl font-semibold">Filter</p>

              <FilterForm
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                selectedStatuses={selectedStatuses}
                setSelectedStatuses={setSelectedStatuses}
                selectedInsurances={selectedInsurances}
                setSelectedInsurances={setSelectedInsurances}
              />
            </div>

            <div className="flex items-center justify-between gap-4 font-semibold bg-gray-background py-4 px-5">
              <Button
                variant="text-primary"
                size="unset"
                onClick={() => {
                  onReset();
                  onCloseOptions();
                }}
              >
                Reset
              </Button>
              <div className="flex text-sm gap-4">
                <Button variant="gray" onClick={onCloseOptions}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    onApply();
                    onCloseOptions();
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
        </Popover>
      )}

      {!isDesktop && (
        <ModalMobile
          open={openFilter}
          onClickAway={onCloseOptions}
          childHeight={410}
          className=""
          header={
            <div className="flex items-center justify-between">
              <p>Filter</p>
              <Button
                variant="text-primary"
                size="unset"
                className="text-base"
                onClick={() => {
                  onReset();
                  onCloseOptions();
                }}
              >
                Reset
              </Button>
            </div>
          }
        >
          <div className="w-full grid grid-rows-note-layout overflow-hidden">
            <FilterForm
              className="px-5 pb-5 pt-2 mih-h-0 overflow-y-auto scrollbar"
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              selectedStatuses={selectedStatuses}
              setSelectedStatuses={setSelectedStatuses}
              selectedInsurances={selectedInsurances}
            />
            <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5">
              <Button
                className="w-full md:w-fit"
                variant="gray"
                onClick={onCloseOptions}
              >
                Cancel
              </Button>
              <Button
                className="w-full md:w-fit"
                onClick={() => {
                  onApply();
                  onCloseOptions();
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </ModalMobile>
      )}
    </div>
  );
};

export default FilterButton;
