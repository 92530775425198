import { ReactComponent as PlusIcon } from "@assets/icons/plus-small.svg";
import { useNavigate } from "react-router";
import {
  Pages,
  patientPanelTabs,
  patientProfileTabs,
} from "../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  setOpenEditInfoModalAction,
  setSelectedPatientPanelTabAction,
  setSelectedPatientProfileTabAction,
} from "../../store/patient/actions";
import { getPatientInfo } from "../../store/patient/thunks";
import { selectUser } from "../../store/user/userReducer";
import Button from "./Button";

interface AddPhoneNumberProps {
  patientId: number;
  call?: boolean;
}

const AddPhoneNumber = ({ patientId, call }: AddPhoneNumberProps) => {
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className="w-full h-full flex flex-col items-center justify-center bg-gray-background">
      <div className="w-12 h-12 rounded-full flex items-center justify-center bg-primary-blue-light mb-5">
        <PlusIcon width="20" height="20" />
      </div>
      <p className="text-xl lg:text-2xl font-bold mb-1">No Phone Number</p>
      <p className="text-sm lg:text-base font-medium text-tertiary mb-6">
        Add phone number to {call ? "call" : "message"} patient
      </p>
      {!user.customer.has_ehr_data && (
        <Button
          className="text-sm font-semibold"
          onClick={() => {
            dispatch(
              getPatientInfo(patientId, () => {
                navigate(`${Pages.PATIENTS}/${patientId}`);
              }),
            );
            dispatch(
              setSelectedPatientProfileTabAction(
                patientProfileTabs.INFORMATION,
              ),
            );
            dispatch(
              setSelectedPatientPanelTabAction(
                call ? patientPanelTabs.CALL : patientPanelTabs.MESSAGE,
              ),
            );
            dispatch(setOpenEditInfoModalAction(true));
          }}
        >
          Add Phone Number
        </Button>
      )}
    </div>
  );
};

export default AddPhoneNumber;
