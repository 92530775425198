import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { VariableSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { useAppDispatch, useAppSelector } from "../../../store";
import { FeatureFlags } from "../../../store/featureFlagSlice";
import { useFeature } from "../../../store/featureFlagSlice";
import ScribeListItem from "../ScribeListItem";
import useScribeSearchParams from "../hooks/useScribeSearchParams";
import { getGroupedScribes, selectPagination } from "../store/selectors";
import { getProviderNotes } from "../store/thunks";

const ITEM_HEIGHT = 54;
const HEADER_HEIGHT = 20;
const SEPARATOR_HEIGHT = 25;

const ScribeListContent = ({ className = "" }: { className?: string }) => {
  const { selectedScribeId } = useScribeSearchParams();
  const isFrontDesk = useFeature(FeatureFlags.FRONT_DESK);
  const isFrontDeskInbox = useFeature(FeatureFlags.FRONT_DESK_INBOX);
  const groupedScribes = useAppSelector(getGroupedScribes);
  const { nextCursor, isFetching } = useAppSelector(selectPagination);

  const dispatch = useAppDispatch();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<VariableSizeList>(null);
  const [containerHeight, setContainerHeight] = useState(0);

  const updateListMeasurements = useCallback(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.clientHeight);
      if (listRef.current) {
        listRef.current.resetAfterIndex(0);
      }
    }
  }, []);

  useEffect(() => {
    updateListMeasurements();
  }, [updateListMeasurements]);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === containerRef.current) {
          setContainerHeight(entry.contentRect.height);
          if (listRef.current) {
            listRef.current.resetAfterIndex(0);
          }
        }
      }
    });

    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const filteredGroupedScribes = useMemo(() => {
    return groupedScribes.map((group) => ({
      ...group,
      entries: group.entries.filter((scribe) => scribe.type === "scribe"),
    }));
  }, [groupedScribes]);

  const memoizedItems = useMemo(() => {
    const items: { type: "header" | "item" | "separator"; data: any }[] = [];
    filteredGroupedScribes.forEach((group, groupIdx) => {
      if (group.entries.length === 0) {
        return;
      }

      items.push({ type: "header", data: group.date });
      group.entries.forEach((scribe) => {
        items.push({ type: "item", data: scribe });
      });

      if (groupIdx < filteredGroupedScribes.length - 1) {
        items.push({ type: "separator", data: group.date });
      }
    });

    return items;
  }, [filteredGroupedScribes]);

  useEffect(() => {
    if (listRef.current && memoizedItems.length > 0) {
      listRef.current.resetAfterIndex(0);
    }
  }, [memoizedItems]);

  useEffect(() => {
    if (memoizedItems.length > 0) {
      updateListMeasurements();
    }
  }, [memoizedItems, updateListMeasurements]);

  const getItemSize = useCallback(
    (index: number) => {
      if (index >= memoizedItems.length) {
        return ITEM_HEIGHT;
      }

      const item = memoizedItems[index];
      switch (item.type) {
        case "header":
          return HEADER_HEIGHT;
        case "item":
          return ITEM_HEIGHT;
        case "separator":
          return SEPARATOR_HEIGHT;
        default:
          return HEADER_HEIGHT;
      }
    },
    [memoizedItems],
  );

  const loadMoreItems = useCallback(() => {
    if (!isFetching && nextCursor !== -1) {
      return dispatch(getProviderNotes({ cursor: nextCursor }));
    }
    return Promise.resolve();
  }, [dispatch, isFetching, nextCursor]);

  const isItemLoaded = useCallback(
    (index: number) => index < memoizedItems.length,
    [memoizedItems],
  );

  const renderItem = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      if (!isItemLoaded(index)) {
        return (
          <div style={style} className="flex justify-center items-center">
            Loading more...
          </div>
        );
      }

      const item = memoizedItems[index];
      switch (item.type) {
        case "header":
          return (
            <div key={item.data} style={style} className="pr-1">
              <div className="flex justify-between pb-1">
                <p className="text-xs font-medium text-tertiary uppercase">
                  {item.data}
                </p>
              </div>
            </div>
          );
        case "item":
          return (
            <div key={item.data.audioId} style={style}>
              <ScribeListItem
                scribe={item.data}
                isSelected={selectedScribeId === item.data.audioId}
                isFrontDesk={isFrontDesk}
                isFrontDeskInbox={isFrontDeskInbox}
              />
            </div>
          );
        case "separator":
          return (
            <div
              key={`separator-${item.data}`}
              style={style}
              className="md:px-1 py-3"
            >
              <hr />
            </div>
          );
        default:
          return null;
      }
    },
    [
      memoizedItems,
      isFrontDesk,
      isFrontDeskInbox,
      selectedScribeId,
      isItemLoaded,
    ],
  );

  const itemCount =
    nextCursor !== -1 ? memoizedItems.length + 1 : memoizedItems.length;

  return (
    <main className={`h-full overflow-hidden ${className}`} ref={containerRef}>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
        threshold={2}
      >
        {({ onItemsRendered, ref }) => (
          <VariableSizeList
            className="scrollbar"
            height={containerHeight}
            itemCount={itemCount}
            itemSize={getItemSize}
            width="100%"
            onItemsRendered={onItemsRendered}
            ref={(list) => {
              ref(list);
              listRef.current = list;
            }}
            overscanCount={3}
          >
            {renderItem}
          </VariableSizeList>
        )}
      </InfiniteLoader>
    </main>
  );
};

export default ScribeListContent;
