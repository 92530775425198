import { useCallback, useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import ScribeListSkeleton from "../../Skeletons/ScribeListSkeleton";
import ScribeListContent from "../ScribeListContent";
import ScribeListEmpty from "../ScribeListEmpty";
import ScribeListFooter from "../ScribeListFooter";
import ScribeListHeader from "../ScribeListHeader";
import {
  getGroupedScribes,
  selectIsLoading,
  selectPagination,
} from "../store/selectors";
import { getProviderNotes } from "../store/thunks";

interface ScribeListProps {
  setIsNewScribe: () => void;
}

const ScribeList = ({ setIsNewScribe }: ScribeListProps) => {
  const dispatch = useAppDispatch();
  const groupedScribes = useAppSelector(getGroupedScribes);
  const { nextCursor, isFetching } = useAppSelector(selectPagination);
  const isLoading = useAppSelector(selectIsLoading);
  const containerRef = useRef<HTMLDivElement>(null);

  const filteredGroupedScribes = useMemo(() => {
    return groupedScribes.map((group) => ({
      ...group,
      entries: group.entries.filter((scribe) => scribe.type === "scribe"),
    }));
  }, [groupedScribes]);

  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container || nextCursor === -1 || isFetching || isLoading) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = container;
    if (scrollHeight - scrollTop - clientHeight < 200) {
      dispatch(getProviderNotes({ cursor: nextCursor }));
    }
  }, [nextCursor, isFetching, isLoading, dispatch]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  if (filteredGroupedScribes.length === 0 && !isLoading) {
    return <ScribeListEmpty setIsNewScribe={setIsNewScribe} />;
  }

  return (
    <div className="flex flex-col space-y-4 h-full w-full overflow-hidden">
      <ScribeListHeader />
      <div
        ref={containerRef}
        className="h-full flex flex-col overflow-auto pb-20 md:pb-18"
      >
        {filteredGroupedScribes.length > 0 && (
          <ScribeListContent className="z-[1]" />
        )}
        {filteredGroupedScribes.length === 0 && (isLoading || isFetching) && (
          <ScribeListSkeleton className="pb-18 relative z-[1]" />
        )}
        <ScribeListFooter setIsNewScribe={setIsNewScribe} />
        <div className="fixed top-0 md:left-[unset] -ml-4 md:w-[300px] bg-gray-background w-full h-full" />
      </div>
    </div>
  );
};

export default ScribeList;
