import { ReactComponent as CloseIcon } from "@assets/icons/close.svg";
import { ReactComponent as LogoutIcon } from "@assets/icons/logout-simple.svg";
import { ReactComponent as MenuIcon } from "@assets/icons/menu.svg";
import { ReactComponent as LogoIcon } from "@assets/logo-quadrant-text.svg";
import { useEffect, useState } from "react";
import { NavLink } from "react-router";
import { getDefaultRoute } from "../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../store";
import { FeatureFlags, useFeature } from "../../store/featureFlagSlice";
import { logoutAction } from "../../store/user/actions";
import { selectUser } from "../../store/user/userReducer";
import ProfilePicture from "../Basic/ProfilePicture";
import SiteSelect from "./SiteSelect";

const MobileMenu = ({ navButtons, handleNavLinkClick }) => {
  const user = useAppSelector(selectUser);
  const isScribeOnly = useFeature(FeatureFlags.SCRIBE_ONLY);
  const isFrontDeskEnabled = useFeature(FeatureFlags.FRONT_DESK);
  const isFrontDeskInbox = useFeature(FeatureFlags.FRONT_DESK_INBOX);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const dispatch = useAppDispatch();

  const logoLink = getDefaultRoute({
    isScribeOnlyFeatureEnabled: isScribeOnly,
    isFrontDeskInboxEnabled: isFrontDeskInbox,
    isFrontDeskEnabled: isFrontDeskEnabled,
  });

  useEffect(() => {
    if (showMobileMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showMobileMenu]);

  const logout = () => {
    dispatch(logoutAction());
  };

  return (
    <nav className="fixed top-0 left-0 flex items-center justify-between w-screen h-16 py-3 px-5 flex-none bg-white z-50">
      <NavLink
        aria-label="Inbox"
        to={logoLink}
        className="z-50"
        onClick={(e) => handleNavLinkClick(e, logoLink)}
      >
        <LogoIcon width="128" height="40" className="flex-none" />
      </NavLink>

      <button
        type="button"
        aria-label="Menu"
        onClick={() => setShowMobileMenu(!showMobileMenu)}
        className="z-50"
      >
        {showMobileMenu ? (
          <CloseIcon stroke="#121212" width="24" height="24" />
        ) : (
          <MenuIcon stroke="#121212" width="24" height="24" />
        )}
      </button>

      <div
        className={`fixed top-0 left-0 h-screen-dynamic pt-16 z-10 text-tertiary transition-all duration-300
          ${showMobileMenu ? "w-screen" : "w-0"}`}
      >
        <div className="h-full bg-white overflow-x-hidden flex flex-col space-y-4 justify-between pb-8">
          <div
            className="flex flex-col space-y-4 border-t border-white/20 pt-7 px-5"
            onClick={() => setShowMobileMenu(false)}
          >
            {navButtons}
          </div>

          <div>
            {/* <p
              className={`text-xs text-right p-3 ${showMobileMenu ? "" : "hidden"}`}
            >
              Version: {process.env.REACT_APP_VERSION}
            </p> */}

            <SiteSelect
              isMobile
              onSelectCallback={() => setShowMobileMenu(false)}
            />

            <div className="flex items-center px-5 mt-4">
              <ProfilePicture
                size={11}
                fontSize="base"
                className="cursor-pointer"
                firstName={user.first_name}
                lastName={user.last_name}
                src={user.profile_picture}
                flagPadding={false}
              />
              <div className="ml-3 space-y-1 truncate">
                <p className="truncate">{user.display_name}</p>
                <p className="text-zinc-500 text-xs truncate">{user.email}</p>
              </div>
              <button
                type="button"
                aria-label="Logout"
                className="ml-auto"
                onClick={logout}
              >
                <LogoutIcon width="24" height="24" stroke="#667085" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MobileMenu;
