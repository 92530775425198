import { useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { saveEncounterPatient } from "../../../store/conversation/thunks";
import type { Patient } from "../../../store/patient/interfaces";
import AssignPatientModal from "../AssignPatientModal";
import ScribeHeader from "../ScribeHeader";
import ScribeNote from "../ScribeNote";
import ScribeViewDetailsFooter from "../ScribeViewDetailsFooter";
import useScribeSearchParams from "../hooks/useScribeSearchParams";
import { selectScribeById } from "../store/selectors";

const ScribeViewDetails = ({
  patient,
  handleAssignPatientId,
  onBack,
  onResumeRecording,
}: {
  patient: Patient | null;
  handleAssignPatientId: (patientId: number) => void;
  onBack: () => void;
  onResumeRecording: () => void;
}) => {
  const { selectedScribeId } = useScribeSearchParams();
  const selectedScribe = useAppSelector((state) =>
    selectScribeById(state, selectedScribeId),
  );

  const dispatch = useAppDispatch();

  const [showAssignPatientModal, setShowAssignPatientModal] = useState(false);

  const onAssignPatient = useCallback(
    (patientId: number) => {
      handleAssignPatientId(patientId);
      dispatch(saveEncounterPatient(selectedScribe?.audioId, patientId));
    },
    [dispatch, handleAssignPatientId, selectedScribe?.audioId],
  );

  const onCloseAssignPatientModal = useCallback(() => {
    setShowAssignPatientModal(false);
  }, []);

  return (
    <>
      <ScribeHeader
        handleAssignPatientId={onAssignPatient}
        onBack={onBack}
        patient={patient}
        setShowAssignPatientModal={setShowAssignPatientModal}
      />
      <div className="w-full h-full min-h-0 flex flex-col md:justify-between px-4 md:px-0 pt-5 pb-18">
        <main className="w-full h-full overflow-auto pr-1 scrollbar">
          <ScribeNote originalNote={selectedScribe?.note} patient={patient} />
          {/* <FreeText
          originalFreeText={selectedScribe?.freeText}
          variant="oldRecord"
          copyButton
        /> */}
        </main>
        <ScribeViewDetailsFooter onResumeRecording={onResumeRecording} />
      </div>
      {showAssignPatientModal && (
        <AssignPatientModal
          handleClose={onCloseAssignPatientModal}
          handleAssignPatientId={onAssignPatient}
        />
      )}
    </>
  );
};

export default ScribeViewDetails;
