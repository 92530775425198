import noSoundDetectedImage from "@assets/no-sound-detected.svg";
import { useMediaQuery } from "react-responsive";
import Warning from "./index";

const defaultOnCancel = () => {};

const desktopDescription =
  "Recording Stopped when the app was minimized. To capture conversation, keep app visible.";
const mobileDescription =
  "Recording Stopped when the app was minimized. To capture conversation, keep app visible and decline calls.";

const RecordingContextStalled = ({
  onSubmit = () => {},
  onCancel = defaultOnCancel,
}: {
  onSubmit?: () => void;
  onCancel?: () => void;
}) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <Warning
      image={noSoundDetectedImage}
      title="Microphone Access Lost"
      description={isDesktop ? desktopDescription : mobileDescription}
      submitText={isDesktop ? "Try again" : "Finish Later"}
      // cancelText={isDesktop ? "" : "Resume"}
      horizontal={!isDesktop}
      onSubmit={onSubmit}
      onCancel={!isDesktop ? onCancel : defaultOnCancel}
    />
  );
};

export default RecordingContextStalled;
