import { ReactComponent as ArrowsIcon } from "@assets/icons/double-arrow-left.svg";
import { ReactComponent as SearchIcon } from "@assets/icons/search.svg";
import { debounce } from "@mui/material/utils";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import {
  InboxTabsInit,
  convPerPage,
  inboxes,
} from "../../../helpers/constants";
import { selectInputText } from "../../../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  collapseSidebarAction,
  setActiveInboxAction,
  setConvCurrPageAction,
  setConversationsCompletedTempAction,
  setConversationsScrollPositionAction,
  setFiltersUpdatedAction,
  setSearchInputAction,
  setSelectedConversationPatientAction,
  setSelectedDoctorInboxAction,
} from "../../../store/conversation/actions";
import { searchConversationsWithFilters } from "../../../store/conversation/thunks";
import { selectUser } from "../../../store/user/userReducer";
import LoaderDots from "../../Basic/LoaderDots";
import ConversationListSkeleton from "../../Skeletons/ConversationListSkeleton";
import ConversationGroup from "./ConversationGroup";
import FilterDropdownButtons from "./FilterDropdownButtons";
import InboxTabsBorder from "./InboxTabsBorder";

const ConversationSidebar = () => {
  const {
    sidebarCollapsed,
    conversationsReview,
    conversations,
    conversationsCompleted,
    conversationsCompletedTemp,
    activeInbox,
    activeFilters,
    conversationSearchInput,
    conversationsScrollPosition,
    convCurrPage,
    loader,
    conversationsCounts,
    filtersUpdated,
    selectedDoctorInbox,
  } = useAppSelector((state) => state.conversation);
  const user = useAppSelector(selectUser);
  const [inboxTabs, setInboxTabs] = useState(InboxTabsInit);
  const dispatch = useAppDispatch();
  const listRef = useRef<HTMLDivElement>(null);
  const conversationCompletedTempRef = useRef(conversationsCompletedTemp);

  // reset filtering by doctor if selected
  useEffect(() => {
    if (selectedDoctorInbox) {
      onSelectDoctor(null);
    }
  }, [selectedDoctorInbox]);

  useEffect(() => {
    conversationCompletedTempRef.current = conversationsCompletedTemp;
  }, [conversationsCompletedTemp]);

  useEffect(() => {
    if (conversationsCounts?.[activeInbox]) {
      setInboxTabs([
        {
          name: "active",
          conversations: conversationsCounts[activeInbox]["active TOTAL"],
        },
        {
          name: "review",
          conversations: conversationsCounts[activeInbox]["review TOTAL"],
        },
        {
          name: "complete",
          conversations: conversationsCounts[activeInbox]["complete TOTAL"],
        },
      ]);
    }
  }, [conversationsCounts, activeInbox]);

  useLayoutEffect(() => {
    const ref = listRef.current;
    if (ref) {
      ref.scroll(0, conversationsScrollPosition);
    }
    return () => {
      if (ref) {
        dispatch(setConversationsScrollPositionAction(ref.scrollTop));
      }
    };
  }, [dispatch, conversationsScrollPosition]);

  useEffect(() => {
    if (conversationsCompleted && conversationsCompleted.all.length > 0) {
      dispatch(
        setConversationsCompletedTempAction([
          ...conversationCompletedTempRef.current.filter(
            (conversationId) =>
              !conversationsCompleted.all.some(
                (conv) => conv.lastMessage.conversation_id === conversationId,
              ),
          ),
        ]),
      );
    }
  }, [conversationsCompleted, dispatch]);

  const getConversations = () => {
    switch (activeInbox) {
      case inboxes.ACTIVE:
        return conversations;
      case inboxes.COMPLETE:
        return conversationsCompleted;
      case inboxes.REVIEW:
        return conversationsReview;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (filtersUpdated) {
      dispatch(setFiltersUpdatedAction(false));
    }
  }, [filtersUpdated, dispatch]);

  const onChangeTab = (tabName) => {
    dispatch(setActiveInboxAction(tabName));
    dispatch(setSelectedConversationPatientAction(null));
    dispatch(setConvCurrPageAction(1));
    dispatch(
      searchConversationsWithFilters(
        conversationSearchInput,
        activeFilters,
        1,
        convPerPage,
        tabName,
        selectedDoctorInbox,
      ),
    );
    clearCompletedTemp();
    scrollToTop();
  };

  const clearCompletedTemp = () => {
    dispatch(setConversationsCompletedTempAction([]));
  };

  const collapseConvSidebar = () => {
    dispatch(collapseSidebarAction(!sidebarCollapsed));
  };

  const scrollToTop = () => {
    if (listRef?.current) {
      listRef.current.scroll(0, 0);
    }
  };

  const handleSearchInput = (e) => {
    dispatch(setSearchInputAction(e.target.value));
    scrollToTop();
    debouncedSearch(e.target.value, selectedDoctorInbox);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((inputValue, selectedDoctorInbox) => {
        dispatch(
          searchConversationsWithFilters(
            inputValue,
            activeFilters,
            1,
            convPerPage * convCurrPage,
            activeInbox,
            selectedDoctorInbox,
          ),
        );
      }, 150),
    [dispatch, activeInbox, activeFilters, convCurrPage],
  );

  const hasMoreConversations = () => {
    return (
      getConversations() &&
      getConversations().total_conversations > getConversations().all.length
    );
  };

  const loadMoreConversations = () => {
    dispatch(
      searchConversationsWithFilters(
        conversationSearchInput,
        activeFilters,
        1,
        convPerPage * (convCurrPage + 1),
        activeInbox,
        selectedDoctorInbox,
        () => dispatch(setConvCurrPageAction(convCurrPage + 1)),
      ),
    );
  };

  const onSelectDoctor = (doctor) => {
    dispatch(setSelectedDoctorInboxAction(doctor));
    dispatch(
      searchConversationsWithFilters(
        conversationSearchInput,
        activeFilters,
        1,
        convPerPage * convCurrPage,
        activeInbox,
        doctor,
      ),
    );
  };

  return (
    <div className="relative h-full z-[11]">
      <div
        className={`pt-4 border-r border-gray-200 h-full w-full transition-all flex-none flex flex-col
          overflow-hidden bg-white absolute z-20 top-0 left-0 sidebar-static:static sidebar-static:w-80
          shadow-xl sidebar-static:shadow-none
          ${sidebarCollapsed ? "md:w-3" : "md:w-80"}`}
      >
        <div className="mb-4 px-4 space-y-4">
          <p className="font-semibold text-lg truncate">
            {user.customer.display_name || user.customer.name}
          </p>

          <div className="relative w-full">
            <input
              value={conversationSearchInput}
              onClick={selectInputText}
              onChange={handleSearchInput}
              className="rounded-lg bg-gray-100 h-10 text-sm outline-none w-full pl-11 placeholder:text-tertiary"
              placeholder="Search"
            />
            <SearchIcon
              className="absolute top-2.5 left-4"
              width="20"
              height="20"
            />
          </div>

          <InboxTabsBorder
            tabs={inboxTabs}
            activeTab={activeInbox}
            setActiveTab={onChangeTab}
            tabClassName="w-[33%]"
            className={`border-b-2 transition-all sidebar-static:opacity-100 ${sidebarCollapsed ? "md:opacity-0" : ""}`}
          />

          <FilterDropdownButtons />
        </div>

        <div
          className={`h-full overflow-y-auto scrollbar relative transition-all sidebar-static:opacity-100
            ${sidebarCollapsed ? "md:opacity-0" : ""}`}
          ref={listRef}
        >
          {loader || !getConversations() ? (
            <ConversationListSkeleton />
          ) : (
            <InfiniteScroll
              className="h-full"
              pageStart={0}
              initialLoad={false}
              loadMore={loadMoreConversations}
              hasMore={hasMoreConversations()}
              useWindow={false}
              loader={
                <div
                  key={0}
                  className="w-full h-10 flex items-center justify-center"
                >
                  <LoaderDots />
                </div>
              }
            >
              <ConversationGroup
                conversations={getConversations()}
                containerRef={listRef}
              />
            </InfiniteScroll>
          )}
        </div>
      </div>

      <button
        type="button"
        onClick={collapseConvSidebar}
        className={`w-6 h-6 rounded-full hidden md:flex sidebar-static:hidden items-center justify-center shadow-sm shadow-black/10
          bg-white absolute z-30 top-[16px] transition-all
          ${sidebarCollapsed ? "left-0" : "left-[308px]"}`}
        aria-label="Toggle conversation sidebar"
      >
        <ArrowsIcon
          className={`transition-all ${sidebarCollapsed ? "rotate-180" : ""}`}
          width="16"
          height="16"
        />
      </button>
    </div>
  );
};

export default ConversationSidebar;
