import { ReactComponent as PhoneIcon } from "@assets/icons/phone2.svg";
import { useCallback } from "react";
import { getFormattedPhoneNumber } from "../../../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  getFilterByTeamId,
  getIsFilterByAssignedToCurrentUser,
  getIsFilterByMentions,
  setFilterByTeamId,
  setIsFilterByAssignedToCurrentUser,
  setIsFilterByMentions,
} from "../../../store/call/callSlice";
import { CallStatusTab } from "../../../store/call/interfaces";
import { useGetTeamsQuery } from "../../../store/user/api";
import { type Team, selectUser } from "../../../store/user/userReducer";
import ProfilePicture from "../../Basic/ProfilePicture";
import { getTeamIcon } from "../../Settings/Clinic/Teams/IconSelection";
import { useCallManagement } from "../hooks/useCallManagement";
import FilterButton from "./FilterButton";

const optionStyles =
  "grid grid-cols-middle items-center gap-2 truncate text-sm w-full";
const optionButtonStyles =
  "p-3 w-full rounded-lg hover:bg-primary-blue-light truncate";
const activeOptionButtonStyles = "text-primary-blue bg-primary-blue-light";
const countStyles =
  "w-4.5 h-4.5 rounded-full flex items-center justify-center bg-primary-blue-light text-primary-blue text-xxs font-semibold";
const labelStyles = "text-left truncate";

const MentionsDropdown = () => {
  const user = useAppSelector(selectUser);

  const isFilterByMentions = useAppSelector(getIsFilterByMentions);
  const isFilterByAssignedToCurrentUser = useAppSelector(
    getIsFilterByAssignedToCurrentUser,
  );
  const filterByTeamId = useAppSelector(getFilterByTeamId);
  const dispatch = useAppDispatch();
  const {
    callCounts = {
      total_completed: 0,
      total_reviews: 0,
      hours_saved: 0,
      incoming_calls: 0,
      me: 0,
      teams: null,
    },
    filters,
  } = useCallManagement();

  const isFiltersApplied =
    isFilterByMentions || isFilterByAssignedToCurrentUser || filterByTeamId;

  const { data: teams = [] } = useGetTeamsQuery({
    search: "",
  });

  const canShowCounts = filters.status === CallStatusTab.Pending;

  const getOptionLabel = useCallback(
    (name: string, isActive?: boolean, displayCount?: boolean) => {
      switch (name) {
        case "phone_number":
          return (
            <div className={optionStyles}>
              <PhoneIcon
                width="20"
                height="20"
                fill={isActive ? "#2970FF" : "#121212"}
                className="flex-none"
              />
              <p className={labelStyles}>
                {getFormattedPhoneNumber(user.customer.assistant_phone_number)}
              </p>
            </div>
          );
        case "user":
          return (
            <div className={optionStyles}>
              <ProfilePicture
                size={5}
                fontSize="xxs"
                firstName={user.first_name}
                lastName={user.last_name}
                flagPadding={false}
                src={user.profile_picture}
              />
              <p className={labelStyles}>
                {user.display_name?.trim()
                  ? user.display_name
                  : user.first_name?.trim() || user.last_name?.trim()
                    ? `${user.first_name} ${user.last_name}`
                    : "-"}
              </p>
              {displayCount && (
                <div className={countStyles}>{callCounts.me}</div>
              )}
            </div>
          );
        default:
          return null;
      }
    },
    [user, callCounts],
  );

  const getFilterButtonLabel = useCallback(() => {
    if (isFilterByMentions) {
      return getOptionLabel("mentions");
    }
    if (isFilterByAssignedToCurrentUser) {
      return getOptionLabel("user");
    }
    if (filterByTeamId) {
      const team = teams.find((team) => team.doctor_team_id === filterByTeamId);
      return (
        <div className={optionStyles}>
          {getTeamIcon(team?.team_icon, "20", "#121212")}
          <p className={labelStyles}>{team?.name.trim() || "-"}</p>
        </div>
      );
    }
    return getOptionLabel("phone_number");
  }, [
    isFilterByMentions,
    isFilterByAssignedToCurrentUser,
    filterByTeamId,
    teams,
    getOptionLabel,
  ]);

  const resetFilters = () => {
    dispatch(setIsFilterByMentions(false));
    dispatch(setIsFilterByAssignedToCurrentUser(false));
    dispatch(setFilterByTeamId(null));
  };

  const handleFilterByAssignedToCurrentUser = () => {
    dispatch(setIsFilterByMentions(false));
    dispatch(setIsFilterByAssignedToCurrentUser(true));
    dispatch(setFilterByTeamId(null));
  };

  const handleFilterByTeam = (team: Team) => {
    dispatch(setFilterByTeamId(team.doctor_team_id));
    dispatch(setIsFilterByMentions(false));
    dispatch(setIsFilterByAssignedToCurrentUser(false));
  };

  const sortedTeams = teams
    ?.map((team) => ({
      ...team,
      display: team.name?.trim() || "-",
    }))
    .sort((a, b) => a.display.localeCompare(b.display));

  return (
    <FilterButton
      buttonClassName="bg-white text-sm font-semibold p-2 rounded-md border border-gray-foreground"
      dropdownClassName="p-2"
      width="230px"
      mobileHeight={600}
      label={getFilterButtonLabel()}
      closeOnSelect
      maxHeight="455px"
    >
      <div className="w-full flex flex-col">
        <button
          type="button"
          onClick={resetFilters}
          className={`${optionButtonStyles} ${!isFiltersApplied ? activeOptionButtonStyles : ""}`}
        >
          {getOptionLabel("phone_number", !isFiltersApplied, canShowCounts)}
        </button>
        <button
          type="button"
          onClick={handleFilterByAssignedToCurrentUser}
          className={`${optionButtonStyles} ${isFilterByAssignedToCurrentUser ? activeOptionButtonStyles : ""}`}
        >
          {getOptionLabel(
            "user",
            isFilterByAssignedToCurrentUser,
            canShowCounts,
          )}
        </button>

        {sortedTeams?.length > 0 &&
          sortedTeams?.map((team) => (
            <button
              key={team.doctor_team_id}
              type="button"
              onClick={() => handleFilterByTeam(team)}
              className={`${optionButtonStyles}
              ${filterByTeamId === team.doctor_team_id ? activeOptionButtonStyles : ""}`}
            >
              <div className={optionStyles}>
                {getTeamIcon(
                  team.team_icon,
                  "20",
                  filterByTeamId === team.doctor_team_id
                    ? "#2970FF"
                    : "#121212",
                )}
                <p className={labelStyles}>{team.name?.trim() || "-"}</p>
                {canShowCounts && (
                  <div className={countStyles}>
                    {callCounts.teams?.[team.doctor_team_id]}
                  </div>
                )}
              </div>
            </button>
          ))}
      </div>
    </FilterButton>
  );
};

export default MentionsDropdown;
