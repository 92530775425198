import { ReactComponent as DeleteIcon } from "@assets/icons/trash-redesign.svg";
import ActionsButton from "../../../Basic/ActionsButton";
import AvatarStack from "../../../Basic/AvatarStack";
import TeamIconButton from "./TeamIconButton";

const TeamListItem = ({ team, onClick, onClickActions, onDelete }) => {
  return (
    <div className="p-4 rounded-xl space-y-2 bg-white" onClick={onClick}>
      <div className="flex items-center justify-between space-x-2">
        <div className="flex items-center space-x-3">
          <TeamIconButton team={team} />
          <p className="text-base font-semibold truncate">@{team.name}</p>
        </div>

        <ActionsButton
          onClick={onClickActions}
          actions={[
            {
              icon: <DeleteIcon width="20" height="20" className="flex-none" />,
              label: "Delete",
              onClick: onDelete,
            },
          ]}
        />
      </div>

      <div className="flex flex-col space-y-1 font-medium truncate">
        <p className="text-xs text-tertiary">Members</p>
        {team.members.length > 0 ? (
          <AvatarStack
            users={team.members}
            length={4}
            totalNumber={team.members ? team.members.length : 0}
          />
        ) : (
          <p>-</p>
        )}
      </div>
    </div>
  );
};

export default TeamListItem;
