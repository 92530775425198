import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../../store";
import { LOGOUT } from "./types";

export type NoteTemplateSection = {
  note_template_section_id: number;
  note_template_id: number;
  name: string;
  detail_level: string;
  section_style: string;
  subsections: string[];
  has_subsections: boolean;
  hide_by_default: boolean;
  split_by_problem: boolean;
  include_differential: boolean;
};

export interface NoteTemplate {
  note_template_id: number;
  name: string;
  title: string;
  content: string;
  sections: NoteTemplateSection[];
  is_custom: boolean;
  is_hidden: boolean;
  note_language: string;
  show_icd10_codes_suggestions: boolean;
  custom_instructions: string;
}

export interface NoteTemplateState {
  isNoteTemplateLoading: boolean;
  noteTemplates: NoteTemplate[];
  defaultProviderNoteTemplate: NoteTemplate | null;
  defaultClinicNoteTemplate: NoteTemplate | null;
  selectedNoteTemplate: NoteTemplate | null;
}

const initialState: NoteTemplateState = {
  isNoteTemplateLoading: false,
  noteTemplates: [],
  defaultProviderNoteTemplate: null,
  defaultClinicNoteTemplate: null,
  selectedNoteTemplate: null,
};

export const noteTemplateSlice = createSlice({
  name: "noteTemplate",
  initialState,
  reducers: {
    setNoteTemplatesLoading: (state, action: PayloadAction<boolean>) => {
      state.isNoteTemplateLoading = action.payload;
    },
    setNoteTemplates: (state, action: PayloadAction<NoteTemplate[]>) => {
      state.noteTemplates = action.payload;
    },
    setDefaultProviderNoteTemplate: (
      state,
      action: PayloadAction<NoteTemplate>,
    ) => {
      state.defaultProviderNoteTemplate = action.payload;
    },
    setDefaultClinicNoteTemplate: (
      state,
      action: PayloadAction<NoteTemplate>,
    ) => {
      state.defaultClinicNoteTemplate = action.payload;
    },
    setSelectedNoteTemplate: (state, action: PayloadAction<NoteTemplate>) => {
      state.selectedNoteTemplate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LOGOUT, () => {
      return { ...initialState };
    });
  },
});

export const {
  setNoteTemplates,
  setDefaultProviderNoteTemplate,
  setDefaultClinicNoteTemplate,
  setSelectedNoteTemplate,
} = noteTemplateSlice.actions;

export const selectNoteTemplateState = (state: RootState) => state.noteTemplate;

export const selectSelectedNoteTemplate = createSelector(
  selectNoteTemplateState,
  (state) => state.selectedNoteTemplate,
);

export const selectNoteTemplates = createSelector(
  selectNoteTemplateState,
  (state) => state.noteTemplates,
);

export const selectDefaultProviderNoteTemplate = createSelector(
  selectNoteTemplateState,
  (state) => state?.defaultProviderNoteTemplate,
);

export const selectDefaultClinicNoteTemplate = createSelector(
  selectNoteTemplateState,
  (state) => state?.defaultClinicNoteTemplate,
);
