import { ReactComponent as TrashIcon } from "@assets/icons/trash.svg";
import Button from "../Basic/Button";
import Modal from "../Basic/Modal";

const DeleteModal = ({ onClose, onSubmit, patients }) => {
  return (
    <Modal>
      <div className="w-88">
        <div className="flex justify-between mb-4">
          <div className="w-12 h-12 rounded-full flex items-center justify-center bg-red-100 border-8 border-red-50">
            <TrashIcon stroke="#D92D20" width="20" height="20" />
          </div>
        </div>

        <p className="text-base md:text-lg font-semibold text-gray-900 mb-1">
          Delete {patients.length > 1 ? "Patients" : "Patient"}
        </p>
        <p className="text-xs md:text-sm font-normal text-slate-600 mb-2">
          Are you sure you want to delete
          {patients.length > 1 ? " these patients" : " this patient"}? This
          action cannot be undone.
        </p>

        <div className="mb-4 text-sm border rounded-lg divide-y max-h-40 overflow-y-auto scrollbar">
          {patients.map((patient) => (
            <div key={patient.patient_id} className="p-2">
              {patient.preferred_name
                ? patient.preferred_name
                : patient.first_name}{" "}
              {patient.last_name}
            </div>
          ))}
        </div>

        <div className="grid grid-cols-2 gap-3">
          <Button
            variant="gray"
            className="w-ful font-semibold h-11"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="red-filled"
            className="w-full font-semibold h-11"
            onClick={() => {
              onSubmit();
              onClose();
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
