import { ReactComponent as CloseIcon } from "@assets/icons/close.svg";
import { ReactComponent as CopyIcon } from "@assets/icons/copy-corner.svg";
import { ReactComponent as EditIcon } from "@assets/icons/edit2.svg";
import { ReactComponent as PlusIcon } from "@assets/icons/plus-small.svg";
import { ReactComponent as SearchIcon } from "@assets/icons/search.svg";
import { ReactComponent as TrashIcon } from "@assets/icons/trash-tr.svg";
import { debounce } from "@mui/material/utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectInputText } from "../../helpers/helpers";
import {
  createTemplate,
  deleteTemplate,
  getTemplates,
  updateTemplate,
} from "../../store/template/thunks";
import Button from "../Basic/Button";
import Input from "../Basic/Input";
import Loader from "../Basic/Loader";
import Modal from "../Basic/Modal";
import TextareaAutosizeForm from "../Basic/TextareaAutosizeForm";
import TextareaOrderedLines from "../Basic/TextareaOrderedLines";
import DeleteModal from "./DeleteModal";
import TemplateItem from "./TemplateItem";

const TemplateModal = ({ onClose, onUseTemplate }) => {
  const { templates, loader } = useSelector((state) => state.template);
  const [filteredTemplates, setFilteredTemplates] = useState(templates);
  const [searchValue, setSearchValue] = useState("");
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [clickedTemplate, setClickedTemplate] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [mode, setMode] = useState("");
  const dispatch = useDispatch();
  const templateListRef = useRef();
  const selectedTemplatesRef = useRef(selectedTemplates);
  const searchValueRef = useRef(searchValue);
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm();
  const characterLimit = 5000;

  useEffect(() => {
    dispatch(getTemplates());
  }, [dispatch]);

  useEffect(() => {
    selectedTemplatesRef.current = selectedTemplates;
  }, [selectedTemplates]);

  useEffect(() => {
    searchValueRef.current = searchValue;
  }, [searchValue]);

  useEffect(() => {
    filterTemplates(searchValueRef.current);

    const updatedTemplates = templates.filter((template) =>
      selectedTemplatesRef.current.some(
        (selectedTemplate) =>
          template.campaign_template_id ===
          selectedTemplate.campaign_template_id,
      ),
    );
    setSelectedTemplates(updatedTemplates);
  }, [templates]);

  useEffect(() => {
    if (selectedTemplates.length > 0) {
      setValue("conditions", combineConditions(selectedTemplates));
      setValue(
        "goals",
        selectedTemplates.map((template) => template.goals).join("\n\n"),
      );
    } else {
      setValue("conditions", "");
      setValue("goals", "");
    }
  }, [selectedTemplates, setValue]);

  const filterTemplates = useCallback(
    (searchName) => {
      setFilteredTemplates(
        templates.filter((t) =>
          t.name.toLowerCase().includes(searchName.trim().toLowerCase()),
        ),
      );
    },
    [templates],
  );

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        filterTemplates(value);
      }, 200),
    [filterTemplates],
  );

  const combineConditions = (templates) => {
    const conditions = templates
      .map((template) => template.conditions)
      .join("\n");
    const linesArray = conditions.split("\n");

    let lineNumber = 1;
    const updatedLines = linesArray.map((line) => {
      const numberMatch = line.match(/^\d+\./);
      if (!numberMatch) {
        const updatedLine = `${lineNumber}. ${line}`;
        lineNumber++;
        return updatedLine;
      }
      return line.replace(/^\d+\./, `${lineNumber++}.`);
    });

    return updatedLines.join("\n");
  };

  const onDuplicate = (template) => {
    const newName = generateNewTemplateName(template.name);

    dispatch(createTemplate(newName, template.conditions, template.goals));
  };

  const generateNewTemplateName = (originalName) => {
    let newName = originalName;
    let count = 1;

    while (templateNameExists(newName)) {
      newName = `${originalName} (${count})`;
      count++;
    }

    return newName;
  };

  const templateNameExists = (name) => {
    return templates.some((template) => template.name === name);
  };

  const trimCharacterLimit = (text, limit) => {
    return text.length > limit ? `${text.slice(0, limit - 3)}...` : text;
  };

  const onSubmit = (data) => {
    mode === "create"
      ? dispatch(
          createTemplate(data.name, data.conditions, data.goals, () => {
            setMode("");
          }),
        )
      : dispatch(
          updateTemplate(
            selectedTemplates[0].campaign_template_id,
            data.conditions,
            data.goals,
            () => setMode(""),
          ),
        );
  };

  return (
    <Modal>
      <div className="w-screen max-w-4xl h-screen-dynamic max-h-75vh-dynamic grid grid-rows-tab-layout gap-5">
        <div className="flex items-center justify-between">
          <p className="text-lg md:text-xl font-semibold">Manage Templates</p>
          <button type="button" onClick={onClose}>
            <CloseIcon stroke="#737373" width="24" height="24" />
          </button>
        </div>

        <div className="border rounded-lg flex w-full h-full overflow-hidden min-h-0 divide-x">
          <div className="w-full max-w-[280px] grid grid-rows-note-layout divide-y">
            <div className="grid grid-rows-tab-layout divide-y min-h-0 overflow-hidden">
              <div className="p-4">
                <div className="relative w-full">
                  <input
                    className="h-10 outline-none pl-10.5 pr-3.5 border w-full rounded-full"
                    placeholder="Search"
                    value={searchValue}
                    onClick={selectInputText}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      debouncedSearch(e.target.value);
                    }}
                  />
                  <SearchIcon
                    width="20"
                    height="20"
                    className="absolute top-2.5 left-3.5"
                    stroke="#888888"
                  />
                </div>
              </div>
              <div className="p-1.5 h-full min-h-0 overflow-hidden relative">
                <div
                  className="h-full min-h-0 overflow-y-auto scrollbar"
                  ref={templateListRef}
                >
                  {filteredTemplates.length > 0
                    ? filteredTemplates.map((template) => (
                        <TemplateItem
                          key={template.campaign_template_id}
                          template={template}
                          containerRef={templateListRef}
                          selectedItems={selectedTemplates}
                          setSelectedItems={setSelectedTemplates}
                          clickedTemplate={clickedTemplate}
                          setClickedTemplate={setClickedTemplate}
                          setOpenDeleteModal={setOpenDeleteModal}
                          onDuplicate={onDuplicate}
                          setMode={setMode}
                        />
                      ))
                    : !loader && <p className="p-2.5 text-sm">No templates</p>}
                </div>
                {loader && (
                  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
                    <Loader />
                  </div>
                )}
              </div>
            </div>
            <div className="py-2 px-4">
              <Button
                className="font-semibold w-full space-x-2"
                variant="secondary"
                onClick={() => {
                  setMode("create");
                  setSelectedTemplates([]);
                  reset();
                }}
              >
                <PlusIcon width="14" height="14" />
                <p>Create New</p>
              </Button>
            </div>
          </div>
          <div className="w-full grid grid-rows-note-layout divide-y overflow-hidden">
            <form
              id="template-form"
              className="p-1.5 overflow-hidden h-full min-h-0"
              onSubmit={handleSubmit(onSubmit)}
            >
              {(selectedTemplates.length > 0 || mode === "create") && (
                <div className="p-4.5 h-full min-h-0 overflow-y-auto scrollbar">
                  {mode === "create" && (
                    <div className="space-y-6 pb-6 border-b mb-6">
                      <p className="font-semibold text-sm">
                        Title<span className="text-red-600">*</span>
                      </p>
                      <Input
                        register={register}
                        name="name"
                        placeholder="Enter template title"
                        required="Title can't be empty"
                        validate={(value) => {
                          if (value.trim()) {
                            return true;
                          }
                          return "Title can't be empty";
                        }}
                        error={errors.name}
                        errorExclamation={false}
                      />
                    </div>
                  )}
                  <div className="space-y-6 pb-6 border-b mb-6">
                    <p
                      className={`font-semibold
                        ${mode === "create" ? "text-sm" : "text-lg md:text-xl"}`}
                    >
                      What conditions would you like to check in on?
                      <span className="text-red-600">*</span>
                    </p>
                    {mode === "edit" || mode === "create" ? (
                      <TextareaOrderedLines
                        register={register}
                        setValue={setValue}
                        name="conditions"
                        placeholder="Enter conditions"
                        required="Please provide at least one condition"
                        error={errors.conditions}
                        minRows={3}
                        initLength={
                          getValues("conditions")
                            ? getValues("conditions").length
                            : 0
                        }
                        maxLength={500}
                      />
                    ) : (
                      <div className="text-sm text-slate-700 whitespace-pre-wrap">
                        {trimCharacterLimit(
                          combineConditions(selectedTemplates),
                          characterLimit,
                        )}
                      </div>
                    )}
                  </div>
                  <div className="space-y-6 pb-6">
                    <p
                      className={`font-semibold
                        ${mode === "create" ? "text-sm" : "text-lg md:text-xl"}`}
                    >
                      What goals do you have for this patient’s care?
                    </p>
                    {mode === "edit" || mode === "create" ? (
                      <TextareaAutosizeForm
                        register={register}
                        name="goals"
                        placeholder="Enter goals"
                        minRows={3}
                        initLength={
                          getValues("goals") ? getValues("goals").length : 0
                        }
                        maxLength={5000}
                      />
                    ) : (
                      <div className="text-sm text-slate-700 whitespace-pre-wrap">
                        {trimCharacterLimit(
                          selectedTemplates
                            .map((template) => template.goals)
                            .join("\n\n"),
                          characterLimit,
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </form>
            <div className="py-2 px-4 flex items-center justify-between">
              <div className="flex items-center h-10">
                {mode !== "edit" &&
                  mode !== "create" &&
                  selectedTemplates.length === 1 && (
                    <>
                      <button
                        type="button"
                        className="h-full w-10 flex items-center justify-center"
                        onClick={() => onDuplicate(selectedTemplates[0])}
                      >
                        <CopyIcon width="20" height="20" />
                      </button>
                      <button
                        type="button"
                        className="h-full w-10 flex items-center justify-center"
                        onClick={() => setMode("edit")}
                      >
                        <EditIcon width="17" height="16" />
                      </button>
                      <button
                        type="button"
                        className="h-full w-10 flex items-center justify-center"
                        onClick={() => {
                          setOpenDeleteModal(true);
                          setClickedTemplate(selectedTemplates[0]);
                        }}
                      >
                        <TrashIcon width="18" height="19" />
                      </button>
                    </>
                  )}
              </div>

              {mode === "edit" || mode === "create" ? (
                <Button
                  form="template-form"
                  className="w-24 font-semibold"
                  type="submit"
                >
                  Save
                </Button>
              ) : (
                <Button
                  className="w-36 font-semibold"
                  onClick={() => {
                    onUseTemplate({
                      conditions: trimCharacterLimit(
                        combineConditions(selectedTemplates),
                        characterLimit,
                      ),
                      goals: trimCharacterLimit(
                        selectedTemplates
                          .map((template) => template.goals)
                          .join("\n\n"),
                        characterLimit,
                      ),
                    });
                    onClose();
                  }}
                >
                  Use Template
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>

      {clickedTemplate && openDeleteModal && (
        <DeleteModal
          title="Delete Template"
          description={`Are you sure you want to delete ${clickedTemplate.name} template? This action cannot be undone.`}
          onClose={() => setOpenDeleteModal(false)}
          onSubmit={() =>
            dispatch(
              deleteTemplate(clickedTemplate.campaign_template_id, () => {
                setSelectedTemplates(
                  selectedTemplates.filter(
                    (template) =>
                      template.campaign_template_id !==
                      clickedTemplate.campaign_template_id,
                  ),
                );
                setClickedTemplate(null);
              }),
            )
          }
        />
      )}
    </Modal>
  );
};

export default TemplateModal;
