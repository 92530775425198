import { useSearchParams } from "react-router";

export const SCRIBE_SEARCH_PARAM = "scribeId";

const useScribeSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedScribeId = searchParams.get("scribeId");

  const setSelectedScribeId = (scribeId: string | null) => {
    if (scribeId === selectedScribeId) {
      return;
    }

    const newParams = new URLSearchParams(searchParams);

    if (scribeId === null) {
      newParams.delete(SCRIBE_SEARCH_PARAM);
    } else {
      newParams.set(SCRIBE_SEARCH_PARAM, scribeId);
    }

    setSearchParams(newParams, {
      replace: true,
    });
  };

  return { selectedScribeId, setSelectedScribeId };
};

export default useScribeSearchParams;
