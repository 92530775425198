import { ReactComponent as ClockIcon } from "@assets/icons/clock.svg";
import { useEffect } from "react";
import { getFormattedTimeColon } from "../../helpers/helpers";
import { useAppSelector } from "../../store";
import type { BillingPatient } from "../../store/billingMetrics/billingMetricsReducer";
import { selectUser } from "../../store/user/userReducer";
import Loader from "../Basic/Loader";
import type { FixLater } from "../IndependentScribe/store/scribeSlice";
import { TimeCptItem } from "./TimeCptItem";

interface TimeEditingProps {
  billingType: string;
  billingPatient: BillingPatient;
  combinedStaffData: FixLater;
  additionalTime: number;
  setAdditionalTime: (time: number) => void;
  breakdownData: FixLater;
  setBreakdownData: (data: FixLater) => void;
  staffAdjusted: FixLater;
  setStaffAdjusted: (data: FixLater) => void;
  editableAdjustments: boolean;
  editMode: boolean;
  onEditTime: () => void;
  onClickNote: () => void;
  className?: string;
  adjustmentsToRemove: FixLater;
  setAdjustmentsToRemove: (data: FixLater) => void;
}

const TimeEditing = ({
  billingType,
  billingPatient,
  setAdditionalTime,
  staffAdjusted,
  className = "",
}: TimeEditingProps) => {
  const user = useAppSelector(selectUser);
  const time = "00:00";

  useEffect(() => {
    const [minutes, seconds] = time.split(":").map(Number);
    const sec = minutes * 60 + seconds;
    setAdditionalTime(sec);
  }, [setAdditionalTime]);

  useEffect(() => {
    if (billingPatient?.staff) {
      const grouped = {};

      billingPatient.staff.forEach((item) => {
        const { doctor_id, first_name, last_name, seconds } = item;
        if (item.reason) {
          if (!grouped[doctor_id]) {
            grouped[doctor_id] = {
              doctor_id: doctor_id,
              first_name: first_name,
              last_name: last_name,
              items: [],
              total: 0,
            };
          }
          grouped[doctor_id].items.push(item);
          grouped[doctor_id].total += seconds;
        }
      });

      staffAdjusted.forEach((item) => {
        const {
          doctorId,
          secondsAdjusted,
          reason,
          monthly_bill_staff_adjustment_id,
        } = item;
        if (!grouped[doctorId]) {
          grouped[doctorId] = {
            doctor_id: doctorId,
            first_name: user.first_name,
            last_name: user.last_name,
            items: [],
            total: 0,
          };
        }
        grouped[doctorId].items.push({
          reason,
          seconds: secondsAdjusted,
          monthly_bill_staff_adjustment_id: monthly_bill_staff_adjustment_id,
          doctor_id: doctorId,
        });
        grouped[doctorId].total += secondsAdjusted;
      });
    }
  }, [billingPatient?.staff, user, staffAdjusted]);

  const getTotalWithAdditionalSeconds = () => {
    return billingPatient.totalSeconds;
  };

  return (
    <div className={`grid h-full w-full ${className}`}>
      {billingPatient !== null ? (
        <div>
          <div className="space-y-3 rounded-lg bg-gray-110 p-4 border border-gray-foreground mb-4 text-sm">
            <div className="flex items-center justify-between">
              <p className="text-base font-semibold">Total Time</p>
              <div
                className="text-sm font-semibold h-7 w-fit px-2.5 flex items-center justify-center
                        space-x-1.5 bg-white rounded-full"
              >
                <ClockIcon width="16" height="16" stroke="#121212" />
                <p>{getFormattedTimeColon(getTotalWithAdditionalSeconds())}</p>
              </div>
            </div>

            {billingType === "CCM" && (
              <div className="space-y-3">
                <TimeCptItem
                  cptMinutes={20}
                  totalSeconds={getTotalWithAdditionalSeconds()}
                  campaignType={billingType}
                />
                <TimeCptItem
                  cptMinutes={40}
                  totalSeconds={getTotalWithAdditionalSeconds()}
                  campaignType={billingType}
                />
                <TimeCptItem
                  cptMinutes={60}
                  totalSeconds={getTotalWithAdditionalSeconds()}
                  campaignType={billingType}
                />
              </div>
            )}
            {billingType === "PCM" && (
              <div className="space-y-3">
                <TimeCptItem
                  cptMinutes={30}
                  totalSeconds={getTotalWithAdditionalSeconds()}
                  campaignType={billingType}
                />
                <TimeCptItem
                  cptMinutes={60}
                  totalSeconds={getTotalWithAdditionalSeconds()}
                  campaignType={billingType}
                />
                <TimeCptItem
                  cptMinutes={90}
                  totalSeconds={getTotalWithAdditionalSeconds()}
                  campaignType={billingType}
                />
              </div>
            )}
            {billingType === "BHI" && (
              <TimeCptItem
                cptMinutes={20}
                totalSeconds={getTotalWithAdditionalSeconds()}
                campaignType={billingType}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="w-full flex items-center justify-center">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default TimeEditing;
