import { ReactComponent as Encounters } from "@assets/encounters.svg";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { redirect } from "react-router";
import OnboardingForm from "../components/Login/OnboardingForm";
import { Pages } from "../helpers/constants";
import { useAppSelector } from "../store";
import { selectUser } from "../store/user/userReducer";

const Onboarding = () => {
  const user = useAppSelector(selectUser);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    if (user) {
      redirect(Pages.LOGIN);
    }
  }, [user]);

  return (
    <div className="bg-gray-background h-screen-dynamic w-full overflow-hidden overflow-y-auto scrollbar font-Mulish">
      <div
        className={`min-h-screen-dynamic relative overflow-y-auto scrollbar
      ${isDesktop ? "max-h-screen-dynamic" : ""}`}
      >
        <div
          className="flex flex-col justify-center min-h-screen-dynamic
            h-full py-6 px-5 gap-4 md:gap-8 max-w-[516px] mx-auto"
        >
          <Encounters className="mx-auto w-[115px] h-[120px] md:w-[140px] md:h-[148px]" />
          <OnboardingForm />
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
