import { ReactComponent as RemoveIcon } from "@assets/icons/close.svg";
import { ReactComponent as WandIcon } from "@assets/icons/magic-stick.svg";
import { useMediaQuery } from "react-responsive";
import ModalMobile from "../Basic/ModalMobile";
import HistoryTab from "../PatientProfile/History/HistoryTab";

const HistoryModal = ({ open, onClose, carePlan, patientHistory }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return isDesktop ? (
    open && (
      <div className="fixed h-screen-dynamic top-0 right-0 w-1/3 shadow-right-bar bg-white p-5 overflow-y-auto scrollbar">
        <div className="flex items-center justify-between mb-3">
          <p className="text-base font-semibold">History</p>
          <button type="button" onClick={onClose}>
            <RemoveIcon stroke="#667085" width="20" height="20" />
          </button>
        </div>

        {carePlan && (
          <div className="space-y-4 bg-gray-110 p-5 rounded-lg mb-3">
            <div className="flex items-center space-x-2">
              <p className="font-semibold text-base">Summary</p>
              <WandIcon width="18" height="18" stroke="#121212" />
            </div>
            <p className="text-sm font-medium text-tertiary">{carePlan}</p>
          </div>
        )}
        {patientHistory && <HistoryTab patientInfo={patientHistory} />}
      </div>
    )
  ) : (
    <ModalMobile open={open} header="History" onClickAway={onClose}>
      <div className="w-full mt-3">
        {carePlan && (
          <div className="space-y-4 bg-gray-110 p-5 rounded-lg mb-3">
            <div className="flex items-center space-x-2">
              <p className="font-semibold text-base">Summary</p>
              <WandIcon width="18" height="18" stroke="#121212" />
            </div>
            <p className="text-sm font-medium text-tertiary">{carePlan}</p>
          </div>
        )}
        {patientHistory && <HistoryTab patientInfo={patientHistory} />}
      </div>
    </ModalMobile>
  );
};

export default HistoryModal;
