import LocalStorageService from "../../services/LocalStorageService";
import {
  LOGOUT,
  SET_CALL_EVENT,
  SET_DEVICE_STATUS,
  SET_OPEN_CALL_MODAL,
  SET_OPEN_CALL_OPTIONS_MODAL,
  SET_SCRIBE_CALLS,
  SET_TWILIO_TOKEN,
} from "../types";

const storageService = new LocalStorageService();

const init = {
  twilioToken: null,
  deviceStatus: "offline",
  openCallOptionsModal: storageService.getItem("openCallOptionsModal") ?? true,
  openCallModal: false,
  callEvent: null,
  scribeCalls: true,
};

export default function voiceRecorderReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      return { ...init };

    case SET_TWILIO_TOKEN:
      if (state.twilioToken === payload.twilioToken) {
        return state;
      }

      return { ...state, twilioToken: payload.twilioToken };
    case SET_DEVICE_STATUS:
      if (state.deviceStatus === payload.deviceStatus) {
        return state;
      }

      return { ...state, deviceStatus: payload.deviceStatus };
    case SET_OPEN_CALL_OPTIONS_MODAL:
      storageService.setItem(
        "openCallOptionsModal",
        payload.openCallOptionsModal,
      );
      return { ...state, openCallOptionsModal: payload.openCallOptionsModal };
    case SET_OPEN_CALL_MODAL:
      return { ...state, openCallModal: payload.openCallModal };
    case SET_CALL_EVENT:
      return { ...state, callEvent: payload.callEvent };
    case SET_SCRIBE_CALLS:
      return { ...state, scribeCalls: payload.scribeCalls };
    default:
      return state;
  }
}
