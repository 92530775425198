import {
  LOGOUT,
  SET_HIDE_MOBILE_NAVBAR,
  SET_IS_DATE_PICKER_OPEN,
  SET_IS_MODAL_OPEN,
} from "../types";

const init = {
  isModalOpen: false,
  isDatePickerOpen: false,
  hideMobileNavbar: false,
};

export default function uiReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      return { ...init };
    case SET_IS_MODAL_OPEN:
      return { ...state, isModalOpen: payload.isModalOpen };
    case SET_IS_DATE_PICKER_OPEN:
      return { ...state, isDatePickerOpen: payload.isDatePickerOpen };
    case SET_HIDE_MOBILE_NAVBAR:
      return { ...state, hideMobileNavbar: payload.hideMobileNavbar };
    default:
      return state;
  }
}
