import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Pages, patientProfileTabs } from "../../../helpers/constants";
import { useAppSelector } from "../../../store";
import {
  setEnrollFieldsAction,
  setSelectedEnrollCampaignAction,
  setSelectedEnrollPatientsAction,
  setSelectedStartDateAction,
} from "../../../store/campaign/actions";
import {
  setOpenEditInfoModalAction,
  setSelectedPatientProfileTabAction,
} from "../../../store/patient/actions";
import { setSelectedCareManagementTabAction } from "../../../store/user/actions";
import { selectUser } from "../../../store/user/userReducer";

const getDefaultFrequency = (upcomingMessage, selectedEnrollCampaign) => {
  const campaign = upcomingMessage
    ? upcomingMessage.campaign
    : selectedEnrollCampaign;
  if (campaign.default_frequency) {
    return campaign.default_frequency;
  }
  if (campaign?.campaign_type.includes("Outreach")) {
    return "monthly";
  }
  return "once";
};

const getDefaultStartDate = (upcomingMessage, selectedEnrollCampaign) => {
  const campaign = upcomingMessage
    ? upcomingMessage.campaign
    : selectedEnrollCampaign;

  if (campaign?.default_time_to_send) {
    switch (campaign.default_time_to_send) {
      case "now":
        return "now";
      case "tomorrow":
        return dayjs().add(1, "day");
      case "next_week":
        return dayjs().add(1, "week");
      case "next_month":
        return dayjs().add(1, "month");
      default:
        return dayjs().add(1, "day");
    }
  }
  if (campaign?.campaign_type === "HPI") {
    return "now";
  }
  return dayjs().add(1, "day");
};

export const useNewEnrollmentLogic = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedPatientInfo, selectedPatientEnrollments } = useSelector(
    (state) => state.patient,
  );
  const { selectedEnrollCampaign, selectedEnrollPatients, enrollFields } =
    useSelector((state) => state.campaign);

  const campaignEnrollFields =
    enrollFields[selectedEnrollCampaign?.campaign_id] || {};

  const handleNewEnrollment = () => {
    if (!selectedPatientInfo.phone_number) {
      dispatch(
        setSelectedPatientProfileTabAction(patientProfileTabs.INFORMATION),
      );
      if (!user.customer.has_ehr_data) {
        dispatch(setOpenEditInfoModalAction(true));
      }
      return;
    }

    const upcomingMessage =
      selectedPatientEnrollments?.length > 0
        ? selectedPatientEnrollments[0]
        : null;

    if (upcomingMessage) {
      dispatch(setSelectedEnrollCampaignAction(upcomingMessage.campaign));
    }

    const defaultDateString =
      getDefaultStartDate(upcomingMessage) === "now"
        ? "now"
        : getDefaultStartDate(upcomingMessage).toDate().toLocaleString("en-us");
    const selectedDate = upcomingMessage
      ? upcomingMessage.next_checkin
        ? new Date(
            `${upcomingMessage.next_checkin.replace(/-/g, "/")} GMT+0`,
          ).toLocaleString("en-us")
        : defaultDateString
      : campaignEnrollFields.start || defaultDateString;

    dispatch(
      setEnrollFieldsAction({
        ...enrollFields,
        [upcomingMessage
          ? upcomingMessage.campaign.campaign_id
          : selectedEnrollCampaign.campaign_id]: {
          chronicConditions: upcomingMessage
            ? upcomingMessage.outreach_focus || selectedPatientInfo.conditions
            : campaignEnrollFields.chronicConditions || "",
          goals: upcomingMessage
            ? upcomingMessage.outreach_goals || selectedPatientInfo.goals
            : campaignEnrollFields.goals || "",
          pertinentMedicalHistory: upcomingMessage
            ? upcomingMessage.ehr_information ||
              selectedPatientInfo.past_medical_history
            : campaignEnrollFields.pertinentMedicalHistory || "",
          frequency: upcomingMessage
            ? upcomingMessage.frequency
              ? upcomingMessage.frequency
              : getDefaultFrequency(upcomingMessage, selectedEnrollCampaign)
            : campaignEnrollFields.frequency ||
              getDefaultFrequency(upcomingMessage, selectedEnrollCampaign),
          start: selectedDate,
          doctorId: upcomingMessage ? upcomingMessage.patient.doctor_id : "",
          aiChat: upcomingMessage ? !!upcomingMessage.patient.ai_chat : true,
        },
      }),
    );
    dispatch(setSelectedStartDateAction(selectedDate));

    dispatch(
      setSelectedEnrollPatientsAction({
        ...selectedEnrollPatients,
        [upcomingMessage
          ? upcomingMessage.campaign.campaign_id
          : selectedEnrollCampaign.campaign_id]: [selectedPatientInfo],
      }),
    );

    dispatch(setSelectedCareManagementTabAction("Enroll"));
    navigate(Pages.ENROLL);
  };

  return { handleNewEnrollment };
};
