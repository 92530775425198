import emptyCompletedSrc from "@assets/empty-threads-completed.svg";
import emptyReviewSrc from "@assets/empty-threads.svg";

interface EmptyInboxTableProps {
  completed?: boolean;
}

const EmptyInboxTable = ({ completed }: EmptyInboxTableProps) => {
  return (
    <div className="flex flex-col items-center justify-center h-full md:pt-12.5 md:-mt-12.5">
      <img
        src={completed ? emptyCompletedSrc : emptyReviewSrc}
        alt="No Threads"
        width="440"
        height="200"
      />
      <p className="text-base md:text-xl text-center font-semibold">
        {completed ? "No completed threads" : "No threads for review"}
      </p>
    </div>
  );
};

export default EmptyInboxTable;
