import { ReactComponent as CheckIcon } from "@assets/icons/check-circle-completed.svg";
import { useAppSelector } from "../../../store";
import { getCallsCompletedTmp } from "../../../store/call/callSlice";
import {
  CallStatus,
  CallStatusTab,
  type InboxItem,
  type InboxListItem,
} from "../../../store/call/interfaces";
import CallsTableRowsSkeleton from "../../Skeletons/CallsTableRowsSkeleton";
import { useCallManagement } from "../hooks/useCallManagement";
import CallTableRow from "./CallTableRow";
import EmptyInboxTable from "./EmptyInboxTable";
import ReasonFilter from "./ReasonFilter";

const tableRowClasses = "w-full items-center grid grid-cols-12 gap-4";
const statusColumnClasses = "col-span-1";
const activityLogColumnClasses = "col-span-3 truncate";
const callerColumnClasses = "col-span-3 truncate";
const reasonColumnClasses = "col-span-5 truncate";

interface CallsGridTableProps {
  handleClickCall: (call: InboxListItem) => void;
  handleCompleteCall: (call: InboxListItem) => void;
}

const CallsGridTable = ({
  handleClickCall,
  handleCompleteCall,
}: CallsGridTableProps) => {
  const {
    data: { inbox: calls = [] } = {},
    isLoading,
    filters,
    selectedConversationId,
  } = useCallManagement();

  const callsCompletedTmp = useAppSelector(getCallsCompletedTmp);

  const showLoading =
    isLoading ||
    (filters.status === CallStatusTab.Completed &&
      calls.length > 0 &&
      calls[0]?.items[0]?.data?.status !== CallStatus.Completed) ||
    (filters.status === CallStatusTab.Pending &&
      calls.length > 0 &&
      calls[0]?.items[0]?.data?.status !== CallStatus.Pending);

  const isInboxListItemSelected = (
    selectedItemPatientId: string | null,
    itemPatientId: number,
  ): boolean => {
    return selectedItemPatientId === itemPatientId.toString();
  };

  const calculateExpandedHeight = (items: InboxItem[]): string => {
    const ROW_HEIGHT = 64;
    const ROW_GAP = 12;
    return `${ROW_HEIGHT * (items.length - 1) + ROW_GAP * (items.length - 2)}px`;
  };

  return (
    <div className="relative min-w-134 h-full">
      <div
        className={`sticky z-10 top-0 left-0 w-full bg-gray-background px-4 py-2.5 text-sm font-semibold text-tertiary
          ${tableRowClasses}`}
      >
        <CheckIcon
          width="30"
          height="30"
          fill={
            filters.status === CallStatusTab.Completed ? "#2970FF" : "#D0D5DD"
          }
          className={`flex-none ${statusColumnClasses}`}
        />
        {/*<ActivityLogFilter className={activityLogColumnClasses} />*/}
        <div className={activityLogColumnClasses}>Activity Log</div>
        <div className={callerColumnClasses}>Caller</div>
        <ReasonFilter className={reasonColumnClasses} />
      </div>

      {showLoading ? (
        <CallsTableRowsSkeleton />
      ) : calls.length > 0 ? (
        calls.map((call: InboxListItem) => (
          <div
            key={call.patient.patient_id}
            className={`overflow-hidden
              ${
                callsCompletedTmp.pending.includes(call.patient.patient_id) ||
                callsCompletedTmp.completed.includes(call.patient.patient_id)
                  ? "h-0 mt-0"
                  : "mt-3"
              }`}
          >
            <CallTableRow
              call={call.items[0]}
              patient={call.patient}
              numOfSubItems={call.items.length}
              isSelected={isInboxListItemSelected(
                selectedConversationId,
                call.patient.conversation_id,
              )}
              handleClickCall={() => handleClickCall(call)}
              handleCompleteCall={() => handleCompleteCall(call)}
            />

            <div
              className={`w-full space-y-3 transition-all overflow-hidden
                ${
                  call.items.length > 1 &&
                  isInboxListItemSelected(
                    selectedConversationId,
                    call.patient.conversation_id,
                  )
                    ? "mt-3"
                    : "mt-0"
                }`}
              style={{
                height: isInboxListItemSelected(
                  selectedConversationId,
                  call.patient.conversation_id,
                )
                  ? calculateExpandedHeight(call.items)
                  : "0",
              }}
            >
              {call.items.map((item: InboxItem, index: number) => {
                if (index > 0) {
                  return (
                    <CallTableRow
                      key={`${call.patient.patient_id}_${index}`}
                      call={item}
                      patient={call.patient}
                      numOfSubItems={0}
                      isSelected={isInboxListItemSelected(
                        selectedConversationId,
                        call.patient.conversation_id,
                      )}
                      handleClickCall={() => handleClickCall(call)}
                    />
                  );
                }
              })}
            </div>
          </div>
        ))
      ) : (
        <EmptyInboxTable
          completed={filters.status === CallStatusTab.Completed}
        />
      )}
    </div>
  );
};

export default CallsGridTable;
