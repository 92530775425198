import { ReactComponent as SortIcon } from "@assets/icons/arrows-up-down.svg";
import { useLayoutEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { useTable } from "react-table";
import {
  Pages,
  patientPanelTabs,
  patientProfileTabs,
  rowsPerPage,
} from "../../helpers/constants";
import {
  setCurrPageAction,
  setTableScrollPositionAction,
} from "../../store/billingMetrics/actions";
import { getBillingPatients } from "../../store/billingMetrics/thunks";
import {
  setSelectedPatientPanelTabAction,
  setSelectedPatientProfileTabAction,
} from "../../store/patient/actions";
import { getPatientInfo } from "../../store/patient/thunks";
import { setOpenCallOptionsModalAction } from "../../store/voiceRecorder/actions";
import LoaderDots from "../Basic/LoaderDots";
import PatientListItem from "./PatientListItem";

const MetricsTableData = ({ columns, data, setSelectedNote, onSort }) => {
  const {
    billingPatients,
    tableScrollPosition,
    currentDateStamp,
    currPage,
    selectedBillingType,
    selectedCptCode,
    numberOfFilteredPatients,
    sortedBy,
    sortingDirection,
    billingSearchValue,
  } = useSelector((state) => state.billingMetrics);

  const isDesktop = useMediaQuery({ minWidth: 768 });
  const tableRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data,
    });

  useLayoutEffect(() => {
    const ref = tableRef.current;
    if (ref) {
      ref.scroll(0, tableScrollPosition);
    }
    return () => {
      if (ref) {
        dispatch(setTableScrollPositionAction(ref.scrollTop));
      }
    };
  }, [dispatch, tableScrollPosition]);

  const onNext = () => {
    dispatch(
      getBillingPatients(
        currentDateStamp,
        rowsPerPage * (currPage + 1),
        billingSearchValue,
        selectedBillingType,
        selectedCptCode,
        sortedBy,
        sortingDirection,
        () => dispatch(setCurrPageAction(currPage + 1)),
      ),
    );
  };

  const navigateToProfile = (patientId) => {
    dispatch(
      getPatientInfo(patientId, () => {
        navigate(`${Pages.PATIENTS}/${patientId}`);
        if (isDesktop) {
          dispatch(setOpenCallOptionsModalAction(true));
        }
      }),
    );
    dispatch(
      setSelectedPatientProfileTabAction(patientProfileTabs.ENROLLMENTS),
    );
    dispatch(setSelectedPatientPanelTabAction(patientPanelTabs.MESSAGE));
  };

  return (
    <div className="overflow-auto h-full w-full scrollbar pr-1" ref={tableRef}>
      <InfiniteScroll
        pageStart={0}
        loadMore={onNext}
        hasMore={rows.length < numberOfFilteredPatients}
        useWindow={false}
        loader={
          <div key={0} className="w-full h-10 flex items-center justify-center">
            <LoaderDots />
          </div>
        }
      >
        <div className="min-w-fit w-full h-fit">
          {isDesktop ? (
            <table {...getTableProps()} className="min-w-full">
              <thead className="bg-gray-background sticky top-0 z-[9]">
                {headerGroups.map((headerGroup) => {
                  const { key, ...restHeaderGroupProps } =
                    headerGroup.getHeaderGroupProps();

                  return (
                    <tr key={key} {...restHeaderGroupProps}>
                      {headerGroup.headers.map((column, index) => {
                        const { key, ...restHeaderProps } =
                          column.getHeaderProps();

                        return (
                          <th
                            {...restHeaderProps}
                            key={key}
                            scope="col"
                            className="group h-11 text-sm font-semibold text-tertiary p-0"
                          >
                            <div
                              className={`flex items-center space-x-2 h-full w-full py-3 px-3 xl:px-4 truncate
                                ${index === 0 ? "" : "justify-center"}
                                ${column.isSortable ? "cursor-pointer" : ""}`}
                              onClick={() => {
                                if (column.isSortable) {
                                  if (sortedBy !== column.id) {
                                    onSort(column.id, "asc");
                                  } else if (
                                    sortedBy === column.id &&
                                    sortingDirection === "asc"
                                  ) {
                                    onSort(column.id, "desc");
                                  } else {
                                    onSort(null, null);
                                  }
                                }
                              }}
                            >
                              <div className="truncate">
                                {column.render("Header")}
                              </div>
                              {column.isSortable && (
                                <SortIcon
                                  width="16"
                                  height="16"
                                  stroke={
                                    sortedBy === column.id
                                      ? "#2970FF"
                                      : "#667085"
                                  }
                                />
                              )}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row?.getRowProps()}
                        key={row?.getRowProps().key || i}
                        className="cursor-pointer"
                        onClick={() =>
                          navigateToProfile(row.original.patientId)
                        }
                      >
                        {row.cells.map((cell, index) => {
                          const { key, ...restCellProps } = cell.getCellProps();

                          return (
                            <td
                              {...restCellProps}
                              key={key}
                              className="whitespace-nowrap text-zinc-700 font-semibold text-sm py-1.5 px-0"
                            >
                              <div
                                className={`bg-white px-3 xl:px-4 h-14 w-full flex items-center
                                  ${index === 0 ? "rounded-l-lg" : "justify-center"}
                                  ${index === row.cells.length - 1 ? "rounded-r-lg" : ""}`}
                              >
                                {cell.render("Cell")}
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={columns.length}
                      className="text-zinc-700 font-semibold text-sm py-1.5 px-0"
                    >
                      <div className="bg-white px-3 xl:px-4 h-14 w-full flex items-center justify-center rounded-lg">
                        {billingSearchValue
                          ? "Patients not found"
                          : "No Patients Enrolled"}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : rows.length > 0 ? (
            <div className="space-y-2">
              {billingPatients?.length > 0 &&
                billingPatients.map((patient, index) => (
                  <PatientListItem
                    key={`${patient.patientId}_${index}`}
                    patient={patient}
                    setSelectedNote={setSelectedNote}
                    onClick={() => navigateToProfile(patient.patientId)}
                  />
                ))}
            </div>
          ) : (
            <div className="bg-white px-3 xl:px-4 h-14 w-full flex items-center justify-center rounded-lg my-1.5 text-sm">
              {billingSearchValue
                ? "Patients not found"
                : "No Patients Enrolled"}
            </div>
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default MetricsTableData;
