import type z from "zod";
import type {
  conversationSchema,
  messageContentSchema,
} from "../conversation/validationSchemas";

export enum CallScribeType {
  CCM = "ccm",
  Other = "other",
}

export enum CallStatus {
  Pending = "pending",
  Completed = "completed",
  Reviewed = "review",
}

export enum CallStatusTab {
  Pending = "review",
  Completed = "completed",
}

export enum CallDirection {
  Inbound = "inbound",
  Outbound = "outbound",
}

export enum CallCategory {
  MedicationRefill = "Medication Refill",
  Scheduling = "Scheduling",
  InsuranceBilling = "Billing/Insurance",
  SickNote = "Sick Note",
  TestResultInquiry = "Test Result Inquiry",
  ReferralRequests = "Referral Requests",
  MedicalQuestion = "Medical Question",
  TextMessage = "Text Message",
  InternalMessage = "Internal Message",
  Schedule = "Schedule",
  Other = "Other",
}

export enum SortDirection {
  Asc = "asc",
  Desc = "desc",
}

export enum InboxItemType {
  Call = "call",
  Text = "text",
  TextInternal = "text_internal",
}

export interface Call {
  assistant_call_id: number;
  date: string;
  direction: CallDirection | null | "";
  is_new_patient: boolean;
  is_urgent: boolean;
  reasons: CallCategory[];
  status: CallStatus;
  summary: string | null;
  time: number;
}

export interface InboxPatient {
  conversation_id: number;
  patient_birthdate: string;
  patient_full_name: string;
  patient_gender: string | null;
  patient_id: number;
  patient_npi: string | null;
  patient_phone_number: string | null;
  patient_preferred_name: string | null;
  patient_pronouns: string | null;
  patient_sex: string | null;
}

export interface InboxItem {
  data: Call;
  total: number;
  type: InboxItemType;
}

export interface InboxListItem {
  patient: InboxPatient;
  items: InboxItem[];
}

export interface CallCounts {
  hours_saved: number;
  incoming_calls: number;
  me: number;
  teams: Record<string, number> | null;
  total_completed: number;
  total_reviews: number;
}

export interface CallTags {
  is_new_user: boolean;
  is_urgent: boolean;
}

export interface CallNote {
  assistant_call_id: number;
  assistant_call_note_id: number;
  created_at: string;
  created_by: number;
  note: string;
  updated_at: string;
  updated_by: number;
}

export interface CallNotes {
  [assistant_call_id: number]: CallNote[];
}

export interface Doctor {
  doctor_id: number;
  first_name: string;
  last_name: string;
  display_name: string;
  profile_picture: string;
  preferred_name?: string;
  occupation?: string;
}

export enum MessageFrom {
  Patient = "patient",
  Doctor = "doctor",
  Note = "note",
}

export type ContentMessage = z.infer<typeof messageContentSchema>;

export interface AssignedUser {
  doctor_id: number;
  first_name: string;
  last_name: string;
  display_name: string;
  profile_picture: string;
}

export interface AssignedTeam {
  doctor_team_id: number;
  name: string;
  team_icon: string | null;
}

export type Conversation = z.infer<typeof conversationSchema>;

export interface CallState {
  calls: InboxListItem[] | null;
  callsCompletedTmp: number[];
  callsTotal: number;
  reviewTotal: number;
  callCounts: CallCounts;
  tableScrollPosition: number;
  selectedCall: InboxListItem | null;
  isMessageInputOpen: boolean;
  isFilterByMentions: boolean;
  isFilterByAssignedToCurrentUser: boolean;
  filterByTeamId: number | null;
}

export interface CallSearchParams {
  q?: string;
  categories?: CallCategory[];
  is_new_user?: boolean;
  is_urgent?: boolean;
  direction?: CallDirection | "";
  limit?: number;
  offset?: number;
  sort_direction?: SortDirection;
  has_mention?: 0 | 1;
  assigned_to_me?: 0 | 1;
  team_id?: number | string | null;
}
