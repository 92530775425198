import {
  SET_HIDE_MOBILE_NAVBAR,
  SET_IS_DATE_PICKER_OPEN,
  SET_IS_MODAL_OPEN,
} from "../types";

export function setIsModalOpen(isModalOpen) {
  return {
    type: SET_IS_MODAL_OPEN,
    payload: {
      isModalOpen,
    },
  };
}

export function setIsDatePickerOpen(isDatePickerOpen) {
  return {
    type: SET_IS_DATE_PICKER_OPEN,
    payload: {
      isDatePickerOpen,
    },
  };
}

export function setHideMobileNavbar(hideMobileNavbar) {
  return {
    type: SET_HIDE_MOBILE_NAVBAR,
    payload: {
      hideMobileNavbar,
    },
  };
}
