import { ReactComponent as CheckIcon } from "@assets/icons/check-circle.svg";
import { ReactComponent as CrossIcon } from "@assets/icons/cross-circle.svg";

const cptCodes = {
  CCM: [
    { codes: "99490", minutes: 20 },
    { codes: "99439", minutes: 40 },
    { codes: "99439", minutes: 60 },
  ],
  PCM: [
    { codes: "99426", minutes: 30 },
    { codes: "99427", minutes: 60 },
    { codes: "99427", minutes: 90 },
  ],
  BHI: [{ codes: "99484", minutes: 20 }],
};

export const TimeCptItem = ({ totalSeconds, cptMinutes, campaignType }) => {
  const currentCode =
    cptCodes[campaignType].find((code) => code.minutes === cptMinutes)?.codes ||
    "";

  const isTimeActive = (minutes) => {
    return totalSeconds >= minutes * 60;
  };

  return (
    <div
      className={`w-full flex items-center space-x-1 justify-between mb-0.5
        ${isTimeActive(cptMinutes) ? "text-system-green-dark" : "text-gray-400"}`}
    >
      <p className={`${isTimeActive(cptMinutes) ? "text-primary" : ""}`}>
        (CPT : {currentCode})
      </p>

      <div className="flex items-center justify-center space-x-1">
        {isTimeActive(cptMinutes) ? (
          <CheckIcon width="19" height="19" stroke="#009262" />
        ) : (
          <CrossIcon width="20" height="20" stroke="#A0A6B2" />
        )}
        <span>{cptMinutes} minutes</span>
      </div>
    </div>
  );
};
