import { ReactComponent as PencilIcon } from "@assets/icons/pencil.svg";
import userIconSrc from "@assets/icons/user-default.svg";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router";
import AvatarModal from "../../components/Settings/Profile/AvatarModal";
import {
  Pages,
  countryCodes,
  occupations,
  passwordValidationRegEx,
} from "../../helpers/constants";
import { getUserInfo, registerUser } from "../../store/user/thunks";
import Button from "../Basic/Button";
import Input from "../Basic/Input";
import CountryPhoneInput from "../Basic/PhoneInput/CountryPhoneInput";
import Select from "../Basic/Select";

const SignupForm = () => {
  const { token } = useParams();
  const { signupInfo } = useSelector((state) => state.user);
  const [openAvatarModal, setOpenAvatarModal] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");
  const [showInputPosition, setShowInputPosition] = useState(false);
  const [positionOther, setPositionOther] = useState("");
  const [positionError, setPositionError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const watchPosition = watch("occupation");

  useEffect(() => {
    dispatch(getUserInfo(token));
  }, [dispatch, token]);

  useEffect(() => {
    if (signupInfo.customer) {
      setValue("clinic", signupInfo.customer.name);
      setValue("email", signupInfo.email);
      setValue("firstName", signupInfo.first_name);
      setValue("lastName", signupInfo.last_name);
      setValue("displayName", signupInfo.display_name);
      setValue("occupation", signupInfo.occupation);
      setProfilePicture(signupInfo.profile_picture);
    }
  }, [signupInfo, setValue]);

  useEffect(() => {
    if (watchPosition === "other") {
      setShowInputPosition(true);
    } else {
      setPositionOther("");
      setShowInputPosition(false);
    }
  }, [watchPosition]);

  const checkInputPositionOther = useCallback(() => {
    if (watchPosition === "other" && !positionOther?.trim()) {
      setPositionError({ message: "Position is required" });
    } else {
      setPositionError(null);
    }
  }, [watchPosition, positionOther]);

  useEffect(() => {
    checkInputPositionOther();
  }, [checkInputPositionOther]);

  const onSubmit = (data) => {
    if (data.occupation === "other") {
      data.occupation = positionOther.trim();
    }

    if (!positionError) {
      dispatch(
        registerUser(
          token,
          data.password,
          data.firstName,
          data.lastName,
          data.displayName,
          data.phoneNumber,
          data.occupation,
          profilePicture,
          navigate,
        ),
      );
    }
  };

  return (
    <div className="w-full max-w-[22.5rem] mx-auto">
      <p className="text-2xl md:text-[32px] font-semibold mb-8">Sign up</p>

      <form className="mb-8 relative" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col space-y-5 mb-6">
          <Input
            required="Clinic can’t be blank"
            validate={(value) => {
              if (value.trim()) {
                return true;
              }
              return "Clinic can’t be blank";
            }}
            register={register}
            label="Clinic"
            placeholder="Select clinic"
            name="clinic"
            disabled
            error={errors.clinic}
            withoutAsterisk
          />

          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setOpenAvatarModal(true);
            }}
            className="bg-gray-200 rounded-full relative mb-5 md:mb-6 w-20 h-20 flex-none"
          >
            <img
              src={profilePicture || userIconSrc}
              alt="Avatar"
              className={`rounded-full
                ${profilePicture ? "min-w-full min-h-full object-cover" : "w-full max-w-[60px] mx-auto p-2"}`}
            />
            <div className="absolute bottom-0.5 right-0.5 rounded-full bg-primary-blue flex items-center justify-center w-6 h-6">
              <PencilIcon width={12} height={12} />
            </div>
          </button>

          <div className="grid md:grid-cols-2 gap-5">
            <Input
              register={register}
              name="firstName"
              label="First Name"
              placeholder="Enter first name"
              required="First Name can’t be blank"
              validate={(value) => {
                if (value.trim()) {
                  return true;
                }
                return "First Name can’t be blank";
              }}
              error={errors.firstName}
              withoutAsterisk
            />
            <Input
              register={register}
              name="lastName"
              label="Last Name"
              placeholder="Enter last name"
              required="Last Name can’t be blank"
              validate={(value) => {
                if (value.trim()) {
                  return true;
                }
                return "Last Name can’t be blank";
              }}
              error={errors.lastName}
              withoutAsterisk
            />
          </div>

          <Input
            label="Display Name"
            register={register}
            name="displayName"
            placeholder="Name with titles displayed to patient (ex. Dr. Smith)"
            required="Display name can't be empty"
            validate={(value) => {
              if (value.trim()) {
                return true;
              }
              return "Display name can't be empty";
            }}
            error={errors.displayName}
            withoutAsterisk
          />

          <Select
            label="Position"
            name="occupation"
            placeholder="Select Position"
            options={occupations}
            register={register}
            className="col-span-2"
            required="Position can't be empty"
            error={errors.occupation}
            withoutAsterisk
          />
          {showInputPosition && (
            <Input
              value={positionOther}
              onChange={(e) => setPositionOther(e.target.value)}
              placeholder="Enter your position"
              required="Position is required"
              error={positionError}
              withoutAsterisk
            />
          )}

          <Input
            required="Email can’t be blank"
            register={register}
            name="email"
            label="Email"
            placeholder="Enter your email"
            type="email"
            disabled
            error={errors.email}
            withoutAsterisk
          />

          <CountryPhoneInput
            required="Phone number is required"
            label="Phone Number"
            name="phoneNumber"
            countries={countryCodes}
            control={control}
            error={errors.phoneNumber}
            withoutAsterisk
          />

          <Input
            register={register}
            name="password"
            label="Password"
            placeholder="Enter your password"
            type="password"
            required="Password can’t be blank"
            error={errors.password}
            minLength={{
              value: 8,
              message: "Must be at least 8 characters",
            }}
            validationPattern={{
              value: passwordValidationRegEx,
              message:
                "Use a minimum of 8 characters with a mix of letters, numbers & symbols",
            }}
            autoComplete="new-password"
            withoutAsterisk
          />
          <Input
            register={register}
            name="confirmPassword"
            label="Confirm password"
            placeholder="Repeat your password"
            type="password"
            required="Password can’t be blank"
            error={errors.confirmPassword}
            validate={(value) => {
              if (watch("password") !== value) {
                return "Passwords don't match";
              }
            }}
            autoComplete="new-password"
            withoutAsterisk
          />
        </div>

        <Button
          className="w-full font-semibold mt-6"
          onClick={checkInputPositionOther}
          type="submit"
        >
          Get started
        </Button>
      </form>

      <div className="flex justify-center space-x-1">
        <p className="text-sm font-medium">Already have an account?</p>
        <Link
          to={Pages.LOGIN}
          className="text-primary-blue font-medium text-sm"
        >
          Sign in
        </Link>
      </div>

      <AvatarModal
        open={openAvatarModal}
        onClose={() => setOpenAvatarModal(false)}
        onSave={(picture) => {
          setProfilePicture(picture);
          setOpenAvatarModal(false);
        }}
      />
    </div>
  );
};

export default SignupForm;
