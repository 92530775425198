import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Pages, convPerPage } from "../../../helpers/constants";
import { useActivityTracker } from "../../../hooks/useActivityTracker";
import { useAppDispatch, useAppSelector } from "../../../store";
import { openConversationEvent } from "../../../store/billingMetrics/thunks";
import { useSendMessageMutation } from "../../../store/call/api";
import type { Conversation } from "../../../store/call/interfaces";
import { removeFailedMessageAction } from "../../../store/conversation/actions";
import { searchConversationsWithFilters } from "../../../store/conversation/thunks";
import { selectUser } from "../../../store/user/userReducer";
import ChatSkeleton from "../../Skeletons/ChatSkeleton/ChatSkeleton";
import type { MentionItem } from "../Mentions/interfaces";
import DateIndicator from "./DateIndicator";
import FailedMessagesList from "./FailedMessagesList";
import MessageList from "./MessageList";
import ScrollToBottomButton from "./ScrollToBottomButton";
import SentMessagesList from "./SentMessagesList";

interface ChatProps {
  scrolledUp: boolean;
  setScrolledUp: (scrolledUp: boolean) => void;
  showResponse: boolean;
  currentConversation: Conversation;
  selectedConversationId: string;
  mentionItems: MentionItem[];
}

const InboxChat = ({
  scrolledUp,
  setScrolledUp,
  showResponse,
  currentConversation,
  selectedConversationId,
  mentionItems,
}: ChatProps) => {
  const [conversationChanged, setConversationChanged] = useState(true);
  const [dateIndicator, setDateIndicator] = useState("");
  const [showDateIndicator, setShowDateIndicator] = useState(false);
  const [lastMessage, setLastMessage] = useState(null);
  const [failedMessageCurrent, setFailedMessageCurrent] = useState<
    {
      idempotencyKey: string;
      message: string;
      isInternal: boolean;
    }[]
  >([]);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [conversationMessagesLength, setConversationMessagesLength] = useState(
    {},
  );
  const [
    currentConversationLengthChanged,
    setCurrentConversationLengthChanged,
  ] = useState(false);
  const {
    conversationSearchInput,
    activeInbox,
    activeFilters,
    sentMessages,
    failedMessages,
    convCurrPage,
    conversationsUpdated,
    selectedDoctorInbox,
  } = useAppSelector((state) => state.conversation);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const messageContainer = useRef(null);
  const dateIndicatorRef = useRef(null);
  const dateRefs = useRef([]);
  const scrolledUpRef = useRef(scrolledUp);
  const conversationSearchInputRef = useRef(conversationSearchInput);
  const conversationMessagesLengthRef = useRef(conversationMessagesLength);
  const convCurrPageRef = useRef(convCurrPage);
  const activeFiltersRef = useRef(activeFilters);

  const selectedConversationIdMemoized = useMemo(() => {
    setConversationChanged(true);
    return selectedConversationId;
  }, [selectedConversationId]);

  const patientPicture = useMemo(
    () =>
      currentConversation ? currentConversation.patient.profile_picture : "",
    [currentConversation],
  );

  const doTracking = useMemo(
    () =>
      currentConversation && window.location.pathname === Pages.INBOX
        ? currentConversation.trackable
        : false,
    [currentConversation],
  );

  const { restartTimer } = useActivityTracker(doTracking);

  const [sendMessage] = useSendMessageMutation();

  useEffect(() => {
    scrolledUpRef.current = scrolledUp;
  }, [scrolledUp]);

  useEffect(() => {
    conversationSearchInputRef.current = conversationSearchInput;
  }, [conversationSearchInput]);

  useEffect(() => {
    conversationMessagesLengthRef.current = conversationMessagesLength;
  }, [conversationMessagesLength]);

  useEffect(() => {
    convCurrPageRef.current = convCurrPage;
  }, [convCurrPage]);

  useEffect(() => {
    activeFiltersRef.current = activeFilters;
  }, [activeFilters]);

  const scrollChat = useCallback(() => {
    if (
      messageContainer.current?.scrollTop /
        (messageContainer.current?.scrollHeight -
          messageContainer.current?.clientHeight) <
      0.99
    ) {
      setScrolledUp(true);
    } else {
      setScrolledUp(false);
      setUnreadMessages(0);
    }
  }, [setScrolledUp]);

  const scrollToBottom = useCallback((smooth) => {
    messageContainer.current?.scrollTo({
      top: messageContainer.current?.scrollHeight,
      left: 0,
      behavior: smooth ? "smooth" : "auto",
    });
    setUnreadMessages(0);
  }, []);

  useEffect(() => {
    if (scrolledUpRef.current && !conversationsUpdated) {
      setUnreadMessages((prev) => prev + 1);
    }
  }, [conversationsUpdated]);

  useEffect(() => {
    if (showResponse && !scrolledUpRef.current) {
      scrollToBottom(true);
    }
  }, [showResponse, scrollToBottom]);

  useEffect(() => {
    if (
      sentMessages[selectedConversationIdMemoized] &&
      sentMessages[selectedConversationIdMemoized].length > 0
    ) {
      scrollToBottom(true);
    }
  }, [sentMessages, scrollToBottom, selectedConversationIdMemoized]);

  useEffect(() => {
    if (failedMessageCurrent.length > 0) {
      scrollToBottom(false);
    }
  }, [failedMessageCurrent, scrollToBottom]);

  useEffect(() => {
    messageContainer.current.addEventListener("scroll", scrollChat);

    return () =>
      messageContainer.current?.removeEventListener("scroll", scrollChat);
  }, [scrollChat]);

  useEffect(() => {
    if (!currentConversation) {
      return;
    }

    setCurrentConversationLengthChanged(
      currentConversation.messages.length >
        conversationMessagesLengthRef.current[selectedConversationIdMemoized],
    );

    setConversationMessagesLength((prev) => ({
      ...prev,
      [selectedConversationIdMemoized]: currentConversation.messages.length,
    }));

    if (currentConversation.messages.length > 0) {
      setLastMessage(
        currentConversation.messages[currentConversation.messages.length - 1],
      );
    }

    dispatch(
      searchConversationsWithFilters(
        conversationSearchInputRef.current,
        activeFiltersRef.current,
        1,
        convPerPage * convCurrPageRef.current,
        activeInbox,
        selectedDoctorInbox,
      ),
    );

    if (conversationChanged) {
      scrollToBottom(false);

      (async () => {
        if (doTracking) {
          restartTimer();
          await openConversationEvent(
            currentConversation.patient.patient_id,
            currentConversation.campaign_type,
          );
        }
      })();
      setConversationChanged(false);
    } else {
      if (!scrolledUpRef.current) {
        scrollToBottom(true);
      }
    }
  }, [
    dispatch,
    currentConversation,
    scrollToBottom,
    restartTimer,
    selectedConversationIdMemoized,
    conversationChanged,
    activeInbox,
    doTracking,
    selectedDoctorInbox,
  ]);

  useEffect(() => {
    setFailedMessageCurrent(
      failedMessages[selectedConversationIdMemoized] || [],
    );
  }, [failedMessages, selectedConversationIdMemoized]);

  useEffect(() => {
    messageContainer.current.addEventListener("scroll", updateDateIndicator);

    const resizeObserver = new ResizeObserver(() => {
      if (messageContainer.current?.offsetWidth < 300) {
        setShowDateIndicator(false);
      }
    });
    resizeObserver.observe(messageContainer.current);

    return () => {
      messageContainer.current?.removeEventListener(
        "scroll",
        updateDateIndicator,
      );
      resizeObserver.disconnect();
    };
  }, []);

  let timeoutId: NodeJS.Timeout;
  const updateDateIndicator = () => {
    clearTimeout(timeoutId);
    if (messageContainer.current && messageContainer.current.scrollTop > 30) {
      setShowDateIndicator(true);
      timeoutId = setTimeout(() => {
        setShowDateIndicator(false);
      }, 4000);
    } else {
      setShowDateIndicator(false);
    }

    dateRefs.current.forEach((dateRef) => {
      if (dateRef && dateIndicatorRef.current) {
        if (
          dateIndicatorRef.current.getBoundingClientRect().top >=
          dateRef.getBoundingClientRect().top
        ) {
          setDateIndicator(dateRef.textContent);
        }
      }
    });
  };

  const removeFailedMessage = (idempotencyKey: string) => {
    dispatch(
      removeFailedMessageAction(
        idempotencyKey,
        Number(selectedConversationIdMemoized),
      ),
    );
  };

  const resendFailedMessage = (
    idempotencyKey: string,
    message: string,
    isInternal: boolean,
  ) => {
    removeFailedMessage(idempotencyKey);
    sendMessage({
      message,
      conversationId: Number(selectedConversationIdMemoized),
      isInternal,
      idempotencyKey,
    });
  };

  return (
    <div className="overflow-hidden relative flex">
      <div
        ref={messageContainer}
        className="pl-4 pb-4 pr-2 mr-2 overflow-y-auto scrollbar relative h-full w-full"
      >
        {!currentConversation ? (
          <ChatSkeleton />
        ) : (
          <>
            <DateIndicator
              showDateIndicator={showDateIndicator}
              dateIndicator={dateIndicator}
              dateIndicatorRef={dateIndicatorRef}
            />

            <MessageList
              conversation={currentConversation}
              mentionItems={mentionItems}
              patientPicture={patientPicture}
              dateRefs={dateRefs}
            />

            <SentMessagesList
              sentMessages={sentMessages}
              lastMessage={lastMessage}
              user={user}
              conversationId={Number(selectedConversationIdMemoized)}
            />

            <FailedMessagesList
              failedMessages={failedMessageCurrent}
              lastMessage={lastMessage}
              user={user}
              sentMessages={sentMessages}
              conversationId={Number(selectedConversationIdMemoized)}
              onRemoveMessage={removeFailedMessage}
              onResendMessage={resendFailedMessage}
            />

            <div className="absolute bottom-8 left-1/2 h-0 w-fit overflow-visible">
              <ScrollToBottomButton
                unreadMessages={unreadMessages}
                scrolledUp={scrolledUp}
                conversationLengthChanged={currentConversationLengthChanged}
                onClick={() => scrollToBottom(true)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InboxChat;
