import type { User } from "../../../store/user/userReducer";
import MessageCloud from "./MessageCloud";
import { shouldCombineWithFailedPrevious } from "./messageUtils";

interface FailedMessagesListProps {
  failedMessages: {
    idempotencyKey: string;
    message: string;
    isInternal: boolean;
  }[];
  lastMessage: any;
  user: User;
  sentMessages: Record<string, any[]>;
  conversationId: number;
  onRemoveMessage: (idempotencyKey: string) => void;
  onResendMessage: (
    idempotencyKey: string,
    message: string,
    isInternal: boolean,
  ) => void;
}

const FailedMessagesList = ({
  failedMessages,
  lastMessage,
  user,
  sentMessages,
  conversationId,
  onRemoveMessage,
  onResendMessage,
}: FailedMessagesListProps) => {
  if (!failedMessages?.length) {
    return null;
  }

  return (
    <div className="flex flex-col">
      {failedMessages.map((failedMessage, index) => {
        const combineWithPrevious =
          index === 0
            ? shouldCombineWithFailedPrevious(
                lastMessage,
                user,
                sentMessages,
                conversationId,
              )
            : true;

        return (
          <div key={failedMessage.idempotencyKey}>
            <MessageCloud
              combineWithPrevious={combineWithPrevious}
              type="outgoing"
              firstName={user.first_name}
              lastName={user.last_name}
              displayName={user.display_name}
              role={user.occupation}
              profilePicture={user.profile_picture}
              message={failedMessage.message}
              time=""
              failed
              onDelete={() => onRemoveMessage(failedMessage.idempotencyKey)}
              onResend={() =>
                onResendMessage(
                  failedMessage.idempotencyKey,
                  failedMessage.message,
                  failedMessage.isInternal,
                )
              }
              isInternal={failedMessage.isInternal}
            />
          </div>
        );
      })}
    </div>
  );
};

export default FailedMessagesList;
