import { ReactComponent as CheckIcon } from "@assets/icons/check-circle-opacity.svg";
import { ReactComponent as UndoCircleIcon } from "@assets/icons/undo-circle.svg";
import { useEffect, useState } from "react";
import {
  useMoveToCompletedMutation,
  useMoveToReviewMutation,
} from "../../../../store/call/api";
import LoaderDots from "../../../Basic/LoaderDots";

interface CompleteConversationButtonProps {
  conversation: any;
  className?: string;
}

const CompleteConversationButton = ({
  conversation,
  className = "bg-white",
}: CompleteConversationButtonProps) => {
  const [startAnimation, setStartAnimation] = useState(false);

  const [moveToReview] = useMoveToReviewMutation();
  const [moveToCompleted] = useMoveToCompletedMutation();

  useEffect(() => {
    if (
      conversation?.conversation_id &&
      conversation?.completed !== undefined
    ) {
      setStartAnimation(false);
    }
  }, [conversation?.conversation_id, conversation?.completed]);

  const onCompleteConversation = () => {
    moveToCompleted({
      patientId: conversation.patient.patient_id,
      conversationId: conversation.conversation_id,
    });
  };

  const onMoveToReview = () => {
    moveToReview({ conversation });
  };

  return (
    <div
      className={`w-full flex items-center justify-center md:justify-between text-sm px-4
        transition-all overflow-hidden h-10 lg:h-11 ${className}`}
    >
      <p
        className={`hidden md:flex font-medium text-tertiary transition-all duration-300 truncate
          ${conversation?.completed ? "w-0 opacity-0" : "w-28 opacity-100"}`}
      >
        Finished review?
      </p>

      <button
        type="button"
        className="flex items-center justify-center relative whitespace-nowrap font-semibold text-primary-blue h-10"
        disabled={startAnimation || !conversation}
        onClick={() => {
          setStartAnimation(true);
          if (conversation.completed) {
            onMoveToReview();
          } else {
            onCompleteConversation();
          }
        }}
      >
        {startAnimation || !conversation ? (
          <LoaderDots />
        ) : (
          <div className="flex items-center space-x-1">
            {conversation?.completed ? (
              <UndoCircleIcon
                width="20"
                height="20"
                className="flex-none transition-all duration-300"
                stroke="#2970FF"
              />
            ) : (
              <CheckIcon
                width="20"
                height="20"
                className="flex-none transition-all duration-300"
                stroke="#2970FF"
              />
            )}
            <p>
              {conversation?.completed ? "Move to Review" : "Mark as complete"}
            </p>
          </div>
        )}
      </button>
    </div>
  );
};

export default CompleteConversationButton;
