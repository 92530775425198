import { ReactComponent as CameraIcon } from "@assets/icons/camera.svg";
import { ReactComponent as CheckIcon } from "@assets/icons/check2.svg";
import { ReactComponent as Avatar1 } from "@assets/profile/avatar1.svg";
import { ReactComponent as Avatar2 } from "@assets/profile/avatar2.svg";
import { ReactComponent as Avatar3 } from "@assets/profile/avatar3.svg";
import { ReactComponent as Avatar4 } from "@assets/profile/avatar4.svg";
import { ReactComponent as Avatar5 } from "@assets/profile/avatar5.svg";
import { ReactComponent as Avatar6 } from "@assets/profile/avatar6.svg";
import { ReactComponent as Avatar7 } from "@assets/profile/avatar7.svg";
import { ReactComponent as Avatar8 } from "@assets/profile/avatar8.svg";
import { ReactComponent as Avatar9 } from "@assets/profile/avatar9.svg";
import { ReactComponent as Avatar10 } from "@assets/profile/avatar10.svg";
import { ReactComponent as Avatar11 } from "@assets/profile/avatar11.svg";
import { ReactComponent as Avatar12 } from "@assets/profile/avatar12.svg";
import { useEffect, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { useMediaQuery } from "react-responsive";
import Button from "../../Basic/Button";

const colors = [
  "#FFD2C4",
  "#B26756",
  "#FFD0B5",
  "#8F5346",
  "#F48462",
  "#FFEFE4",
];

const AvatarButton = ({
  fileName,
  selectedAvatar,
  onSelectAvatar,
  children,
}: {
  fileName: string;
  selectedAvatar: string | null;
  onSelectAvatar: (svgString: string, fileName: string) => void;
  children: React.ReactNode;
}) => {
  const selectedAvatarRef = useRef(selectedAvatar);

  useEffect(() => {
    selectedAvatarRef.current = selectedAvatar;
  }, [selectedAvatar]);

  useEffect(() => {
    if (selectedAvatarRef.current === fileName) {
      onSelectAvatar(renderToString(children), fileName);
    }
  }, [onSelectAvatar, fileName, children]);

  return (
    <button
      type="button"
      onClick={(e) => {
        e.preventDefault();
        onSelectAvatar(renderToString(children), fileName);
      }}
      className={`w-fit h-fit rounded-full border-3 relative mx-auto
        ${selectedAvatar === fileName ? "border-system-green" : "border-transparent"}`}
    >
      {children}

      {selectedAvatar === fileName && (
        <div className="absolute bottom-0 right-0 rounded-full bg-system-green flex items-center justify-center w-6 h-6">
          <CheckIcon width="15" height="15" stroke="white" />
        </div>
      )}
    </button>
  );
};

const ColorButton = ({
  color,
  setSelectedColor,
}: {
  color: string;
  setSelectedColor: (color: string) => void;
}) => {
  return (
    <button
      type="button"
      className="w-11 h-11 md:w-14 md:h-14 rounded-full mx-auto"
      style={{ backgroundColor: color }}
      onClick={(e) => {
        e.preventDefault();
        setSelectedColor(color);
      }}
    />
  );
};

const AvatarSelect = ({
  className = "",
  onSave,
  onCancel,
}: {
  className?: string;
  onSave: (avatar: string) => void;
  onCancel: () => void;
}) => {
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [uploadedImgName, setUploadedImgName] = useState(null);
  const [profileImg, setProfileImg] = useState(null);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const avatarSize = isDesktop ? 80 : 60;
  const onSelectAvatar = (svgString, fileName) => {
    setSelectedAvatar(fileName);
    svgToDataUrl(svgString).then((dataUrl) => {
      setProfileImg(dataUrl);
    });

    setUploadedImgName(null);
  };

  const svgToDataUrl = (svgString) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.src = `data:image/svg+xml;base64,${window.btoa(svgString)}`;

      img.onload = () => {
        canvas.width = 300;
        canvas.height = 300;
        ctx.drawImage(img, 0, 0, 300, 300);
        const dataUrl = canvas.toDataURL("image/png");
        resolve(dataUrl);
      };

      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onUploadImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedImgName(file.name);
      const imgUrl = URL.createObjectURL(file);
      objectUrlToDataUrl(imgUrl).then((dataUrl) => {
        setProfileImg(dataUrl);
      });

      setSelectedAvatar(null);
    }
  };

  const objectUrlToDataUrl = (objectURL) => {
    return new Promise((resolve, reject) => {
      fetch(objectURL)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.onerror = () => {
            reject(new Error("Error reading Blob"));
          };
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return (
    <div
      className={`w-full grid grid-rows-note-layout overflow-hidden ${className}`}
    >
      <div className="px-4 min-h-0 flex flex-col overflow-y-auto scrollbar">
        <div className="grid grid-cols-4 gap-5">
          <AvatarButton
            fileName="avatar1.svg"
            selectedAvatar={selectedAvatar}
            onSelectAvatar={onSelectAvatar}
          >
            <Avatar1
              width={avatarSize}
              height={avatarSize}
              fill={selectedColor || colors[0]}
            />
          </AvatarButton>
          <AvatarButton
            fileName="avatar2.svg"
            selectedAvatar={selectedAvatar}
            onSelectAvatar={onSelectAvatar}
          >
            <Avatar2
              width={avatarSize}
              height={avatarSize}
              fill={selectedColor || colors[0]}
            />
          </AvatarButton>
          <AvatarButton
            fileName="avatar3.svg"
            selectedAvatar={selectedAvatar}
            onSelectAvatar={onSelectAvatar}
          >
            <Avatar3
              width={avatarSize}
              height={avatarSize}
              fill={selectedColor || colors[1]}
            />
          </AvatarButton>
          <AvatarButton
            fileName="avatar4.svg"
            selectedAvatar={selectedAvatar}
            onSelectAvatar={onSelectAvatar}
          >
            <Avatar4
              width={avatarSize}
              height={avatarSize}
              fill={selectedColor || colors[3]}
            />
          </AvatarButton>
          <AvatarButton
            fileName="avatar5.svg"
            selectedAvatar={selectedAvatar}
            onSelectAvatar={onSelectAvatar}
          >
            <Avatar5
              width={avatarSize}
              height={avatarSize}
              fill={selectedColor || colors[1]}
            />
          </AvatarButton>
          <AvatarButton
            fileName="avatar6.svg"
            selectedAvatar={selectedAvatar}
            onSelectAvatar={onSelectAvatar}
          >
            <Avatar6
              width={avatarSize}
              height={avatarSize}
              fill={selectedColor || colors[4]}
            />
          </AvatarButton>
          <AvatarButton
            fileName="avatar7.svg"
            selectedAvatar={selectedAvatar}
            onSelectAvatar={onSelectAvatar}
          >
            <Avatar7
              width={avatarSize}
              height={avatarSize}
              fill={selectedColor || colors[2]}
            />
          </AvatarButton>
          <AvatarButton
            fileName="avatar8.svg"
            selectedAvatar={selectedAvatar}
            onSelectAvatar={onSelectAvatar}
          >
            <Avatar8
              width={avatarSize}
              height={avatarSize}
              fill={selectedColor || colors[1]}
            />
          </AvatarButton>
          <AvatarButton
            fileName="avatar9.svg"
            selectedAvatar={selectedAvatar}
            onSelectAvatar={onSelectAvatar}
          >
            <Avatar9
              width={avatarSize}
              height={avatarSize}
              fill={selectedColor || colors[0]}
            />
          </AvatarButton>
          <AvatarButton
            fileName="avatar10.svg"
            selectedAvatar={selectedAvatar}
            onSelectAvatar={onSelectAvatar}
          >
            <Avatar10
              width={avatarSize}
              height={avatarSize}
              fill={selectedColor || colors[1]}
            />
          </AvatarButton>
          <AvatarButton
            fileName="avatar11.svg"
            selectedAvatar={selectedAvatar}
            onSelectAvatar={onSelectAvatar}
          >
            <Avatar11
              width={avatarSize}
              height={avatarSize}
              fill={selectedColor || colors[4]}
            />
          </AvatarButton>
          <AvatarButton
            fileName="avatar12.svg"
            selectedAvatar={selectedAvatar}
            onSelectAvatar={onSelectAvatar}
          >
            <Avatar12
              width={avatarSize}
              height={avatarSize}
              fill={selectedColor || colors[5]}
            />
          </AvatarButton>
        </div>

        <p className="mt-5 mb-2 text-xs text-tertiary text-center">
          Choose color
        </p>

        <div className="grid grid-cols-6 gap-2">
          {colors.map((color, index) => (
            <ColorButton
              key={index}
              color={color}
              setSelectedColor={setSelectedColor}
            />
          ))}
        </div>

        <p className="mt-5 mb-3 text-xs text-tertiary text-center">
          or set profiles picture from your device
        </p>

        <label
          htmlFor="photo-input"
          className="cursor-pointer flex items-center justify-center w-full space-x-2 bg-primary-blue-light
            text-primary-blue font-semibold rounded-lg h-10 px-3.5 truncate flex-none"
        >
          <CameraIcon
            width="22"
            height="20"
            stroke="#2970FF"
            className="flex-none"
          />
          <p className="truncate">
            {uploadedImgName ? uploadedImgName : "Upload photo"}
          </p>

          <input
            id="photo-input"
            name="photo-input"
            accept="image/*"
            className="hidden"
            type="file"
            onClick={(e) => {
              (e.target as HTMLInputElement).value = null;
            }}
            onChange={onUploadImage}
          />
        </label>
      </div>

      <div className="w-full bg-gray-background py-4 px-5 flex justify-end space-x-4 text-sm font-semibold mt-4">
        <Button className="w-full" variant="gray-filled" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className="w-full"
          onClick={() => onSave(profileImg)}
          disabled={!profileImg}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default AvatarSelect;
