import axios, { type AxiosError } from "axios";
import { handleValidation } from "../../helpers/commonValidationSchemas";
import { BASE_URL } from "../../helpers/config";
import { inboxes } from "../../helpers/constants";
import { handleRequestError } from "../../helpers/helpers";
import { baseApi } from "../baseApi";
import type { AppDispatch } from "../index";
import { setNotificationAction } from "../user/actions";
import {
  setConversationsAction,
  setConversationsCompletedAction,
  setConversationsCountsAction,
  setConversationsReviewAction,
  setLoaderAction,
  setNumOfConvByDoctorIdAction,
} from "./actions";
import {
  conversationCountsSchema,
  conversationsSchema,
} from "./validationSchemas";

const splitCampaignIdsAndCommon = (campaigns) => {
  return campaigns.reduce(
    (result, value) => {
      if (typeof value === "number" && !Number.isNaN(value)) {
        result.campaignIds.push(value);
      } else if (typeof value === "string") {
        result.commonCampaigns.push(value);
      }
      return result;
    },
    { campaignIds: [], commonCampaigns: [] },
  );
};

export const searchConversationsWithFilters =
  (searchInput, filters, page, rows, inboxType, doctor, onSuccess = () => {}) =>
  (dispatch: AppDispatch) => {
    const { campaignIds, commonCampaigns } = splitCampaignIdsAndCommon(
      filters?.campaigns ? filters.campaigns : [],
    );
    const statuses = filters?.status?.[inboxType]
      ? filters.status[inboxType]
      : [];
    const categories = filters?.category ? filters.category : [];
    const types = filters?.type ? filters.type : [];
    const flags = filters?.other?.flag ? filters.other.flag : [];
    const isUnread = filters?.other?.unread ? true : null;
    const isStaffEngaging = filters?.other?.staffEngaging ? true : null;
    const from =
      filters?.time && filters.time !== "all" ? filters.time - 1 : null;

    const doctorId = doctor
      ? doctor !== "unassigned"
        ? doctor.doctor_id
        : null
      : null;
    const unassigned = doctor === "unassigned";

    const clinicAll = doctor === "clinic_all";

    // @deprecated: only used in the messaging page, to be removed by mid jan. 2025
    axios
      .post(`${BASE_URL}/conversation/search`, {
        q: searchInput,
        inbox: inboxType,
        doctor_id: doctorId,
        campaignIds: campaignIds,
        commonCampaigns: commonCampaigns,
        statuses: [...statuses, ...categories, ...types],
        flags: flags,
        isUnread: isUnread,
        staffEngaging: isStaffEngaging,
        from: from,
        to: 0,
        offset: (page - 1) * rows,
        limit: rows,
        unassigned: unassigned,
        clinic_all: clinicAll,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to load conversations",
          );
        } else {
          const conversations = response.data.data;

          handleValidation(
            conversationsSchema,
            conversations,
            "searchConversationsWithFilters",
          );

          switch (inboxType) {
            case inboxes.ACTIVE:
              dispatch(setConversationsAction(response.data.data));
              break;
            case inboxes.REVIEW:
              dispatch(setConversationsReviewAction(response.data.data));
              break;
            case inboxes.COMPLETE:
              dispatch(setConversationsCompletedAction(response.data.data));
              break;
            default:
          }
          onSuccess();
        }
        dispatch(
          getConversationCounts(
            from,
            inboxType,
            campaignIds,
            commonCampaigns,
            [...statuses, ...categories, ...types],
            flags,
            isUnread,
            clinicAll ? "clinic_all" : doctorId,
            unassigned,
            undefined,
          ),
        );
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to load conversations");
      });
  };

export const getConversationCounts =
  (
    from,
    inboxType,
    campaignIds,
    commonCampaigns,
    statuses,
    flags,
    isUnread,
    doctorId,
    unassigned,
    clinicAll,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      // const data = await fetchWithAuth(`${BASE_URL}/conversation/count`, {
      //   method: "POST",
      //   body: JSON.stringify({
      //     from: from,
      //     to: 0,
      //     inbox: inboxType,
      //     campaignIds: campaignIds,
      //     commonCampaigns: commonCampaigns,
      //     statuses: statuses,
      //     flags: flags,
      //     isUnread: isUnread,
      //     doctor_id: doctorId,
      //     unassigned: unassigned,
      //     clinic_all: clinicAll,
      //   }),
      // });

      const response = await axios.post(`${BASE_URL}/conversation/count`, {
        from: from,
        to: 0,
        inbox: inboxType,
        campaignIds: campaignIds,
        commonCampaigns: commonCampaigns,
        statuses: statuses,
        flags: flags,
        isUnread: isUnread,
        doctor_id: doctorId,
        unassigned: unassigned,
        clinic_all: clinicAll,
      });
      const data = response.data;

      if (data.error) {
        handleRequestError(data.error, dispatch, "Failed to load counts");
      } else {
        const counts = data.data;

        handleValidation(
          conversationCountsSchema,
          counts,
          "getConversationCounts",
        );

        dispatch(setConversationsCountsAction(inboxType, counts));
        dispatch(getConversationCountByDoctor(doctorId, unassigned));
      }
    } catch (error) {
      handleRequestError(error, dispatch, "Failed to load counts");
    } finally {
      dispatch(setLoaderAction(false));
    }
  };

export const getConversationCountByDoctor =
  (doctor, unassigned) => (dispatch: AppDispatch) => {
    const doctorId = doctor
      ? doctor !== "unassigned"
        ? doctor.doctor_id
        : null
      : null;
    const unassignedDoctor = doctor === "unassigned" || unassigned;

    const clinicAll = doctor === "clinic_all";

    axios
      .post(`${BASE_URL}/conversation/count`, {
        from: null,
        to: 0,
        inbox: "active",
        campaignIds: [],
        commonCampaigns: [],
        statuses: [],
        flags: [],
        isUnread: null,
        doctor_id: doctorId,
        unassigned: unassignedDoctor,
        clinic_all: clinicAll,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to load counts",
          );
        } else {
          const counts = response.data.data;

          handleValidation(
            conversationCountsSchema,
            counts,
            "getConversationCountByDoctor",
          );

          dispatch(
            setNumOfConvByDoctorIdAction({
              [`${
                clinicAll
                  ? "clinic_all"
                  : unassignedDoctor
                    ? "unassigned"
                    : doctorId
                      ? doctorId
                      : "all"
              }`]:
                (Number(counts?.["active TOTAL"]) || 0) +
                (Number(counts?.["review TOTAL"]) || 0),
            }),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to load counts");
      })
      .finally(() => {
        dispatch(setLoaderAction(false));
      });
  };

export const completeConversation =
  (
    conversationId,
    conversationSearchInput,
    activeFilters,
    page,
    rows,
    inboxType,
    doctor,
  ) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/conversation/${conversationId}/complete`)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to complete conversation",
          );
        } else {
          dispatch(
            searchConversationsWithFilters(
              conversationSearchInput,
              activeFilters,
              page,
              rows,
              inboxType,
              doctor,
            ),
          );
          dispatch(
            searchConversationsWithFilters(
              conversationSearchInput,
              activeFilters,
              page,
              rows,
              inboxes.COMPLETE,
              doctor,
            ),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to complete conversation");
      });
  };

export const markAsUnread =
  (
    conversationId,
    conversationSearchInput,
    activeFilters,
    page,
    rows,
    inboxType,
    doctor,
  ) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/conversation/mark-as-unread`, {
        conversation_id: conversationId,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to mark conversation as unread",
          );
        } else {
          dispatch(
            searchConversationsWithFilters(
              conversationSearchInput,
              activeFilters,
              page,
              rows,
              inboxType,
              doctor,
            ),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(
          error,
          dispatch,
          "Failed to mark conversation as unread",
        );
      });
  };

export const changeFlag =
  (
    conversationId,
    flag,
    conversationSearchInput,
    activeFilters,
    page,
    rows,
    inboxType,
    doctor,
  ) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/conversation/change-flag`, {
        conversation_id: conversationId,
        flag: flag,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to change conversation flag",
          );
        } else {
          dispatch(
            searchConversationsWithFilters(
              conversationSearchInput,
              activeFilters,
              page,
              rows,
              inboxType,
              doctor,
            ),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(
          error,
          dispatch,
          "Failed to change conversation flag",
        );
      });
  };

export const suggestAnswer = (id) => (dispatch: AppDispatch) => {
  axios
    .get(`${BASE_URL}/conversation/${id}/suggested-answer`)
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to load suggested answer",
        );
      }
      dispatch(baseApi.util.invalidateTags([{ type: "Conversation", id }]));
    });
};

export const removeDiagnoses = (diagnoses, conversationId) => (dispatch) => {
  axios
    .post(`${BASE_URL}/conversation/remove-diagnoses`, {
      conversation_id: conversationId,
      diagnoses: diagnoses,
    })
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to remove diagnoses",
        );
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Successfully removed diagnosis",
            desc: "Diagnosis was successfully removed",
          }),
        );
        dispatch(
          baseApi.util.invalidateTags([
            { type: "Conversation", id: conversationId },
          ]),
        );
      }
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(error, dispatch, "Failed to remove diagnoses");
    });
};

export const saveEncounterNote =
  (audioId: string, note: string, onSuccess = () => {}) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/encounter/${audioId}/save-note`, {
        note: note,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to save encounter note",
          );
        } else {
          onSuccess();
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to save encounter note");
      });
  };

export const saveEncounterNoteSimple =
  (audioId, note) => (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/encounter/${audioId}/save-note`, {
        note: note,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to save encounter note",
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to save encounter note");
      });
  };

export const saveEncounterTitle =
  (audioId, title, onSuccess?: () => void, onError?: () => void) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/encounter/${audioId}/save-note`, {
        title,
      })
      .then((response) => {
        if (response.data.error) {
          onError?.();
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to save encounter title",
          );
          return;
        }

        onSuccess?.();
      })
      .catch((error: Error | AxiosError) => {
        onError?.();
        handleRequestError(error, dispatch, "Failed to save encounter title");
      });
  };

export const saveEncounterFreeText =
  (audioId, freeText) => (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/encounter/${audioId}/save-note`, {
        free_text: freeText,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to save encounter free text",
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(
          error,
          dispatch,
          "Failed to save encounter free text",
        );
      });
  };

export const saveEncounterPatient =
  (audioId, patientId) => (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/encounter/${audioId}/save-note`, {
        patient_id: patientId,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to save encounter patient",
          );
        } else {
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully saved patient",
              desc: "Encounter has been successfully updated",
            }),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to save encounter patient");
      });
  };
