import { ReactComponent as LogoutIcon } from "@assets/icons/logout.svg";
import { ReactComponent as LogoIcon } from "@assets/logo-quadrant-new.svg";
import { ReactComponent as LogoTextIcon } from "@assets/quadrant-health-text.svg";
import { ClickAwayListener } from "@mui/base";
import { useState } from "react";
import { NavLink } from "react-router";
import { getDefaultRoute } from "../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../store";
import { useFeature } from "../../store/featureFlagSlice";
import { FeatureFlags } from "../../store/featureFlagSlice";
import { logoutAction } from "../../store/user/actions";
import { selectUser } from "../../store/user/userReducer";
import ProfilePicture from "../Basic/ProfilePicture";
import SiteSelect from "./SiteSelect";

const Navbar = ({
  navButtons,
  expandMenu,
  setExpandMenu,
  handleNavLinkClick,
}) => {
  const user = useAppSelector(selectUser);
  const isScribeOnly = useFeature(FeatureFlags.SCRIBE_ONLY);
  const isFrontDeskEnabled = useFeature(FeatureFlags.FRONT_DESK);
  const isFrontDeskInbox = useFeature(FeatureFlags.FRONT_DESK_INBOX);
  const [showLogoutMenu, setShowLogoutMenu] = useState(false);
  const dispatch = useAppDispatch();

  const logoLink = getDefaultRoute({
    isScribeOnlyFeatureEnabled: isScribeOnly,
    isFrontDeskInboxEnabled: isFrontDeskInbox,
    isFrontDeskEnabled: isFrontDeskEnabled,
  });

  const onClickProfileImg = () => {
    setShowLogoutMenu(!showLogoutMenu);
  };

  const logout = () => {
    dispatch(logoutAction());
  };

  return (
    <div className="fixed top-0 left-0 h-screen-dynamic w-fit z-50">
      <nav
        className={`h-full flex flex-col justify-between pb-6 bg-white overflow-hidden transition-all
          ${expandMenu ? "w-64 shadow-menu" : "w-[72px]"}`}
        onMouseEnter={() => setExpandMenu(true)}
        onMouseLeave={() => setExpandMenu(false)}
      >
        <div className="flex flex-col w-full overflow-x-hidden overflow-y-auto scrollbar px-3.5 pt-6">
          <NavLink
            aria-label="Inbox"
            to={logoLink}
            className="pb-6 border-b mb-2"
            onClick={(e) => handleNavLinkClick(e, logoLink)}
          >
            <div
              className={`flex items-center overflow-hidden transition-all
                ${expandMenu ? "w-full" : "w-11"}`}
            >
              <LogoIcon width="44" height="40" className="flex-none" />
              <LogoTextIcon
                width="76"
                height="26"
                className={`flex-none
                  ${expandMenu ? "ml-2" : "transition-all opacity-0"}`}
              />
            </div>
          </NavLink>
          <div className="w-full flex flex-col text-tertiary font-semibold text-base">
            {navButtons}
          </div>
        </div>

        <div className="w-full">
          {/* <p
            className={`text-xs text-right p-3 ${expandMenu ? "" : "hidden"}`}
          >
            Version: {process.env.REACT_APP_VERSION}
          </p> */}

          <SiteSelect expandMenu={expandMenu} />

          <div
            className="cursor-pointer select-none flex items-center space-x-3 font-medium truncate pt-4 px-4 border-t"
            onClick={onClickProfileImg}
            data-testid="profile-button"
          >
            <ProfilePicture
              size={10}
              fontSize="base"
              firstName={user.first_name}
              lastName={user.last_name}
              flagPadding={false}
              src={user.profile_picture}
            />
            <p className="truncate">{user.display_name}</p>
          </div>
        </div>
      </nav>
      {showLogoutMenu && (
        <ClickAwayListener onClickAway={() => setShowLogoutMenu(false)}>
          <div className="absolute z-50 bottom-20 left-6 h-11 w-36 py-3 bg-white rounded-lg shadow-menu">
            <button
              type="button"
              className="flex items-center space-x-2 px-4 w-full"
              onClick={logout}
              data-testid="logout-button"
            >
              <LogoutIcon width="20" height="20" />
              <p className="text-sm text-zinc-700">Logout</p>
            </button>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default Navbar;
