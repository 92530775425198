import { ReactComponent as EllipsisHorizontal2Icon } from "@assets/icons/ellipsis-horizontal2.svg";
import { ReactComponent as UserMinusRoundedIcon } from "@assets/icons/user-minus-rounded.svg";
import { ReactComponent as UserRoundedIcon } from "@assets/icons/user-rounded.svg";
import { ReactComponent as UsersGroupRoundedIcon } from "@assets/icons/users-group-rounded.svg";
import { Popover } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router";
import { Pages } from "../../../helpers/constants";
import { getAge, getPatientSex } from "../../../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  type FixLater,
  setInterruptedRecordingAction,
} from "../store/scribeSlice";
import { getRecordingScribe } from "../store/selectors";
import { SetInterruptedRecordingActionType } from "../store/types";

const ScribePatient = ({
  patient,
  setShowAssignPatientModal,
  handleAssignPatientId,
}: {
  patient: FixLater;
  setShowAssignPatientModal: (show: boolean) => void;
  handleAssignPatientId: (patientId: number) => void;
}) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();
  const recordingScribe = useAppSelector(getRecordingScribe);

  const onCloseOptions = () => {
    setOpenOptions(false);
    setAnchorEl(null);
  };

  const onOpenOptions = (e) => {
    setOpenOptions(true);
    setAnchorEl(e.currentTarget);
  };

  const sexAbbreviation = getPatientSex(patient.sex)
    ? getPatientSex(patient.sex).charAt(0).toUpperCase()
    : "";

  const patientDescription = patient
    ? [
        patient.birthdate ? `${getAge(patient.birthdate)} yo` : "",
        sexAbbreviation,
        patient.pronouns,
        patient.birthdate
          ? new Date(patient.birthdate).toLocaleDateString(undefined, {
              month: "short",
              day: "numeric",
              year: "numeric",
            })
          : "",
      ]
        .filter((field) => !!field)
        .join(" • ")
    : "";

  const openPatientPage = () => {
    if (recordingScribe) {
      dispatch(
        setInterruptedRecordingAction({
          type: SetInterruptedRecordingActionType.PATIENT_PROFILE_OPEN,
        }),
      );
      return;
    }
  };

  return (
    <>
      <Link
        to={{
          pathname: `${Pages.PATIENTS}/${patient.patient_id}`,
        }}
        className="flex items-center gap-2 md:hidden font-semibold text-base"
        onClick={openPatientPage}
      >
        <span>
          {patient.first_name} {patient.last_name}
        </span>
        <EllipsisHorizontal2Icon
          onClick={onOpenOptions}
          width="20"
          height="20"
          className="stroke-2 text-tertiary"
        />
      </Link>
      <div className="hidden md:flex gap-3 bg-gray-110 items-center rounded-full p-1">
        <Link
          to={{
            pathname: `${Pages.PATIENTS}/${patient.patient_id}`,
          }}
          onClick={openPatientPage}
        >
          {patient.photo ? (
            <img
              src={patient.photo}
              alt="patient"
              className="w-8 h-8 rounded-full cursor-pointer"
            />
          ) : (
            <p
              className="w-8 h-8 rounded-full bg-gray-foreground text-xs text-primary-blue uppercase
                flex items-center justify-center cursor-pointer"
            >
              {patient.first_name?.charAt(0)}
              {patient.last_name?.charAt(0)}
            </p>
          )}
        </Link>
        <Link
          to={{
            pathname: `${Pages.PATIENTS}/${patient.patient_id}`,
          }}
          onClick={openPatientPage}
          className="flex flex-col justify-center cursor-pointer"
        >
          <p className="font-semibold text-sm">
            {patient.first_name} {patient.last_name}
          </p>
          <p className="text-xs text-gray-500">{patientDescription}</p>
        </Link>
        <div
          className="bg-white rounded-full cursor-pointer p-2 align-middle"
          onClick={onOpenOptions}
        >
          <EllipsisHorizontal2Icon
            width="20"
            height="20"
            className="stroke-2 text-tertiary"
          />
        </div>
      </div>
      {openOptions && anchorEl && (
        <Popover
          open={openOptions}
          onClose={onCloseOptions}
          className="drop-shadow-2xl border border-gray-foreground"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          slotProps={{
            paper: {
              sx: {
                borderRadius: "8px",
              },
            },
          }}
        >
          <div className="flex flex-col gap-1 py-2 items-start text-sm font-medium">
            <Link
              to={{
                pathname: `${Pages.PATIENTS}/${patient.patient_id}`,
              }}
              className="flex gap-2 px-4 py-2 w-full group hover:text-primary hover:bg-gray-background"
              onClick={openPatientPage}
            >
              <UserRoundedIcon
                width="20"
                height="20"
                className="opacity-60 group-hover:opacity-100"
              />
              <span>Patient Profile</span>
            </Link>
            <button
              type="button"
              className="flex gap-2 px-4 py-2 w-full group hover:text-primary hover:bg-gray-background"
              onClick={() => {
                setShowAssignPatientModal(true);
                onCloseOptions();
              }}
            >
              <UsersGroupRoundedIcon
                width="20"
                height="20"
                className="opacity-60 group-hover:opacity-100"
              />
              <span>Change Patient</span>
            </button>
            <button
              type="button"
              className="flex gap-2 px-4 py-2 w-full group hover:text-primary hover:bg-gray-background"
              onClick={() => handleAssignPatientId(null)}
            >
              <UserMinusRoundedIcon
                width="20"
                height="20"
                className="opacity-60 group-hover:opacity-100"
              />
              <span>Unassign Patient</span>
            </button>
          </div>
        </Popover>
      )}
    </>
  );
};

export default ScribePatient;
