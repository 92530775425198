import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useRef, useState } from "react";
import { useGetConversationQuery } from "../../../../store/call/api";
import useConversationSearchParams from "../../../Inbox/hooks/useConversationSearchParams";
import ChatSummary from "./ChatSummary";
import OutreachFocus from "./OutreachFocus";

const InsightTab = () => {
  const { selectedConversationId } = useConversationSearchParams();
  const [conversationSwitched, setConversationSwitched] = useState(false);
  const tabRef = useRef(null);

  const { data: currentConversation } = useGetConversationQuery(
    selectedConversationId ? Number(selectedConversationId) : skipToken,
  );

  useEffect(() => {
    if (conversationSwitched) {
      tabRef.current.scrollTo(0, 0);
      setConversationSwitched(false);
    }
  }, [conversationSwitched]);

  useEffect(() => {
    if (selectedConversationId) {
      setConversationSwitched(true);
    }
  }, [selectedConversationId]);

  return (
    <div
      ref={tabRef}
      className="space-y-5 px-5 py-5 md:pl-5 md:pr-2 md:mr-2 h-fit md:h-full md:overflow-y-auto scrollbar"
    >
      <ChatSummary />
      {currentConversation.campaign_type.includes("Outreach") && (
        <OutreachFocus />
      )}
    </div>
  );
};

export default InsightTab;
