import { ReactComponent as MessageIcon } from "@assets/icons/chat-round-2.svg";
import { ReactComponent as CallIcon } from "@assets/icons/phone-new.svg";
import { ReactComponent as CloseIcon } from "@assets/icons/x-mark.svg";
import { skipToken } from "@reduxjs/toolkit/query";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router";
import { Pages, twilioDeviceStatuses } from "../../../helpers/constants";
import {
  getFormattedPhoneNumber,
  getPatientSex,
} from "../../../helpers/helpers";
import TwilioService from "../../../services/TwilioService";
import { type RootState, useAppDispatch, useAppSelector } from "../../../store";
import {
  useGetConversationQuery,
  useMoveToCompletedMutation,
  useMoveToReviewMutation,
} from "../../../store/call/api";
import {
  getIsMessageInputOpen,
  getSelectedItemPatient,
  setIsMessageInputOpen,
  setSelectedItemPatient,
} from "../../../store/call/callSlice";
import {
  CallScribeType,
  type InboxItem,
  type InboxPatient,
} from "../../../store/call/interfaces";
import { setMessagesAction } from "../../../store/conversation/actions";
import { selectUser } from "../../../store/user/userReducer";
import {
  setDeviceStatusAction,
  setOpenCallModalAction,
} from "../../../store/voiceRecorder/actions";
import {
  getTwilioToken,
  setCallScribeType,
  setIsToScribe,
} from "../../../store/voiceRecorder/thunks";
import PatientShortInfo from "../../PatientProfile/PatientShortInfo";
import MessageTab from "../../PatientProfile/RightPanel/Message/MessageTab";
import ChatSkeleton from "../../Skeletons/ChatSkeleton/ChatSkeleton";
import useConversationSearchParams from "../hooks/useConversationSearchParams";
import CallControlButtons from "./CallControlButtons";
import CallStatusButtons from "./CallStatusButtons";

const buttonStyle =
  "w-8 h-8 rounded-lg border border-gray-110 flex items-center justify-center";

interface CallDetailsProps {
  onClose?: () => void;
  className?: string;
  nextConversation: {
    patient: InboxPatient;
    items: InboxItem[];
  };
}

const CallDetails = ({
  onClose = () => {},
  className = "",
  nextConversation,
}: CallDetailsProps) => {
  const user = useAppSelector(selectUser);
  const { selectedConversationId, setSelectedConversationId } =
    useConversationSearchParams();
  const isMessageInputOpen = useAppSelector(getIsMessageInputOpen);
  const selectedItemPatient = useAppSelector(getSelectedItemPatient);

  const { data: callConversation } = useGetConversationQuery(
    selectedItemPatient?.patient_phone_number && selectedConversationId
      ? Number(selectedConversationId)
      : skipToken,
  );

  const { messages } = useAppSelector((state: RootState) => state.conversation);
  const { twilioToken, deviceStatus, callEvent, openCallModal, scribeCalls } =
    useAppSelector((state: RootState) => state.voiceRecorder);

  const dispatch = useAppDispatch();
  const [isMuted, setIsMuted] = useState(false);
  const twilioServiceRef = useRef(new TwilioService(dispatch));
  const [callSeconds, setCallSeconds] = useState(0);
  const callInterval = useRef(null);
  const scribeCallsRef = useRef(scribeCalls);

  const [moveToReview] = useMoveToReviewMutation();
  const [moveToCompleted] = useMoveToCompletedMutation();

  const patient = selectedItemPatient
    ? {
        patient_id: selectedItemPatient.patient_id,
        first_name: selectedItemPatient.patient_full_name,
        last_name: "",
        phone_number: selectedItemPatient.patient_phone_number,
        profile_picture: "",
        birthdate: selectedItemPatient.patient_birthdate,
        gender: selectedItemPatient.patient_gender,
        sex: selectedItemPatient.patient_sex,
        pronouns: selectedItemPatient.patient_pronouns,
        chart_number: selectedItemPatient.patient_npi,
      }
    : null;

  useEffect(() => {
    if (callConversation?.patient) {
      const itemPatient = {
        patient_id: callConversation.patient.patient_id,
        patient_full_name: `${callConversation.patient.first_name} ${callConversation.patient.last_name}`,
        patient_phone_number: callConversation.patient.phone_number,
        patient_birthdate: callConversation.patient.birthdate,
        patient_pronouns: callConversation.patient.pronouns,
        patient_sex: getPatientSex(callConversation.patient.sex),
        patient_preferred_name: callConversation.patient.preferred_name,
        patient_gender: callConversation.patient.gender,
        patient_npi: callConversation.patient.chart_number,
        conversation_id: callConversation.patient.conversation_id,
      };
      dispatch(setSelectedItemPatient(itemPatient));
    }
  }, [callConversation?.patient, dispatch]);

  useEffect(() => {
    scribeCallsRef.current = scribeCalls;
  }, [scribeCalls]);

  useEffect(() => {
    if (callInterval.current) {
      clearInterval(callInterval.current);
      callInterval.current = null;
    }

    if (deviceStatus !== twilioDeviceStatuses.IN_PROGRESS) {
      setCallSeconds(0);

      return;
    }

    callInterval.current = setInterval(() => {
      setCallSeconds((callSeconds) => callSeconds + 1);
    }, 1000);

    return () => {
      if (callInterval.current) {
        clearInterval(callInterval.current);
        callInterval.current = null;
      }
    };
  }, [deviceStatus]);

  useEffect(() => {
    twilioServiceRef.current.destroyTwilioDevice();
    if (twilioToken && user.customer?.phone_number) {
      twilioServiceRef.current.initializeTwilioDevice(twilioToken);
    }
  }, [twilioToken, user.customer?.phone_number]);

  useEffect(() => {
    if (deviceStatus === twilioDeviceStatuses.OFFLINE) {
      dispatch(getTwilioToken());
    }
  }, [deviceStatus, dispatch]);

  useEffect(() => {
    if (callEvent?.status && twilioServiceRef.current) {
      if (callEvent.status === "ringing") {
        dispatch(setDeviceStatusAction(twilioDeviceStatuses.RINGING));
      }
      if (callEvent.status === "in-progress") {
        dispatch(setDeviceStatusAction(twilioDeviceStatuses.IN_PROGRESS));
        dispatch(setCallScribeType(callEvent.callsid, CallScribeType.Other));
        dispatch(setIsToScribe(callEvent.callsid, scribeCallsRef.current));
      }
      if (
        callEvent.status === "completed" ||
        callEvent.status === "no-answer"
      ) {
        dispatch(setDeviceStatusAction(twilioDeviceStatuses.OFFLINE));
        twilioServiceRef.current.handleDisconnect();
      }
    }
  }, [callEvent?.status, callEvent?.callsid, dispatch]);

  const handleDisconnect = useCallback(() => {
    setIsMuted(false);
    dispatch(setDeviceStatusAction(twilioDeviceStatuses.OFFLINE));
    twilioServiceRef.current.handleDisconnect();
  }, [dispatch]);

  const handleCall = useCallback(() => {
    if (callConversation?.patient.phone_number) {
      dispatch(setOpenCallModalAction(true));
      twilioServiceRef.current.handleCall(
        callConversation.patient.phone_number,
        callConversation.patient.patient_id,
      );
    }
  }, [
    callConversation?.patient.phone_number,
    callConversation?.patient.patient_id,
    dispatch,
  ]);

  const handleMute = useCallback(() => {
    setIsMuted(!isMuted);
    twilioServiceRef.current.toggleMute(!isMuted);
  }, [isMuted]);

  const handleCompleteCall = (patientId: number) => {
    if (!patientId || !selectedConversationId) {
      return;
    }

    moveToCompleted({
      patientId,
      conversationId: Number(selectedConversationId),
    });
    setSelectedConversationId(
      nextConversation?.patient.conversation_id.toString(),
    );
    dispatch(setSelectedItemPatient(nextConversation?.patient));
  };

  const handleMoveToReview = (patientId: number) => {
    if (!patientId || !selectedConversationId) {
      return;
    }

    moveToReview({ conversation: callConversation });
    setSelectedConversationId(
      nextConversation?.patient.conversation_id.toString(),
    );
    dispatch(setSelectedItemPatient(nextConversation?.patient));
  };

  const handleOpenMessageInput = () => {
    dispatch(setIsMessageInputOpen(!isMessageInputOpen));
  };

  return (
    <div className={className}>
      <article
        className="w-full h-full rounded-lg border bg-gray-background2 border-gray-foreground
          grid grid-rows-tab-layout relative overflow-hidden"
      >
        <header className="grid grid-cols-2-right bg-white items-center gap-3 overflow-hidden py-3 px-5">
          <button
            className={buttonStyle}
            onClick={onClose}
            type="button"
            aria-label="Close details"
          >
            <CloseIcon width="16" height="16" className="flex-none" />
          </button>

          <div className="space-y-1">
            <div className="grid grid-cols-conv-layout gap-2">
              <Link to={`${Pages.PATIENTS}/${selectedItemPatient?.patient_id}`}>
                <p className="font-semibold text-left text-xl lg:text-xl mt-0.5 w-full truncate">
                  {selectedItemPatient?.patient_full_name}
                </p>
              </Link>
              <div className="flex space-x-2">
                {callConversation &&
                  selectedItemPatient?.patient_phone_number && (
                    <CallStatusButtons
                      onComplete={handleCompleteCall}
                      onMoveToReview={handleMoveToReview}
                      patientId={callConversation.patient.patient_id}
                      isCompleted={callConversation.completed === 1}
                      conversation={callConversation}
                    />
                  )}

                <button
                  className={`${buttonStyle}
                    ${isMessageInputOpen && selectedItemPatient?.patient_phone_number ? "bg-primary-blue" : ""}`}
                  onClick={handleOpenMessageInput}
                  disabled={
                    !callConversation ||
                    !selectedItemPatient?.patient_phone_number
                  }
                  type="button"
                  aria-label="Messages"
                >
                  <MessageIcon
                    width="16"
                    height="16"
                    className="flex-none"
                    stroke={
                      isMessageInputOpen &&
                      selectedItemPatient?.patient_phone_number
                        ? "white"
                        : "#2970FF"
                    }
                  />
                </button>
                <button
                  className={`${buttonStyle}
                    ${openCallModal ? "bg-primary-blue" : ""}`}
                  onClick={handleCall}
                  disabled={
                    openCallModal || !selectedItemPatient?.patient_phone_number
                  }
                  type="button"
                  aria-label="Call"
                >
                  <CallIcon
                    width="16"
                    height="16"
                    className="flex-none"
                    stroke={openCallModal ? "white" : "#2970FF"}
                    fill={openCallModal ? "white" : "#2970FF"}
                  />
                </button>
              </div>
            </div>
            <div className="grid grid-cols-conv-layout gap-2 h-6">
              {patient && (
                <PatientShortInfo
                  patient={patient}
                  className="max-w-full min-w-0 w-fit truncate text-xs text-tertiary bg-gray-110 rounded py-1 px-3
                  font-semibold"
                  showBirthdate={false}
                />
              )}
              <p className="text-base text-tertiary font-medium">
                {getFormattedPhoneNumber(
                  selectedItemPatient?.patient_phone_number,
                )}
              </p>
            </div>
          </div>
        </header>

        <main className="overflow-hidden grid grid-rows-note-layout">
          {callConversation &&
          callConversation.conversation_id ===
            Number(selectedConversationId) ? (
            <MessageTab
              patientInfo={callConversation.patient}
              messages={messages}
              setMessages={(messages) => dispatch(setMessagesAction(messages))}
              hideInputArea={!isMessageInputOpen}
            />
          ) : (
            <div className="pl-4 pb-4 pr-2 mr-2 overflow-y-auto scrollbar h-full w-full">
              <ChatSkeleton />
            </div>
          )}

          {openCallModal && (
            <CallControlButtons
              onClose={handleDisconnect}
              handleMute={handleMute}
              isMuted={isMuted}
              callSeconds={callSeconds}
            />
          )}
        </main>
      </article>
    </div>
  );
};

export default CallDetails;
