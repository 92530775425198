import { isSameDay } from "../../../helpers/helpers";
import { type Conversation, MessageFrom } from "../../../store/call/interfaces";
import { MessageType } from "../../../store/conversation/interfaces";
import type { Message } from "../../../store/conversation/validationSchemas";

interface MessageDisplayProperties {
  combineWithPrevious: boolean;
  dateDivider: boolean;
}

export const getMessageDisplayProperties = (
  currentMessage: Message,
  index: number,
  conversation: Conversation,
): MessageDisplayProperties => {
  if (index === 0) {
    return {
      combineWithPrevious: false,
      dateDivider: true,
    };
  }

  const previousMessage = conversation.messages[index - 1];
  const sameDay = isSameDay(currentMessage.send_at, previousMessage.send_at);

  if (currentMessage.type === MessageType.Text) {
    const isDoctor = currentMessage.from === MessageFrom.Doctor;
    const isDoctorPrevious = previousMessage.from === MessageFrom.Doctor;
    const isAutomatic = currentMessage.is_automatic === 1;
    const isAutomaticPrevious = previousMessage.is_automatic === 1;

    let combineWithPrevious = false;

    if (sameDay) {
      if (isDoctor && isDoctorPrevious) {
        if (isAutomatic && isAutomaticPrevious) {
          combineWithPrevious = true;
        } else if (!isAutomatic && !isAutomaticPrevious) {
          combineWithPrevious =
            currentMessage.sender.doctor_id ===
            previousMessage.sender.doctor_id;
        }
      } else if (!isDoctor && !isDoctorPrevious) {
        combineWithPrevious = true;
      }
    }

    return {
      combineWithPrevious,
      dateDivider: !sameDay,
    };
  }

  return {
    combineWithPrevious: false,
    dateDivider: !sameDay,
  };
};
