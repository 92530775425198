import { ReactComponent as MessageIcon } from "@assets/icons/chat-round.svg";
import { ReactComponent as HeartIcon } from "@assets/icons/heart.svg";
import { ReactComponent as ScribeIcon } from "@assets/icons/microphone-scribe.svg";
import { ReactComponent as MinimizeIcon } from "@assets/icons/minimize-square.svg";
import { ReactComponent as CallIcon } from "@assets/icons/phone-new.svg";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  patientPanelTabs,
  twilioDeviceStatuses,
} from "../../helpers/constants";
import { getFormattedTimeWithSeconds } from "../../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  useGetConversationQuery,
  useMoveToCompletedMutation,
  useMoveToReviewMutation,
} from "../../store/call/api";
import {
  getIsMessageInputOpen,
  setIsMessageInputOpen,
} from "../../store/call/callSlice";
import { FeatureFlags, useFeature } from "../../store/featureFlagSlice";
import type { ConversationPatient } from "../../store/patient/interfaces";
import {
  setOpenCallModalAction,
  setOpenCallOptionsModalAction,
} from "../../store/voiceRecorder/actions";
import AddPhoneNumber from "../Basic/AddPhoneNumber";
import TabButton from "../Basic/TabButton";
import RecordingContextStalled from "../Basic/Warning/RecordingContextStalled";
import CallControlButtons from "../Inbox/RightPanel/CallControlButtons";
import CallStatusButtons from "../Inbox/RightPanel/CallStatusButtons";
import useConversationSearchParams from "../Inbox/hooks/useConversationSearchParams";
import RecordingIndicator from "../IndependentScribe/RecordingIndicator";
import TimerProvider from "../IndependentScribe/TimerProvider";
import useScribeSearchParams from "../IndependentScribe/hooks/useScribeSearchParams";
import { useTimerDisplay } from "../IndependentScribe/hooks/useTimerDisplay";
import {
  type FixLater,
  setInterruptedRecordingAction,
} from "../IndependentScribe/store/scribeSlice";
import {
  getRecordingScribe,
  selectScribeById,
} from "../IndependentScribe/store/selectors";
import { SetInterruptedRecordingActionType } from "../IndependentScribe/store/types";
import PatientShortInfo from "../PatientProfile/PatientShortInfo";
import Call from "../PatientProfile/RightPanel/Call/Call";
import CallOptions from "../PatientProfile/RightPanel/Call/CallOptions";
import MessageTab from "../PatientProfile/RightPanel/Message/MessageTab";
import PatientScribe from "../PatientProfile/RightPanel/Scribe/PatientScribe";

const buttonStyle =
  "w-8 h-8 rounded-lg border border-gray-110 flex items-center justify-center";

const ChatCallScribePanel = ({
  handleCall,
  handleDisconnect,
  handleMute,
  isMuted,
  patient,
  selectedTab,
  setSelectedTab,
  messages,
  setMessages,
  scribeTab = false,
  notEnoughTranscript,
  setNotEnoughTranscript,
  cancelRecording,
  togglePause,
  isStalled,
  audioContext,
  mediaRecorder,
  microphones = [],
  microphoneId,
  setMicrophoneId,
  startRecording,
  saveRecording,
  onCancelScribe,
  isReadyToRecord,
  readyToRecordErrors,
  requestMicrophonePermissions,
  showNoteGenerationDelayedWarning,
  hideNoteGenerationDelayedWarning,
  className = "md:rounded-xl",
  showBottomCompleteButton,
  inboxCall = false,
}: {
  handleCall: (phoneNumber: string, patientId: number) => void;
  handleDisconnect: () => void;
  handleMute: () => void;
  isMuted: boolean;
  patient: ConversationPatient;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  messages: FixLater;
  setMessages: (messages: any) => void;
  scribeTab?: boolean;
  notEnoughTranscript?: boolean;
  setNotEnoughTranscript?: (value: boolean) => void;
  cancelRecording?: () => void;
  togglePause?: () => void;
  isStalled?: boolean;
  audioContext?: AudioContext;
  mediaRecorder?: MediaRecorder | null;
  microphones?: { text: string; value: string }[];
  microphoneId?: string;
  setMicrophoneId?: (value: string) => void;
  startRecording?: () => void;
  saveRecording?: () => void;
  onCancelScribe?: () => void;
  isReadyToRecord?: boolean;
  readyToRecordErrors?: string[];
  requestMicrophonePermissions?: () => void;
  showNoteGenerationDelayedWarning?: boolean;
  hideNoteGenerationDelayedWarning?: () => void;
  className?: string;
  showBottomCompleteButton?: boolean;
  inboxCall?: boolean;
}) => {
  const { openCallModal, deviceStatus } = useAppSelector(
    (state) => state.voiceRecorder,
  );
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const isCCM = useFeature(FeatureFlags.CCM);
  const isMessageInputOpen = useAppSelector(getIsMessageInputOpen);
  const { selectedScribeId, setSelectedScribeId } = useScribeSearchParams();
  const selectedScribe = useAppSelector((state) =>
    selectScribeById(state, selectedScribeId),
  );
  const recordingScribe = useAppSelector(getRecordingScribe);
  const { selectedConversationId } = useConversationSearchParams();

  const { data: conversation } = useGetConversationQuery(
    patient?.phone_number && selectedConversationId
      ? Number(selectedConversationId)
      : skipToken,
  );

  const [moveToReview] = useMoveToReviewMutation();
  const [moveToCompleted] = useMoveToCompletedMutation();

  const recordingAndNotOnScribeTab =
    selectedScribe?.isRecording && selectedTab !== patientPanelTabs.SCRIBE;

  const callingAndNotOnCallTab =
    deviceStatus === twilioDeviceStatuses.IN_PROGRESS &&
    selectedTab !== patientPanelTabs.CALL;
  const callInterval = useRef(null);

  const { formattedTime } = useTimerDisplay(
    selectedScribe?.isRecording,
    selectedScribe?.startedAt,
    selectedScribe?.accumulatedDuration,
  );

  const [callSeconds, setCallSeconds] = useState(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (recordingScribe) {
      setNotEnoughTranscript(false);
    }
  }, [recordingScribe, setNotEnoughTranscript]);

  useEffect(() => {
    if (callInterval.current) {
      clearInterval(callInterval.current);
      callInterval.current = null;
    }

    if (deviceStatus !== twilioDeviceStatuses.IN_PROGRESS) {
      setCallSeconds(0);

      return;
    }

    callInterval.current = setInterval(() => {
      setCallSeconds((callSeconds) => callSeconds + 1);
    }, 1000);

    return () => {
      if (callInterval.current) {
        clearInterval(callInterval.current);
        callInterval.current = null;
      }
    };
  }, [deviceStatus]);

  const onCall = () => {
    if (recordingScribe) {
      dispatch(
        setInterruptedRecordingAction({
          type: SetInterruptedRecordingActionType.PATIENT_CALL,
        }),
      );
      return;
    }

    dispatch(setOpenCallModalAction(true));
    setSelectedScribeId(null);
    handleCall(patient?.phone_number, patient?.patient_id);
  };

  const handleOpenMessageInput = () => {
    dispatch(setIsMessageInputOpen(!isMessageInputOpen));
  };

  const handleCompleteCall = (patientId: number) => {
    if (!patientId) {
      return;
    }

    moveToCompleted({
      patientId,
      conversationId: Number(selectedConversationId),
    });
  };

  const handleMoveToReview = (patientId: number) => {
    if (!patientId) {
      return;
    }

    moveToReview({ conversation: conversation });
  };

  const countTabs = () => {
    return [scribeTab, isCCM].filter(Boolean).length + 1;
  };

  return (
    <>
      <div
        className={`bg-white md:border border-gray-200 w-full h-full grid grid-rows-tab-layout overflow-hidden
          ${className}`}
      >
        <div>
          <div className="md:hidden grid items-start grid-cols-conv-layout w-full min-w-0 px-4 py-3 border-b truncate">
            <div className="truncate">
              {patient && (
                <>
                  <p className="font-semibold text-base mt-1">
                    {patient.preferred_name
                      ? patient.preferred_name
                      : patient.first_name}{" "}
                    {patient.last_name}
                  </p>
                  <PatientShortInfo
                    patient={patient}
                    className="mt-1 pb-0.5 truncate text-xs text-tertiary"
                  />
                </>
              )}
            </div>
            <button
              type="button"
              className="w-6 h-8 md:w-8 flex items-center justify-center flex-none"
              onClick={() => {
                if (!isDesktop && recordingScribe) {
                  dispatch(
                    setInterruptedRecordingAction({
                      type: SetInterruptedRecordingActionType.CLOSE_PANEL_WHILE_RECORDING,
                    }),
                  );

                  return;
                }

                dispatch(setOpenCallOptionsModalAction(false));
              }}
            >
              <MinimizeIcon width="24" height="24" stroke="#121212" />
            </button>
          </div>

          <div
            className={`${countTabs() <= 1 ? "hidden h-0 p-0" : `grid h-[52px] lg:h-16 p-2 border-b grid-cols-${countTabs()}`}`}
          >
            <TabButton
              icon={
                <MessageIcon
                  width="20"
                  height="20"
                  stroke={
                    selectedTab === patientPanelTabs.MESSAGE
                      ? "#2970FF"
                      : "#667085"
                  }
                />
              }
              label={patientPanelTabs.MESSAGE}
              active={selectedTab}
              onClick={() => setSelectedTab(patientPanelTabs.MESSAGE)}
            />
            {isCCM && (
              <TabButton
                rightIcon={
                  callingAndNotOnCallTab ? (
                    <RecordingIndicator
                      size={20}
                      isRecording={callingAndNotOnCallTab}
                    />
                  ) : null
                }
                icon={
                  <HeartIcon
                    width="20"
                    height="20"
                    stroke={
                      selectedTab === patientPanelTabs.CALL
                        ? "#2970FF"
                        : "#667085"
                    }
                    fill={
                      selectedTab === patientPanelTabs.CALL
                        ? "#2970FF"
                        : "#667085"
                    }
                  />
                }
                label={
                  callingAndNotOnCallTab
                    ? getFormattedTimeWithSeconds(callSeconds)
                    : patientPanelTabs.CALL
                }
                active={selectedTab}
                onClick={() => setSelectedTab(patientPanelTabs.CALL)}
              />
            )}
            {scribeTab && (
              <TimerProvider>
                <TabButton
                  rightIcon={
                    recordingAndNotOnScribeTab ? (
                      <RecordingIndicator
                        size={20}
                        isRecording={selectedScribe.isRecording}
                      />
                    ) : null
                  }
                  icon={
                    <ScribeIcon
                      width="20"
                      height="20"
                      stroke={
                        selectedTab === patientPanelTabs.SCRIBE
                          ? "#2970FF"
                          : "#667085"
                      }
                    />
                  }
                  label={
                    recordingAndNotOnScribeTab
                      ? formattedTime
                      : patientPanelTabs.SCRIBE
                  }
                  active={selectedTab}
                  onClick={() => setSelectedTab(patientPanelTabs.SCRIBE)}
                />
              </TimerProvider>
            )}
          </div>
        </div>
        <div className="flex overflow-hidden">
          {selectedTab === patientPanelTabs.CALL &&
            (openCallModal ? (
              <div className="overflow-y-auto scrollbar h-full w-full">
                <Call
                  onClose={handleDisconnect}
                  handleMute={handleMute}
                  isMuted={isMuted}
                  patient={patient}
                  callSeconds={callSeconds}
                />
              </div>
            ) : patient?.phone_number ? (
              <div className="pl-5 py-3 height-sm:py-5 pr-2 bg-gray-background2 h-full w-full">
                <CallOptions
                  className="h-full min-h-0 overflow-y-auto scrollbar pr-3"
                  onCall={onCall}
                  patient={patient}
                />
              </div>
            ) : (
              <AddPhoneNumber patientId={patient?.patient_id} call={true} />
            ))}

          {selectedTab === patientPanelTabs.MESSAGE && (
            <div className="grid grid-rows-tab-layout overflow-hidden h-full w-full">
              {inboxCall && patient?.phone_number ? (
                <div className="flex w-full items-center justify-between bg-white py-3 px-5 space-x-2">
                  <div className="flex items-center space-x-2">
                    <button
                      className={`${buttonStyle}
                        ${isMessageInputOpen ? "bg-primary-blue" : ""}`}
                      onClick={handleOpenMessageInput}
                      disabled={!conversation}
                      type="button"
                      aria-label="Messages"
                    >
                      <MessageIcon
                        width="16"
                        height="16"
                        className="flex-none"
                        stroke={isMessageInputOpen ? "white" : "#2970FF"}
                      />
                    </button>
                    <button
                      className={`${buttonStyle}
                        ${openCallModal ? "bg-primary-blue" : ""}`}
                      onClick={onCall}
                      disabled={!patient || !conversation || openCallModal}
                      type="button"
                      aria-label="Call"
                    >
                      <CallIcon
                        width="16"
                        height="16"
                        className="flex-none"
                        stroke={openCallModal ? "white" : "#2970FF"}
                        fill={openCallModal ? "white" : "#2970FF"}
                      />
                    </button>
                  </div>
                  {conversation && (
                    <CallStatusButtons
                      onComplete={handleCompleteCall}
                      onMoveToReview={handleMoveToReview}
                      patientId={conversation.patient.patient_id}
                      isCompleted={conversation.completed === 1}
                      conversation={conversation}
                    />
                  )}
                </div>
              ) : (
                <div />
              )}
              <MessageTab
                patientInfo={patient}
                messages={messages}
                setMessages={setMessages}
                showBottomCompleteButton={showBottomCompleteButton}
                hideInputArea={!isMessageInputOpen && inboxCall}
              />
              {openCallModal && inboxCall && (
                <CallControlButtons
                  onClose={handleDisconnect}
                  handleMute={handleMute}
                  isMuted={isMuted}
                  callSeconds={callSeconds}
                />
              )}
            </div>
          )}

          {selectedTab === patientPanelTabs.SCRIBE && (
            <PatientScribe
              onCancel={onCancelScribe}
              audioContext={audioContext}
              mediaRecorder={mediaRecorder}
              togglePause={togglePause}
              cancelRecording={cancelRecording}
              saveRecording={saveRecording}
              startRecording={startRecording}
              microphoneId={microphoneId}
              setMicrophoneId={setMicrophoneId}
              microphones={microphones}
              notEnoughTranscript={notEnoughTranscript}
              setNotEnoughTranscript={setNotEnoughTranscript}
              isReadyToRecord={isReadyToRecord}
              readyToRecordErrors={readyToRecordErrors}
              requestMicrophonePermissions={requestMicrophonePermissions}
              isStalled={isStalled}
              showNoteGenerationDelayedWarning={
                showNoteGenerationDelayedWarning
              }
              hideNoteGenerationDelayedWarning={
                hideNoteGenerationDelayedWarning
              }
            />
          )}
        </div>
      </div>

      {isStalled &&
        (recordingScribe?.isRecording || !recordingScribe?.isRecording) &&
        !isDesktop && (
          <RecordingContextStalled
            // onCancel={handleTogglePause}
            // onSubmit={() => {
            //   togglePause();
            //   cancelRecording();
            //   onCancel();
            // }}
            onSubmit={() => window.location.reload()}
          />
        )}
    </>
  );
};

export default ChatCallScribePanel;
