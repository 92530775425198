import { skipToken } from "@reduxjs/toolkit/query";
import { type FC, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { Pages, convPerPage } from "../../../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { useGetConversationQuery } from "../../../../store/call/api";
import {
  setConversationsUpdatedAction,
  setFiltersUpdatedAction,
} from "../../../../store/conversation/actions";
import { searchConversationsWithFilters } from "../../../../store/conversation/thunks";
import { FeatureFlags, useFeature } from "../../../../store/featureFlagSlice";
import type { ConversationPatient } from "../../../../store/patient/interfaces";
import { useGetTeamsQuery, useGetUsersQuery } from "../../../../store/user/api";
import AddPhoneNumber from "../../../Basic/AddPhoneNumber";
import {
  type MentionItem,
  type MentionSeparator,
  MentionType,
} from "../../../Inbox/Mentions/interfaces";
import InboxChat from "../../../Inbox/RightPanel/InboxChat";
import InboxInputArea from "../../../Inbox/RightPanel/InboxInputArea";
import useConversationSearchParams from "../../../Inbox/hooks/useConversationSearchParams";
import MessagingChat from "../../../Messaging/Conversation/MessagingChat";
import MessagingInputArea from "../../../Messaging/Conversation/MessagingInputArea";
import ChatSkeleton from "../../../Skeletons/ChatSkeleton/ChatSkeleton";

type MessageTabProps = {
  patientInfo: ConversationPatient;
  messages: Record<string, string>;
  setMessages: (messages: Record<string, string>) => void;
  showBottomCompleteButton?: boolean;
  hideInputArea?: boolean;
};

const MessageTab: FC<MessageTabProps> = ({
  patientInfo,
  messages,
  setMessages,
  showBottomCompleteButton,
  hideInputArea = false,
}) => {
  const frontDeskInbox = useFeature(FeatureFlags.FRONT_DESK_INBOX);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { selectedConversationId } = useConversationSearchParams();
  const conversationSearchInput = useAppSelector(
    (state) => state.conversation.conversationSearchInput,
  );
  const activeInbox = useAppSelector((state) => state.conversation.activeInbox);
  const activeFilters = useAppSelector(
    (state) => state.conversation.activeFilters,
  );
  const convCurrPage = useAppSelector(
    (state) => state.conversation.convCurrPage,
  );
  const conversationsUpdated = useAppSelector(
    (state) => state.conversation.conversationsUpdated,
  );
  const selectedDoctorInbox = useAppSelector(
    (state) => state.conversation.selectedDoctorInbox,
  );

  const { data: currentConversation } = useGetConversationQuery(
    patientInfo?.phone_number && selectedConversationId
      ? Number(selectedConversationId)
      : skipToken,
  );

  const { data: users = [] } = useGetUsersQuery({
    search: "",
  });

  const { data: teams = [] } = useGetTeamsQuery({
    search: "",
  });

  const [scrolledUp, setScrolledUp] = useState(false);
  const [showResponse, setShowResponse] = useState(false);

  const updateMessagingConversations = useCallback(() => {
    dispatch(
      searchConversationsWithFilters(
        conversationSearchInput,
        activeFilters,
        1,
        convPerPage * convCurrPage,
        activeInbox,
        selectedDoctorInbox,
        () => dispatch(setFiltersUpdatedAction(true)),
      ),
    );
  }, [
    dispatch,
    conversationSearchInput,
    activeFilters,
    convCurrPage,
    activeInbox,
    selectedDoctorInbox,
  ]);

  // Memoize mention items to prevent unnecessary recalculations
  const mentionItems = useMemo(() => {
    const userMentions: MentionItem[] = users
      .map((user) => ({
        id: `doctor-${user.doctor_id}`,
        display:
          user.display_name?.trim() ||
          `${user.first_name?.trim() || ""} ${user.last_name?.trim() || "-"}`,
        type: MentionType.USER,
        profile_picture: user.profile_picture,
      }))
      .sort((a, b) => a.display.localeCompare(b.display));

    const teamMentions: MentionItem[] = teams
      .map((team) => ({
        id: `team-${team.doctor_team_id}`,
        display: team.name?.trim() || "-",
        type: MentionType.TEAM,
        team_icon: team.team_icon,
      }))
      .sort((a, b) => a.display.localeCompare(b.display));

    return [
      ...userMentions,
      {
        id: "separator",
        display: "Separator",
        type: MentionType.SEPARATOR,
      } as MentionSeparator,
      ...teamMentions,
    ];
  }, [users, teams]);

  const updateConversations = useCallback(() => {
    dispatch(setConversationsUpdatedAction(true));

    // old inbox
    if (location.pathname === Pages.MESSAGING) {
      updateMessagingConversations();
    }
  }, [dispatch, location, updateMessagingConversations]);

  useEffect(() => {
    dispatch(setConversationsUpdatedAction(false));
  }, [dispatch]);

  useEffect(() => {
    if (!conversationsUpdated) {
      updateConversations();
    }
  }, [conversationsUpdated, updateConversations]);

  const InboxPage = useMemo(
    () => (
      <>
        <InboxChat
          scrolledUp={scrolledUp}
          setScrolledUp={setScrolledUp}
          showResponse={showResponse}
          currentConversation={currentConversation}
          selectedConversationId={selectedConversationId}
          mentionItems={mentionItems}
        />
        <div className={hideInputArea ? "hidden" : ""}>
          <InboxInputArea
            setScrolledUp={setScrolledUp}
            messages={messages}
            setMessages={setMessages}
            showResponse={showResponse}
            setShowResponse={setShowResponse}
            currentConversation={currentConversation}
            selectedConversationId={selectedConversationId}
            showCompleteButton={showBottomCompleteButton}
            mentionItems={mentionItems}
          />
        </div>
      </>
    ),
    [
      scrolledUp,
      showResponse,
      currentConversation,
      hideInputArea,
      messages,
      showBottomCompleteButton,
      mentionItems,
      setMessages,
      selectedConversationId,
    ],
  );

  const MessagingPage = useMemo(
    () => (
      <>
        <MessagingChat
          scrolledUp={scrolledUp}
          setScrolledUp={setScrolledUp}
          showResponse={showResponse}
          currentConversation={currentConversation}
          selectedConversationId={selectedConversationId}
          mentionItems={mentionItems}
        />
        <div className={hideInputArea ? "hidden" : ""}>
          <MessagingInputArea
            setScrolledUp={setScrolledUp}
            messages={messages}
            setMessages={setMessages}
            showResponse={showResponse}
            setShowResponse={setShowResponse}
            currentConversation={currentConversation}
            selectedConversationId={selectedConversationId}
          />
        </div>
      </>
    ),
    [
      scrolledUp,
      showResponse,
      currentConversation,
      mentionItems,
      hideInputArea,
      messages,
      setMessages,
      selectedConversationId,
    ],
  );

  if (!patientInfo?.phone_number) {
    return <AddPhoneNumber patientId={patientInfo?.patient_id} />;
  }

  return (
    <div className="min-h-0 h-full w-full grid grid-rows-note-layout bg-gray-background2 overflow-hidden">
      {frontDeskInbox &&
      (location.pathname === Pages.INBOX ||
        location.pathname.includes(Pages.PATIENTS)) ? (
        currentConversation ? (
          InboxPage
        ) : (
          <ChatSkeleton className="px-4" />
        )
      ) : currentConversation ? (
        MessagingPage
      ) : (
        <ChatSkeleton className="px-4" />
      )}
    </div>
  );
};

export default MessageTab;
