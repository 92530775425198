import { ReactComponent as ScribeIcon } from "@assets/icons/document-round-bold.svg";
import { ReactComponent as ScribeDisabledIcon } from "@assets/icons/document-round-cross.svg";
import { ReactComponent as MutedIcon } from "@assets/icons/muted.svg";
import { ReactComponent as PhoneIcon } from "@assets/icons/phone-x.svg";
import { ReactComponent as RecordingPausedIcon } from "@assets/icons/recording-paused.svg";
import { ReactComponent as RecordingIcon } from "@assets/icons/recording.svg";
import { ReactComponent as UnmutedIcon } from "@assets/icons/unmuted.svg";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { twilioDeviceStatuses } from "../../../../helpers/constants";
import {
  getFormattedPhoneNumber,
  getFormattedTimeWithSeconds,
} from "../../../../helpers/helpers";
import { setScribeCallsAction } from "../../../../store/voiceRecorder/actions";
import { setIsToScribe } from "../../../../store/voiceRecorder/thunks";
import LoaderDotsSmall from "../../../Basic/LoaderDotsSmall";

const Call = ({ onClose, handleMute, isMuted, patient, callSeconds }) => {
  const { deviceStatus, callEvent, scribeCalls } = useSelector(
    (state) => state.voiceRecorder,
  );
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const iconSize = isDesktop ? 40 : 30;

  const handleToggleScribe = () => {
    if (callEvent.callsid) {
      dispatch(setIsToScribe(callEvent.callsid, !scribeCalls));
    }
    dispatch(setScribeCallsAction(!scribeCalls));
  };

  return (
    <div className="w-full grid grid-rows-call items-center h-full relative bg-primary-blue-light">
      <div className="flex flex-col items-center p-4 h-[104px] md:h-[116px]">
        <div className="text-xl font-medium mb-2">
          {deviceStatus === twilioDeviceStatuses.IN_PROGRESS ? (
            "Call Time"
          ) : deviceStatus === twilioDeviceStatuses.READY ? (
            <div className="flex items-end space-x-2">
              <p>Calling</p>
              <LoaderDotsSmall color="primary" size="1" className="mb-2" />
            </div>
          ) : (
            deviceStatus
          )}
        </div>
        {deviceStatus === twilioDeviceStatuses.IN_PROGRESS && (
          <p className="text-3xl md:text-5xl font-semibold text-violet-550">
            {getFormattedTimeWithSeconds(callSeconds)}
          </p>
        )}
      </div>

      <div className="p-4 bg-white rounded-t-20 min-h-fit h-full w-full flex flex-col items-center justify-center space-y-10">
        <div className="flex flex-col items-center justify-end relative h-[86px]">
          <div
            className={`absolute top-0 left-1/2 -translate-x-1/2 rounded-full pl-2 pr-3 py-1
                flex items-center space-x-2
                ${scribeCalls ? "bg-pink-150" : "bg-gray-background"}`}
          >
            {scribeCalls ? (
              <RecordingIcon width="16" height="16" className="flex-none" />
            ) : (
              <RecordingPausedIcon
                width="16"
                height="16"
                className="flex-none"
              />
            )}
            <p className="text-xs font-medium text-zinc-700 whitespace-nowrap">
              {scribeCalls ? "Scribing" : "Scribing Paused"}
            </p>
          </div>
          <div className="text-2xl md:text-[32px] font-semibold whitespace-nowrap">
            <p>{getFormattedPhoneNumber(patient.phone_number)}</p>
          </div>
        </div>

        <div className="w-full grid grid-cols-2 divide-x">
          <button
            type="button"
            className="p-3 w-full flex flex-col flex-none items-center justify-center relative"
            onClick={handleMute}
          >
            {isMuted ? (
              <MutedIcon
                width={iconSize}
                height={iconSize}
                className="m-3 flex-none"
                stroke="#667085"
              />
            ) : (
              <UnmutedIcon
                width={iconSize}
                height={iconSize}
                className="m-3 flex-none"
                stroke="#2970FF"
              />
            )}
            <p>{isMuted ? "Unmute" : "Mute"}</p>
          </button>

          <button
            type="button"
            className="p-3 w-full flex flex-col flex-none items-center justify-center"
            onClick={handleToggleScribe}
          >
            {scribeCalls ? (
              <ScribeIcon
                width={iconSize}
                height={iconSize}
                className="m-3 flex-none"
                stroke="#2970FF"
              />
            ) : (
              <ScribeDisabledIcon
                width={iconSize}
                height={iconSize}
                className="m-3 flex-none"
                stroke="#121212"
              />
            )}
            <p>Scribe</p>
          </button>
        </div>

        <button
          type="button"
          className="w-16 h-16 md:w-20 md:h-20 flex flex-none rounded-full items-center justify-center bg-rose-550"
          onClick={onClose}
        >
          <PhoneIcon width={iconSize} height={iconSize} />
        </button>
      </div>
    </div>
  );
};

export default Call;
