import { ReactComponent as ArrowIcon } from "@assets/icons/arrow-left.svg";

const EnrollmentEditHeader = ({ onClose, enrollment, className = "" }) => {
  return (
    <div
      className={`flex items-center w-full bg-white px-4 py-3 space-x-2 md:space-x-4 border-b
        md:rounded-t-xl md:border-b-0 md:border-x md:border-t md:mt-3 md:py-5
        ${className}`}
    >
      <button
        type="button"
        onClick={onClose}
        className="w-6 h-8 md:w-8 flex items-center justify-center"
      >
        <ArrowIcon width="14" height="14" stroke="#121212" />
      </button>
      <p className="text-base lg:text-xl font-semibold">
        {enrollment.campaign.campaign}
      </p>
    </div>
  );
};

export default EnrollmentEditHeader;
