import noSoundDetectedImage from "@assets/no-sound-detected.svg";
import notEnoughTranscriptImage from "@assets/not-enough-transcript.svg";
import noteGenerationDelayedImage from "@assets/note-generation-delayed.svg";
import type { Banner, BannerId } from "./types";

export const NOTE_NOT_SELECTED = "";
export const NOTE_RECORDING_ID = -1;
export const ERROR_NOT_ENOUGH_TRANSCRIPT =
  "3c2f4d99-2266-4b60-bcdf-6f497a12afda";
export const ERROR_NOTE_GENERATION_DELAYED =
  "ba15483c-e25e-4fd1-b401-1985b4a49cc3";
export const MOCK_PATIENT_FIRST_NAME = "018ee44a-5aa1-74b5-b385-a3a994a769b5";

export const BANNER_CONFIGS: Record<BannerId, Omit<Banner, "onSubmit">> = {
  microphoneAccessLost: {
    id: "microphoneAccessLost",
    image: noSoundDetectedImage,
    title: "Microphone Access Lost",
    description:
      "Recording stopped when the app was minimized or call received. To capture conversation, keep app visible and decline calls",
    submitText: "Try again",
  },
  notEnoughTranscript: {
    id: "notEnoughTranscript",
    image: notEnoughTranscriptImage,
    title: "Short Visit",
    description: "Continue speaking to complete note",
    submitText: "Continue",
  },
  noteGenerationDelayed: {
    id: "noteGenerationDelayed",
    image: noteGenerationDelayedImage,
    title: "Note Generation Delayed",
    description: "Please check back later today",
    submitText: "",
  },
};
