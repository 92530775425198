import Bugsnag from "@bugsnag/js";
import { z } from "zod";
import type { FixLater } from "../components/IndependentScribe/store/scribeSlice";

const formatZodValidationErrors = (
  validationErrors: z.ZodError["errors"],
): Array<{ path: string; message: string }> => {
  if (!validationErrors?.length) {
    return [];
  }
  return validationErrors.map((err) => ({
    path: err.path.join("."),
    message: err.message,
  }));
};

export const handleValidation = (
  schema: z.ZodSchema,
  data: FixLater,
  functionName: string,
) => {
  const validationResult = schema.safeParse(data);
  if (!validationResult.success) {
    console.error(
      `Validation failed (${functionName}):`,
      validationResult.error.errors,
    );

    const zodErrors = formatZodValidationErrors(validationResult.error.errors);
    Bugsnag.notify(
      new Error(`Zod validation error in ${functionName}`),
      (event) => {
        event.addMetadata("validation", {
          errors: zodErrors,
        });
      },
    );
  }

  return validationResult.data;
};

export const zeroOrOneSchema = z.union([z.literal(0), z.literal(1)]);

export const providerSchema = z
  .object({
    doctor_id: z.number(),
    display_name: z.string().nullable(),
    first_name: z.string().nullable(),
    last_name: z.string().nullable(),
    profile_picture: z.string().nullable(),
    occupation: z.string().nullable(),
    phone_number: z.string().nullable(),
    email: z.string(),
  })
  .strict();

export const baseDoctorSchema = providerSchema.extend({
  created_at: z.string(),
  customer_id: z.number(),
  deleted_at: z.string().nullable(),
  note_template_id: z.number().nullable(),
  npi: z.string().nullable(),
  organization_id: z.number(),
  role_id: z.number(),
  signup_ehr_name: z.string().nullable(),
  status: z.string(),
  terms_signed_at: z.string().nullable(),
  updated_at: z.string().nullable(),
});

export const doctorSchema = baseDoctorSchema.extend({
  password: z.string().nullable(),
  reset_token: z.string().nullish(),
});

export const patientSchema = z.object({
  address_line_1: z.string().nullable(),
  address_line_2: z.string().nullable(),
  ai_chat: zeroOrOneSchema.nullable(),
  birthdate: z.string(),
  campaign_id: z.number().nullable(),
  chart_number: z.string().nullable(),
  city: z.string().nullable(),
  conditions: z.string().nullable(),
  consent: zeroOrOneSchema.nullable(),
  country: z.string().nullable(),
  created_at: z.string(),
  customer_id: z.number(),
  doctor_id: z.number().nullable(),
  ehr_id: z.string().nullable(),
  email: z.string().nullable(),
  ethnicity: z.string().nullable(),
  ethnicity_code: z.string().nullable(),
  first_name: z.string(),
  gender: z.string().nullable(),
  goals: z.string().nullable(),
  is_eligible_ccm: zeroOrOneSchema.nullable(),
  is_paused: zeroOrOneSchema,
  language: z.string().nullable(),
  languages: z.string().nullable(),
  last_name: z.string(),
  middle_name: z.string().nullable(),
  npi: z.string().nullable(),
  outreach_campaign: z.string().nullable(),
  past_medical_history: z.string().nullable(),
  patient_id: z.number(),
  pharmacy_address: z.string().nullable(),
  pharmacy_name: z.string().nullable(),
  pharmacy_phone: z.string().nullable(),
  phone_home: z.string().nullable(),
  phone_number: z.string().nullable(),
  preferred_name: z.string().nullable(),
  // todo rename to sites when renamed on backend
  primary_facility: z.string().nullable(),
  primary_language: z.string().nullable(),
  profile_picture: z.string().nullable(),
  pronouns: z.string().nullable(),
  race: z.string().nullable(),
  race_code: z.string().nullable(),
  sex: z.string().nullable(),
  sexual_orientation: z.string().nullable(),
  social_security_number: z.string().nullable(),
  state: z.string().nullable(),
  updated_at: z.string().nullable(),
  zipcode: z.string().nullable(),
});

const scribePatientSchema = z
  .object({
    patient_id: z.number(),
    first_name: z.string().nullable(),
    last_name: z.string().nullable(),
    birthdate: z.string().nullable(),
    gender: z.string().nullable(),
    pronouns: z.string().nullable(),
    phone_number: z.string().nullish(),
    profile_picture: z.string().nullish(),
  })
  .strict();

export const scribeSchema = z
  .object({
    audio_id: z.union([z.string(), z.number()]),
    note_id: z.number().nullable(),
    audio_status: z.string(),
    started_at: z.string(),
    finished_at: z.string().nullable(),
    duration: z.number().min(0).nullable(),
    title: z.string().nullable(),
    free_text: z.string().nullable(),
    patient_id: z.number().nullable(),
    patient: scribePatientSchema.nullable(),
    note: z.string().nullable(),
    type: z.enum(["scribe", "call", "campaign"]),
    provider_id: z.number().nullish(),
    provider_name: z.string().nullish(),
  })
  .strict();

export const baseConversationSchema = z.object({
  campaign_id: z.number().nullable(),
  completed: zeroOrOneSchema,
  consent_status: z.string(),
  conversation_id: z.number(),
  differential_diagnoses: z.string().nullable(),
  ehr_information: z.string().nullable(),
  generate_note_enabled: z.union([z.boolean(), zeroOrOneSchema]),
  last_message_date: z.string().nullable(),
  note: z.string().nullable(),
  outreach_focus: z.string().nullable(),
  outreach_goals: z.string().nullable(),
  state: z.number(),
  status: z.string(),
  suggested_answer: z.string().nullable(),
  summary: z.string().nullable(),
  waiting_response: zeroOrOneSchema,
});

export const rolesSchema = z.object({
  1: z.literal("Admin"),
  2: z.literal("User"),
});
