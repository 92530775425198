import { ReactComponent as ArrowRight } from "@assets/icons/arrow-right.svg";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  ehrOptions,
  medicalSpecialtiesOptions,
} from "../../../../helpers/constants";
import { setPracticeInfo } from "../../../../store/auth/actions";
import Button from "../../../Basic/Button";
import Select from "../../../Basic/Select";

const selects = [
  {
    id: "medicalSpecialty",
    label: "Medical Specialty",
    placeholder: "Enter your specialty here",
    options: medicalSpecialtiesOptions,
  },
  {
    id: "ehr",
    label: "EHR",
    placeholder: "Select an EHR",
    options: ehrOptions,
  },
];

const selectContainerClass = `[&>p:first-child]:text-primary [&>p:first-child]:font-semibold [&>p:first-child>span]:text-[#FF1744]
    [&>option:first-of-type]:font-medium [&>option:first-of-type]:text-gray-placeholder [&>p:first-child]:md:mb-2`;

const FirstStepForm = () => {
  const dispatch = useDispatch();
  const [activeSelects, setActiveSelects] = useState([]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      medicalSpecialty: "",
      ehr: "",
    },
  });

  const handleSelectChange = (e, name) => {
    setActiveSelects([...activeSelects, name]);
    register(name).onChange(e);
  };

  const onSubmit = ({ medicalSpecialty, ehr }) => {
    dispatch(setPracticeInfo({ specialty: medicalSpecialty, ehr }));
  };

  return (
    <form
      className="bg-white py-8 md:py-10 px-6 md:px-10
        rounded-[16px] shadow-[0px_1px_3px_0px_#0000000D]"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="font-semibold text-xl text-tertiary mb-5 md:mb-10 text-center">
        Tell us more about your practice
      </div>
      <div className="space-y-4 md:space-y-6 mb-4 md:mb-6">
        {selects.map((select) => (
          <Select
            key={select.id}
            label={select.label}
            placeholder={select.placeholder}
            options={select.options}
            name={select.id}
            register={register}
            error={errors[select.id]}
            selectClassName={`h-12 py-3 pl-4 !pr-12 ${activeSelects.includes(select.id) ? "text-primary" : "text-gray-placeholder"}`}
            className={selectContainerClass}
            optionClass="text-primary"
            borderColor="gray-foreground"
            onChange={(e) => handleSelectChange(e, select.id)}
            defaultValue=""
            required
          />
        ))}
      </div>
      <Button
        type="submit"
        className="flex gap-2 items-center w-full text-base font-semibold justify-center
          transition-all duration-300 hover:bg-primary-blue-hover mt-[140px]"
      >
        Next <ArrowRight />
      </Button>
    </form>
  );
};

export default FirstStepForm;
