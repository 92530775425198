import { ReactComponent as EditIcon } from "@assets/icons/edit-pen.svg";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../store";
import { useGetTeamsQuery, useGetUsersQuery } from "../../../../store/user/api";
import {
  addTeamMember,
  removeTeamMember,
  updateTeam,
} from "../../../../store/user/thunks";
import type { Team, User } from "../../../../store/user/userReducer";
import AutocompleteVirtualized from "../../../Basic/Autocomplete/AutocompleteVirtualized";
import Button from "../../../Basic/Button";
import Input from "../../../Basic/Input";
import TeamMember from "./TeamMember";

type EditTeamFormProps = {
  className?: string;
  onClose: () => void;
  onCancel: () => void;
  selectedTeam: Team;
  newTeam: Team;
  onSave: () => void;
};

const EditTeamForm = ({
  className = "",
  onClose,
  onCancel,
  selectedTeam,
  newTeam,
  onSave = () => {},
}: EditTeamFormProps) => {
  const [memberOptions, setMemberOptions] = useState([]);
  const [editName, setEditName] = useState(false);
  const [teamName, setTeamName] = useState(selectedTeam.name || "");
  const [team, setTeam] = useState(selectedTeam);
  const [editMembers, setEditMembers] = useState(false);
  const dispatch = useAppDispatch();

  const { data: users = [] } = useGetUsersQuery({
    search: "",
  });

  const { data: teams = [] } = useGetTeamsQuery({
    search: "",
  });

  useEffect(() => {
    setMemberOptions(
      users.filter((user) => {
        return !team.members.some(
          (member) => member.doctor_id === user.doctor_id,
        );
      }),
    );
  }, [users, team]);

  useEffect(() => {
    setTeam(
      teams.find((team) => team.doctor_team_id === selectedTeam.doctor_team_id),
    );
  }, [teams, selectedTeam.doctor_team_id]);

  const handleUpdateName = () => {
    dispatch(
      updateTeam(
        team.doctor_team_id,
        teamName,
        team.show_inbox,
        team.team_icon,
        !newTeam,
        () => {
          onSave();
          onClose();
        },
      ),
    );
  };

  const handleRemoveMember = (memberId: number) => {
    dispatch(removeTeamMember(team.doctor_team_id, memberId));
  };

  const handleSelectMember = (member: User) => {
    dispatch(addTeamMember(team.doctor_team_id, member.doctor_id));
  };

  return (
    <div
      className={`w-full h-full grid grid-rows-note-layout overflow-hidden ${className}`}
    >
      <div className="h-full grid grid-rows-chat-layout overflow-hidden pl-5 pr-2">
        <div>
          <p className="text-sm font-medium text-tertiary mb-1 pr-2">
            Mention Name
          </p>
          <div className="mb-4 pr-2 truncate">
            {editName ? (
              <div className="relative w-full">
                <Input
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                  placeholder="Mention"
                  className="w-full"
                  inputClassName="h-10 pl-7 text-base font-semibold"
                />
                <p className="absolute left-3 top-2 text-base font-semibold">
                  @
                </p>
              </div>
            ) : (
              <button
                type="button"
                className="w-full h-10 border border-transparent rounded-lg px-3 grid grid-cols-2-right gap-3
                items-center transition-all group hover:bg-gray-foreground truncate"
                onClick={() => setEditName(true)}
              >
                <p className="text-base font-semibold truncate">@{teamName}</p>
                <EditIcon
                  width="18"
                  height="18"
                  className="transition-all opacity-0 group-hover:opacity-50"
                />
              </button>
            )}
          </div>

          <div className="flex items-center justify-between mb-2 font-bold pr-2">
            <p className="text-sm">
              Members ({team.members ? team.members.length : 0})
            </p>
            {team.members?.length > 0 && (
              <button
                type="button"
                className="text-primary-blue text-xs"
                onClick={() => setEditMembers(!editMembers)}
              >
                Edit
              </button>
            )}
          </div>
        </div>

        {team.members?.length > 0 && (
          <div className="space-y-1 mb-2 pr-2 overflow-y-auto scrollbar min-h-0">
            {team.members.map((member) => (
              <TeamMember
                key={member.doctor_id}
                member={member}
                edit={editMembers}
                onRemove={handleRemoveMember}
              />
            ))}
          </div>
        )}

        <AutocompleteVirtualized
          options={memberOptions}
          onSelect={handleSelectMember}
          placeholder="Add new member"
          className="w-full bg-white rounded-lg pr-2"
          isDoctors
        />
      </div>

      <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
        <Button className="w-full md:w-fit" variant="gray" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className="w-full md:w-fit"
          onClick={handleUpdateName}
          disabled={!teamName.trim()}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditTeamForm;
