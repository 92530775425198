import { ReactComponent as DoubleHeartIcon } from "@assets/icons/double-heart.svg";
import { ReactComponent as MicrophoneScribeIcon } from "@assets/icons/microphone-scribe.svg";
import { ReactComponent as PhoneCallIcon } from "@assets/icons/phone-call-2.svg";
import automaticProfileSrc from "@assets/quadrant-ai.svg";
import Skeleton from "@mui/material/Skeleton";
import { skipToken } from "@reduxjs/toolkit/query";
import { Link } from "react-router";
import { Pages } from "../../../../helpers/constants";
import {
  getDurationString,
  parseDateString,
} from "../../../../helpers/helpers";
import { useGetProviderQuery } from "../../../../store/provider/api";
import ProfilePicture from "../../../Basic/ProfilePicture";
import RecordingIndicator from "../../../IndependentScribe/RecordingIndicator";
import { SCRIBE_SEARCH_PARAM } from "../../../IndependentScribe/hooks/useScribeSearchParams";
import type { ScribeNote } from "../../../IndependentScribe/store/scribeSlice";
import { initialTitle } from "../../../IndependentScribe/utils";

interface NoteTimelineCardProps {
  note: ScribeNote;
  className?: string;
}

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const userLocale = Intl.DateTimeFormat().resolvedOptions().locale;

const dateFormatter = new Intl.DateTimeFormat(userLocale, {
  timeZone: userTimezone,
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
});

const NoteTimelineCard = ({ note, className = "" }: NoteTimelineCardProps) => {
  const accumulatedDuration = Math.max(
    0,
    note.duration * 1000 ||
      new Date(note.finished_at).getTime() -
        new Date(note.started_at).getTime(),
  );
  const duration = getDurationString(Math.floor(accumulatedDuration / 1000));
  const noteType = note.type;
  const title = note.title || initialTitle(parseDateString(note.started_at));
  const { data: provider } = useGetProviderQuery(note.provider_id ?? skipToken);

  const ProviderInfo = () => {
    if (!note.provider_id) {
      return (
        <span className="flex items-center text-sm font-semibold h-8">
          Quadrant AI
        </span>
      );
    }

    if (provider) {
      return (
        <div className="flex flex-col items-center h-8 mb-2">
          <span className="text-sm font-semibold">
            {provider?.display_name}
          </span>
          <span className="text-xs text-tertiary">{provider?.occupation}</span>
        </div>
      );
    }

    return (
      <div className="flex items-center h-8">
        <Skeleton
          animation="wave"
          variant="rounded"
          width={90}
          height={10}
          sx={{
            bgcolor: "#D6DBE4",
            borderRadius: "10px",
            marginLeft: "auto",
          }}
        />
      </div>
    );
  };

  const ProviderProfilePicture = () => {
    if (!note.provider_id) {
      return (
        <ProfilePicture
          src={automaticProfileSrc}
          firstName={"Quadrant"}
          lastName={"AI"}
          flagPadding={false}
        />
      );
    }

    if (!provider) {
      return (
        <div className="relative w-fit h-fit">
          <div className="w-8 h-8 rounded-full overflow-hidden flex-none flex items-center justify-center bg-gray-200">
            <Skeleton
              animation="wave"
              variant="circular"
              width={32}
              height={32}
              sx={{ bgcolor: "#D6DBE4", flex: "none" }}
            />
          </div>
        </div>
      );
    }

    return (
      <ProfilePicture
        firstName={provider?.first_name}
        lastName={provider?.last_name}
        src={provider?.profile_picture}
        flagPadding={false}
      />
    );
  };

  return (
    <div className="flex flex-col items-end gap-2 relative ml-auto mt-6">
      <div className="flex gap-2">
        <div className="flex flex-col items-end">
          <ProviderInfo />
          <Link
            replace
            to={{
              pathname: `${Pages.PATIENTS}/${note.patient.patient_id}`,
              search: `?${SCRIBE_SEARCH_PARAM}=${note.audio_id}`,
            }}
          >
            <div
              className={`flex flex-col gap-1 w-80 p-4 border-4 border-primary-blue-light rounded-lg bg-white cursor-pointer ${className}`}
            >
              <div className="flex flex-col w-full gap-1 bg-gray-100 rounded-lg p-2">
                <div className="flex flex-row gap-0.5">
                  <span className="flex items-start mt-0.5 py-[2px] rounded">
                    {noteType === "scribe" && (
                      <MicrophoneScribeIcon
                        className={"w-4 h-4 [&_path]:opacity-100"}
                      />
                    )}
                    {noteType === "call" && (
                      <PhoneCallIcon className="w-4 h-4" />
                    )}
                    {noteType === "campaign" && (
                      <DoubleHeartIcon className="w-4 h-4" />
                    )}
                  </span>
                  <div className="font-semibold line-clamp-2">{title}</div>

                  {note.audio_status === "paused" && (
                    <div className="ml-auto">
                      <RecordingIndicator isRecording={false} size={20} />
                    </div>
                  )}
                </div>
              </div>
              <div className="text-sm text-tertiary">
                {duration} ・{" "}
                {dateFormatter.format(parseDateString(note.started_at))}
              </div>
            </div>
          </Link>
        </div>
        <ProviderProfilePicture />
      </div>
    </div>
  );
};

export default NoteTimelineCard;
