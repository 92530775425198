import { ReactComponent as ConversationImg } from "@assets/empty-conversation.svg";
import type React from "react";
import { type RefObject, memo, useEffect, useMemo, useState } from "react";
import { inboxes } from "../../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setNextConversationIdAction } from "../../../store/conversation/actions";
import useConversationSearchParams from "../../Inbox/hooks/useConversationSearchParams";
import ConversationPreview from "./ConversationPreview";

// Separate empty state component to prevent unnecessary rerenders
const EmptyState = memo(() => (
  <div className="w-full h-full min-h-[160px] flex flex-col items-center justify-center my-auto">
    <ConversationImg width="160" height="100" />
    <p className="text-base text-center text-tertiary p-3">
      No conversations found
    </p>
  </div>
));

EmptyState.displayName = "EmptyState";

// Separate header component
const GroupHeader = memo(
  ({ name, icon }: { name: string; icon: React.ReactNode }) =>
    name ? (
      <div className="flex space-x-2 items-center pb-4 px-4">
        {icon}
        <p className="uppercase text-neutral-500 font-semibold text-sm">
          {name}
        </p>
      </div>
    ) : null,
);

GroupHeader.displayName = "GroupHeader";

// Memoized conversation list component
const ConversationList = memo(
  ({
    conversations,
    containerRef,
    clickedConversation,
    setClickedConversation,
  }: {
    conversations: any[];
    containerRef: RefObject<HTMLDivElement>;
    clickedConversation: any;
    setClickedConversation: (conv: any) => void;
  }) => (
    <>
      {conversations.map((conversation) => (
        <ConversationPreview
          key={conversation.lastMessage.conversation_id}
          conversation={conversation}
          containerRef={containerRef}
          clickedConversation={clickedConversation}
          setClickedConversation={setClickedConversation}
        />
      ))}
    </>
  ),
);

ConversationList.displayName = "ConversationList";

// Optimize the main component
const ConversationGroup = memo(
  ({
    name = "",
    icon = null,
    conversations,
    containerRef,
  }: {
    name?: string;
    icon?: React.ReactNode;
    conversations: any;
    containerRef: RefObject<HTMLDivElement>;
  }) => {
    const dispatch = useAppDispatch();

    const { selectedConversationId } = useConversationSearchParams();
    const { activeInbox, loader } = useAppSelector(
      (state) => ({
        activeInbox: state.conversation.activeInbox,
        loader: state.conversation.loader,
      }),
      (prev, next) =>
        prev.activeInbox === next.activeInbox && prev.loader === next.loader,
    );

    const [clickedConversation, setClickedConversation] = useState(null);

    // Memoize the next conversation ID calculation
    const nextConversationId = useMemo(() => {
      if (activeInbox === inboxes.COMPLETE || !conversations?.all?.length) {
        return "";
      }

      const index = conversations.all.findIndex(
        (conversation) =>
          conversation.lastMessage.conversation_id === selectedConversationId,
      );

      if (index === -1 || conversations.all.length <= 1) {
        return "";
      }

      const nextIndex = conversations.all.length > index + 1 ? index + 1 : 0;
      return conversations.all[nextIndex].lastMessage.conversation_id;
    }, [conversations?.all, selectedConversationId, activeInbox]);

    // Only dispatch when nextConversationId actually changes
    useEffect(() => {
      dispatch(setNextConversationIdAction(nextConversationId));
    }, [nextConversationId, dispatch]);

    const containerClassName = useMemo(
      () =>
        `px-4 pb-4 ${conversations?.total_conversations === 0 ? "h-full min-h-fit" : ""}`,
      [conversations?.total_conversations],
    );

    // Early return for loading state
    if (loader) {
      return null;
    }

    return (
      <div className={containerClassName}>
        <GroupHeader name={name} icon={icon} />

        {conversations?.all?.length > 0 ? (
          <ConversationList
            conversations={conversations.all}
            containerRef={containerRef}
            clickedConversation={clickedConversation}
            setClickedConversation={setClickedConversation}
          />
        ) : (
          conversations?.total_conversations === 0 && <EmptyState />
        )}
      </div>
    );
  },
);

ConversationGroup.displayName = "ConversationGroup";

export default ConversationGroup;
