import { skipToken } from "@reduxjs/toolkit/query";
import { Suspense } from "react";
import { useMediaQuery } from "react-responsive";
import { NavLink, useNavigate } from "react-router";
import {
  Pages,
  patientPanelTabs,
  patientProfileTabs,
} from "../../../helpers/constants";
import { getFormattedPhoneNumber } from "../../../helpers/helpers";
import { useAppDispatch } from "../../../store";
import { useGetConversationQuery } from "../../../store/call/api";
import { FeatureFlags, useFeature } from "../../../store/featureFlagSlice";
import {
  setSelectedPatientPanelTabAction,
  setSelectedPatientProfileTabAction,
} from "../../../store/patient/actions";
import { getPatientInfo } from "../../../store/patient/thunks";
import { setOpenCallOptionsModalAction } from "../../../store/voiceRecorder/actions";
import ProfilePicture from "../../Basic/ProfilePicture";
import useConversationSearchParams from "../../Inbox/hooks/useConversationSearchParams";
import PatientShortInfo from "../../PatientProfile/PatientShortInfo";
import InsightPanelSkeleton from "../../Skeletons/InsightPanelSkeleton";
import InsightTab from "./InsightTab";

const InsightPanel = () => {
  const isFrontDeskInbox = useFeature(FeatureFlags.FRONT_DESK_INBOX);
  const isFrontDesk = useFeature(FeatureFlags.FRONT_DESK);
  const { selectedConversationId } = useConversationSearchParams();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isLg = useMediaQuery({ minWidth: 1024 });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data: currentConversation } = useGetConversationQuery(
    selectedConversationId ? Number(selectedConversationId) : skipToken,
  );

  if (!currentConversation) {
    return null;
  }

  const { patient } = currentConversation;

  const deprecationNotice = () => {
    if (isFrontDeskInbox && isFrontDesk) {
      return (
        <div className="flex flex-col items-center justify-center w-full bg-red-500 text-white text-center text-sm">
          <div className="w-[80%] p-4">
            <NavLink to={Pages.INBOX}>
              <>
                New Inbox is here with team inboxes and member tagging!
                <br /> (This version retires February 15)
              </>
            </NavLink>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className="md:border border-gray-200 bg-white md:rounded-lg h-full flex-none grid grid-rows-tab-layout
        transition-all w-full md:w-80 lg:w-[375px] relative overflow-hidden"
    >
      <div className="h-fit flex px-5 overflow-hidden">
        <button
          type="button"
          className="flex items-center w-full border-b overflow-hidden
            pb-3 md:pb-5 pl-7 md:pl-0 pt-3 md:pt-5"
          onClick={() => {
            dispatch(
              getPatientInfo(patient.patient_id, () => {
                navigate(`${Pages.PATIENTS}/${patient.patient_id}`);
                dispatch(setOpenCallOptionsModalAction(true));
              }),
            );
            dispatch(
              setSelectedPatientProfileTabAction(patientProfileTabs.NOTES),
            );
            dispatch(
              setSelectedPatientPanelTabAction(patientPanelTabs.MESSAGE),
            );
          }}
        >
          {!isMobile && (
            <ProfilePicture
              src={patient.profile_picture}
              firstName={
                patient.preferred_name
                  ? patient.preferred_name
                  : patient.first_name
              }
              lastName={patient.last_name}
              size={isLg ? 14 : 10}
              fontSize={isLg ? "xl" : "base"}
              className="h-10 w-10 lg:h-14 lg:w-14 mr-2 lg:mr-3"
            />
          )}
          <div className="flex flex-col items-start w-full truncate">
            <p className="font-semibold text-left text-base lg:text-xl mt-1 md:mt-0 w-full truncate">
              {patient.preferred_name
                ? patient.preferred_name
                : patient.first_name}{" "}
              {patient.last_name}
            </p>
            {patient.phone_number && !isMobile ? (
              <div className="leading-none mt-1 pb-0.5 w-full truncate text-left text-xs lg:text-base text-tertiary">
                <p>{getFormattedPhoneNumber(patient.phone_number)}</p>
              </div>
            ) : (
              <PatientShortInfo
                patient={patient}
                className="mt-1 pb-0.5 w-full truncate text-left text-xs lg:text-base text-tertiary"
                showBirthdate={false}
              />
            )}
          </div>
        </button>
      </div>
      <div className="overflow-y-auto scrollbar h-full grid grid-rows-tab-layout">
        {deprecationNotice()}
        <Suspense fallback={<InsightPanelSkeleton />}>
          <InsightTab />
        </Suspense>
      </div>
    </div>
  );
};

export default InsightPanel;
