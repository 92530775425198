import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import TabButtonRound from "../../components/Basic/TabButtonRound";
import TabsBorder from "../../components/Basic/TabsBorder";
import ClinicInfo from "../../components/Settings/Clinic/ClinicInfo";
import NotesSectionTabs from "../../components/Settings/Notes/NotesSectionTabs";
import { Pages, TabsOrientation } from "../../helpers/constants";
import { type RootState, useAppDispatch, useAppSelector } from "../../store";
import { FeatureFlags, useFeature } from "../../store/featureFlagSlice";
import { setSelectedSettingsTabAction } from "../../store/user/actions";
import { selectUser } from "../../store/user/userReducer";

const notesTab = { text: "Notes", link: Pages.SETTINGS_NOTES };
const sitesTab = { text: "Sites", link: Pages.SETTINGS_SITES };
const adminTab = { text: "Admin", link: Pages.SETTINGS_ADMIN };
const profileTab = { text: "Profile", link: Pages.SETTINGS_PROFILE };
const clinicTab = {
  text: "Clinic",
  link: Pages.SETTINGS_CLINIC,
};
const careManagementTab = {
  text: "Care Management",
  link: Pages.SETTINGS_CARE_MANAGEMENT,
};

const baseTabsConfig = [profileTab, clinicTab];

const SettingsTabs = ({ children }) => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { sites } = useAppSelector((state: RootState) => state.site);
  const { selectedSettingsTab } = useAppSelector(
    (state: RootState) => state.user,
  );

  const isScribeOnly = useFeature(FeatureFlags.SCRIBE_ONLY);
  const isCCM = useFeature(FeatureFlags.CCM);
  const isSystemAdmin = user?.email?.endsWith("@quadrant.health");

  const [availableTabs, setAvailableTabs] = useState([]);

  useEffect(() => {
    const newTabs = [...baseTabsConfig];

    if (sites?.length >= 2) {
      newTabs.push(sitesTab);
    }

    if (isScribeOnly) {
      newTabs.push(notesTab);
    }

    if (isCCM) {
      newTabs.push(careManagementTab);
    }

    if (isSystemAdmin) {
      newTabs.push(adminTab);
    }

    setAvailableTabs(newTabs);

    // Redirect to profile if current tab is not available
    const currentTabExists = newTabs.some(
      (tab) => tab.link === location.pathname,
    );
    if (!currentTabExists) {
      dispatch(setSelectedSettingsTabAction("Profile"));
      navigate(Pages.SETTINGS_PROFILE);
    }
  }, [
    sites?.length,
    isScribeOnly,
    isCCM,
    isSystemAdmin,
    location.pathname,
    dispatch,
    navigate,
  ]);

  const handleTabChange = (tabText) => {
    const selectedTab = availableTabs.find((tab) => tab.text === tabText);
    if (selectedTab) {
      dispatch(setSelectedSettingsTabAction(tabText));
      navigate(selectedTab.link);
    }
  };

  const renderAdditionalContent = () => {
    switch (location.pathname) {
      case Pages.SETTINGS_CLINIC:
        return <ClinicInfo user={user} editable={user.role_id <= 1} />;
      case Pages.SETTINGS_NOTES:
        return <NotesSectionTabs />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-gray-background grid grid-rows-tab-layout xl:grid-rows-1 xl:grid-cols-2-right xl:pl-8 overflow-hidden">
      <div className="w-full overflow-y-auto pr-1 xl:mt-6 xl:pb-6 xl:w-60 scrollbar">
        {/* Desktop Layout */}
        <div className="hidden xl:flex p-4 h-fit w-full rounded-lg bg-white border border-gray-foreground">
          <TabsBorder
            tabs={availableTabs.map((tab) => tab.text)}
            orientation={TabsOrientation.VERTICAL}
            activeTab={selectedSettingsTab}
            setActiveTab={handleTabChange}
            className="h-fit"
            tabClassName="py-4 px-5 text-base whitespace-nowrap"
            activeTextColor="primary-blue"
          />
        </div>

        {/* Mobile Layout */}
        <div className="xl:hidden px-4 overflow-x-hidden">
          <div className="pt-4 pb-1 flex space-x-2 w-full overflow-x-auto scrollbar">
            {availableTabs.map((tab) => (
              <TabButtonRound
                key={tab.text}
                label={tab.text}
                active={selectedSettingsTab}
                onClick={() => handleTabChange(tab.text)}
                className="whitespace-nowrap"
              />
            ))}
          </div>
        </div>

        {renderAdditionalContent()}
      </div>

      {children}
    </div>
  );
};

export default SettingsTabs;
