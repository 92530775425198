import noteGenerationDelayedImage from "@assets/note-generation-delayed.svg";
import { useMediaQuery } from "react-responsive";
import Warning from "./index";

const GenerationDelayed = ({
  forceHorizontal = false,
  onSubmit = () => {},
  onCancel = () => {},
  className = "",
}: {
  forceHorizontal?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
  className?: string;
}) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <Warning
      image={noteGenerationDelayedImage}
      title="Note Generation Delayed"
      description="Please check back later today"
      submitText=""
      horizontal={forceHorizontal || !isDesktop}
      onSubmit={onSubmit}
      onCancel={onCancel}
      className={className}
      noButtonsOnMobile={!isDesktop}
    />
  );
};

export default GenerationDelayed;
