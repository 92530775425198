import { ReactComponent as EditIcon } from "@assets/icons/edit3.svg";
import debounce from "lodash.debounce";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { saveEncounterTitle } from "../../../store/conversation/thunks";
import useScribeSearchParams from "../hooks/useScribeSearchParams";
import { setScribe } from "../store/scribeSlice";
import { selectScribeById } from "../store/selectors";
import { initialTitle } from "../utils";

const defaultTitles = ["Scribe", "Chronic Care Management"];

const ScribeHeaderTitle = ({
  scribeOriginalTitle,
}: {
  scribeOriginalTitle: string;
}) => {
  const { selectedScribeId } = useScribeSearchParams();
  const selectedScribe = useAppSelector((state) =>
    selectScribeById(state, selectedScribeId),
  );

  const [isEditing, setIsEditing] = useState(false);
  const scribeTitleRef = useRef("");
  const saveTimeoutRef = useRef(null);
  const titleInputRef = useRef(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedScribe?.audioId) {
      scribeTitleRef.current = initialTitle(new Date());

      return;
    }

    if (
      !scribeOriginalTitle ||
      defaultTitles.includes(scribeOriginalTitle) ||
      scribeOriginalTitle.match(/Encounter - \d{1,2}\/\d{1,2}$/)
    ) {
      if (!isEditing) {
        scribeTitleRef.current = initialTitle(new Date());

        if (titleInputRef?.current) {
          titleInputRef.current.value = initialTitle(new Date());
        }
      } else {
        scribeTitleRef.current = "";

        if (titleInputRef?.current) {
          titleInputRef.current.value = "";
        }
      }

      return;
    }

    scribeTitleRef.current = scribeOriginalTitle;
    if (titleInputRef?.current) {
      titleInputRef.current.value = scribeOriginalTitle;
    }
  }, [selectedScribe?.audioId, isEditing, scribeOriginalTitle]);

  useEffect(() => {
    if (isEditing) {
      titleInputRef.current?.focus();
      titleInputRef.current.setSelectionRange(
        scribeTitleRef.current.length,
        scribeTitleRef.current.length,
      );
    }
  }, [isEditing]);

  const debouncedHandleTitleChange = debounce((event) => {
    scribeTitleRef.current = event.target.value;
    dispatch(
      setScribe({
        audioId: selectedScribe?.audioId,
        scribe: {
          title: event.target.value,
        },
      }),
    );

    if (selectedScribe?.audioId) {
      dispatch(saveEncounterTitle(selectedScribe?.audioId, event.target.value));
    }
  }, 500);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedHandleTitleChange(event);
  };

  const handleBlur = () => {
    let title = scribeTitleRef.current;
    clearTimeout(saveTimeoutRef.current);

    if (!title) {
      title = scribeOriginalTitle || initialTitle(new Date());
      dispatch(
        setScribe({
          audioId: selectedScribe?.audioId,
          scribe: {
            isEditing: false,
            title,
          },
        }),
      );
      scribeTitleRef.current = title;
      titleInputRef.current.value = title;
      return;
    }

    if (!(selectedScribe?.noteId && title !== scribeOriginalTitle)) {
      return;
    }

    dispatch(
      setScribe({
        audioId: selectedScribe?.audioId,
        scribe: {
          isEditing: false,
          title: title || initialTitle(new Date()),
        },
      }),
    );

    if (selectedScribe?.audioId) {
      dispatch(
        saveEncounterTitle(
          selectedScribe?.audioId,
          title || initialTitle(new Date()),
        ),
      );
    }
  };

  const toggleEdit = () => {
    dispatch(
      setScribe({
        audioId: selectedScribe?.audioId,
        scribe: {
          originalTitle: scribeOriginalTitle,
          isEditing: true,
        },
      }),
    );

    setIsEditing(true);
  };

  return isEditing ? (
    <input
      ref={titleInputRef}
      type="text"
      defaultValue={scribeTitleRef.current}
      onChange={handleTitleChange}
      onBlur={handleBlur}
      maxLength={40}
      className="md:text-2xl font-semibold py-4 md:py-2 px-5 md:px-3 w-full"
      style={{ outlineColor: "#D0D5DD", caretColor: "#2970FF" }}
      onKeyUp={(event) => {
        if (event.key === "Enter") {
          handleBlur();
        }
      }}
    />
  ) : (
    <div className="font-semibold flex items-center overflow-hidden text-24">
      <button
        type="button"
        className="flex group items-center md:hover:bg-[#f5f7fa] px-5 md:px-3 rounded w-full max-w-full
          py-4 md:py-2 justify-between"
        onClick={toggleEdit}
      >
        <span className="truncate overflow-none text-[#3C3C47]">
          {scribeOriginalTitle ?? scribeTitleRef.current}
        </span>
        <div className="hidden group-hover:block flex-none">
          <EditIcon />
        </div>
      </button>
    </div>
  );
};

export default ScribeHeaderTitle;
