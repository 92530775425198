import { ReactComponent as PauseIcon } from "@assets/icons/pause-circle.svg";
import { ReactComponent as EditIcon } from "@assets/icons/pen.svg";
import { ReactComponent as DeleteIcon } from "@assets/icons/trash-redesign.svg";
import { useEffect, useMemo, useState } from "react";
import { getAge, getPatientMrn, getPatientSex } from "../../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../store";
import { FeatureFlags, useFeature } from "../../store/featureFlagSlice";
import { setSelectedPatientsAction } from "../../store/patient/actions";
import type { Patient } from "../../store/patient/interfaces";
import { selectUser } from "../../store/user/userReducer";
import ActionsButton from "../Basic/ActionsButton";
import CircleDivider from "../Basic/CircleDivider";
import Tag from "../Basic/Tag";
import IndeterminateCheckbox from "./IndeterminateCheckbox";

const PatientItem = ({
  patient,
  onClick,
  onEdit,
  onPause,
  onDelete,
}: {
  patient: Patient;
  onClick: () => void;
  onEdit: (patient: Patient) => void;
  onPause: (patientId: number, paused: boolean) => void;
  onDelete: (patient: Patient) => void;
}) => {
  const user = useAppSelector(selectUser);
  const isCCM = useFeature(FeatureFlags.CCM);
  const { selectedPatients } = useAppSelector((state) => state.patient);
  const [selected, setSelected] = useState(false);
  const dispatch = useAppDispatch();

  const patientSex = getPatientSex(patient.sex);

  const mrn = useMemo(
    () => getPatientMrn(patient.chart_number, user.customer.has_ehr_data),
    [patient.chart_number, user.customer.has_ehr_data],
  );

  const isPatientSelected = useMemo(
    () =>
      selectedPatients.some(
        (selectedPatient) => selectedPatient.patient_id === patient.patient_id,
      ),
    [selectedPatients, patient.patient_id],
  );

  useEffect(() => {
    setSelected(isPatientSelected);
  }, [isPatientSelected]);

  return (
    <div
      className={`p-4 rounded-xl border-b border-gray-foreground space-y-2
        ${selected ? "bg-gray-140" : "bg-white"}`}
      onClick={onClick}
    >
      <div className="flex items-center justify-between space-x-2 truncate">
        <div className="flex items-center space-x-2">
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <IndeterminateCheckbox
              checked={selected}
              onChange={() => {
                if (selected) {
                  dispatch(
                    setSelectedPatientsAction(
                      selectedPatients.filter(
                        (selectedPatient) =>
                          selectedPatient.patient_id !== patient.patient_id,
                      ),
                    ),
                  );
                } else {
                  dispatch(
                    setSelectedPatientsAction([...selectedPatients, patient]),
                  );
                }
              }}
            />
          </div>

          <p className="truncate text-base font-semibold">
            {patient.preferred_name
              ? patient.preferred_name
              : patient.first_name}{" "}
            {patient.last_name}
          </p>
        </div>

        <ActionsButton
          actions={[
            !user.customer.has_ehr_data && {
              icon: (
                <EditIcon
                  width="20"
                  height="20"
                  stroke="#667085"
                  className="flex-none"
                />
              ),
              label: "Edit",
              onClick: () => onEdit(patient),
            },
            {
              icon: (
                <PauseIcon
                  width="20"
                  height="20"
                  stroke="#667085"
                  className="flex-none"
                />
              ),
              label: patient.is_paused ? "Unpause" : "Pause",
              onClick: () =>
                onPause(patient.patient_id, patient.is_paused === 1),
            },
            {
              icon: (
                <DeleteIcon
                  width="20"
                  height="20"
                  stroke="#667085"
                  className="flex-none"
                />
              ),
              label: "Delete",
              onClick: () => onDelete(patient),
            },
          ]}
        />
      </div>
      <div className="flex items-center justify-between space-x-2 truncate">
        <div className="truncate text-sm font-medium text-tertiary flex items-center space-x-1">
          {patient.birthdate && (
            <>
              <span>{getAge(patient.birthdate)}</span>
              {(patient.gender || patientSex || patient.birthdate) && (
                <CircleDivider className="flex-none" />
              )}
            </>
          )}
          {(patient.gender || patientSex) && (
            <>
              <span className="capitalize">
                {patient.gender
                  ? patient.gender.charAt(0)
                  : patientSex.charAt(0)}
              </span>
              {patient.birthdate && <CircleDivider className="flex-none" />}
            </>
          )}
          {patient.birthdate && (
            <>
              <span>
                {new Date(
                  (patient.birthdate as string).replace(/-/g, "/"),
                ).toLocaleDateString("en-us", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </span>
              {mrn && <CircleDivider className="flex-none" />}
            </>
          )}
          {mrn && <span>{mrn}</span>}
        </div>
        {isCCM && <Tag text={patient.care_coordination} variant="gray" />}
      </div>
    </div>
  );
};

export default PatientItem;
