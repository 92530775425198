import { baseApi } from "../../../store/baseApi";

export const noteTemplateApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    setClinicDefaultNoteTemplate: builder.mutation<
      { message: string },
      {
        noteTemplateId: number;
      }
    >({
      query: ({ noteTemplateId }) => ({
        url: `/note-template/clinic-select/${noteTemplateId}`,
        method: "POST",
      }),
    }),

    setProviderDefaultNoteTemplate: builder.mutation<
      void,
      { noteTemplateId: number }
    >({
      query: ({ noteTemplateId }) => ({
        url: `/note-template/provider-select/${noteTemplateId}`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useSetClinicDefaultNoteTemplateMutation,
  useSetProviderDefaultNoteTemplateMutation,
} = noteTemplateApi;
